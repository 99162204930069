import React from 'react';
import { Link } from '../../../shared';
import { shopifyUrlNl, shopifyUrlDe } from '../../../../utils/shopify';
import { formatShopifyLink } from '../../../../utils/formatting';

interface ShopifyOrderLinkProps {
    marketplaceCode: OrderData['marketplaceCode'];
    shopifyOrderId: OrderData['shopifyOrderId'];
    orderNumber: OrderData['orderNumber'];
}

export const ShopifyOrderLink = ({ marketplaceCode, shopifyOrderId, orderNumber }: ShopifyOrderLinkProps) => {
    const shopifyUrl = formatShopifyLink({
        shopifyOrderId,
        marketplaceCode,
        shopifyUrlNl,
        shopifyUrlDe,
    });

    return (
        <p>
            <b>
                Order number <Link href={shopifyUrl} text={`${orderNumber},${marketplaceCode.toUpperCase()}`} />
            </b>
        </p>
    );
};
