import React, { useState } from 'react';
import {
    DataGrid,
    GridColDef,
    GridValueFormatterParams,
    GridValueGetterParams,
    GridRowParams,
    GridRenderCellParams,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { DataGridExportToolbar, renderCellExpand } from '../../../shared/DataGrid';
import { AddButton, DeleteButton, SaveButton } from '../../../shared/Buttons';
import { Error, Heading } from '../../../shared';
import {
    ConfirmTransactionSubmissionDialog,
    NewTransactionFormWithFetch,
    TransactionTableFooter,
} from './subcomponents';
import { useTransactionTable } from './useTransactionTable';
import { useCheckIfUserHasManagerRights } from '../../../../hooks';
import { formatBool, formatPriceString, formatPrice } from '../../../../utils/formatting';
import { toYearMonthDayTime } from '../../../../utils/dates';
import styles from './transactionTable.module.scss';

interface TransactionTableProps {
    transactions: Transaction[];
}

export const TransactionTable = ({ transactions }: TransactionTableProps) => {
    const { hasManagerRights } = useCheckIfUserHasManagerRights();
    const [pageSize, setPageSize] = useState(50);

    const {
        debitTotal,
        creditTotal,
        selectedDebitTotal,
        selectedCreditTotal,
        handleRowSelection,
        handleCreateTransactionClick,
        handleHideTransactionForm,
        handleDeleteSelectedTemporaryTransactions,
        handleOpenConfirmationDialog,
        allTransactions,
        hasTemporaryTransactions,
        hasNewTransactionError,
        isDeleteButtonDisabled,
        isFormVisible,
    } = useTransactionTable(transactions);

    // Value getters (render column values as combination of other column values)
    const getDebit = (params: GridValueGetterParams) => {
        if (params.row.balanceType === 'debit') return formatPriceString(params.row.amount);
        return '';
    };

    const getCredit = (params: GridValueGetterParams) => {
        if (params.row.balanceType === 'credit') return formatPriceString(params.row.amount);
        return '';
    };

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 120,
        },
        {
            field: 'type',
            headerName: 'Type',
            renderCell: (params: GridRenderCellParams<string>) => renderCellExpand(params, 275),
            width: 90,
        },
        {
            field: 'supplierNumber',
            headerName: 'Supplier',
            width: 100,
        },
        {
            field: 'datetime',
            headerName: 'Created',
            valueFormatter: (params: GridValueFormatterParams<string>) => toYearMonthDayTime(params.value),
            width: 150,
        },
        {
            field: 'description',
            headerName: 'Description',
            renderCell: renderCellExpand,
            width: 150,
        },
        {
            field: 'productName',
            headerName: 'Product',
            renderCell: renderCellExpand,
            width: 150,
        },
        {
            field: 'sku',
            headerName: 'SKU',
            width: 120,
        },
        {
            field: 'price',
            headerName: 'Price',
            valueFormatter: (params: GridValueFormatterParams<string>) => formatPriceString(params.value),
            width: 90,
        },
        {
            field: 'quantity',
            headerName: 'Qty',
            valueFormatter: (params: GridValueFormatterParams<number>) => (params.value === 0 ? '' : params.value),
            width: 80,
        },
        {
            field: 'Debit',
            valueGetter: getDebit,
            width: 100,
        },
        {
            field: 'Credit',
            valueGetter: getCredit,
            width: 100,
        },
        {
            field: 'amount',
            headerName: 'Total',
            valueFormatter: (params: GridValueFormatterParams<number>) => formatPrice(params.value),
            width: 100,
        },
        {
            field: 'paymentSource',
            headerName: 'Payment Method',
            valueFormatter: (params: GridValueFormatterParams<string | null>) => (params.value ? params.value : 'N.A.'),
            width: 150,
        },
        {
            field: 'isAccountable',
            headerName: 'Accountable?',
            valueFormatter: (params: GridValueFormatterParams<boolean>) => formatBool(params.value),
            width: 150,
        },
        {
            field: 'finalised',
            headerName: 'Finalised',
            valueFormatter: (params: GridValueFormatterParams<string | null>) =>
                params.value ? toYearMonthDayTime(params.value) : 'No',
            width: 150,
        },
    ];

    return (
        <>
            <Heading level={2} text='Transactions' />
            {hasNewTransactionError && (
                <div className={styles.errorWrapper}>
                    <Error />
                </div>
            )}
            {hasTemporaryTransactions && (
                <div className={styles.buttonsWrapper}>
                    <DeleteButton
                        handleClick={handleDeleteSelectedTemporaryTransactions}
                        isDisabled={isDeleteButtonDisabled}
                    />
                    <SaveButton handleClick={handleOpenConfirmationDialog} />
                </div>
            )}
            <Box
                sx={{
                    '& .unaccountable': {
                        color: '#959393',
                    },
                    '& .temporary': {
                        color: '#1565c0',
                    },
                    '& .rejected': {
                        color: '#b91515',
                    },
                }}
            >
                <DataGrid
                    rows={allTransactions}
                    columns={columns}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    pageSize={pageSize} // Default page size is 100, using controlled pageSize allows to change the default value
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    pagination
                    autoHeight
                    getRowId={(row) => row.id}
                    checkboxSelection
                    isRowSelectable={(params: GridRowParams) => params.row.isAccountable}
                    onSelectionModelChange={handleRowSelection}
                    getRowClassName={(params: GridRowParams) => {
                        if (!params.row.isAccountable) return 'unaccountable';
                        if (params.row.className === 'temporary') return 'temporary';
                        if (params.row.className === 'rejected') return 'rejected';
                        return '';
                    }}
                    components={{
                        Toolbar: DataGridExportToolbar,
                    }}
                />
            </Box>
            <TransactionTableFooter
                debitTotal={debitTotal}
                creditTotal={creditTotal}
                selectedDebitTotal={selectedDebitTotal}
                selectedCreditTotal={selectedCreditTotal}
            />
            {/* Only managers can create transactions */}
            {hasManagerRights && (
                <>
                    <div className={styles.addButtonWrapper}>
                        <AddButton label='Create transaction' handleClick={handleCreateTransactionClick} />
                    </div>
                    {isFormVisible && (
                        <NewTransactionFormWithFetch handleHideTransactionForm={handleHideTransactionForm} />
                    )}
                    <ConfirmTransactionSubmissionDialog />
                </>
            )}
        </>
    );
};
