import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ITTP } from '@app/components/TransactionsUpload/types';
import { getUIReadableTTPType } from '@app/components/TransactionsUpload/utils';

const ModalPaper = styled(Paper)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    min-width: 30rem;
`;

const ContentSection = styled(Box)`
    max-height: 30rem;
    overflow: scroll;
`;

interface IProps {
    children: React.ReactNode;
    TTP: ITTP;
}

const DataRow = ({ title, value }: { title: string; value: string }) => (
    <Grid container gap='1rem'>
        <Grid item>
            <Typography fontWeight={600}>{title}</Typography>
        </Grid>

        <Grid item>
            <Typography>{value}</Typography>
        </Grid>
    </Grid>
);

export const TransactionDetailsModal = ({ children, TTP }: IProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const toggleModal = () => setShowModal(!showModal);

    return (
        <>
            <Box onClick={toggleModal}>{children}</Box>
            <Modal open={showModal} onClose={toggleModal}>
                <ModalPaper>
                    <Typography>Transaction Details</Typography>
                    <Divider />
                    <br />

                    {Boolean(TTP.errorMessage) && (
                        <Grid container gap='1rem'>
                            <Grid item>
                                <Typography color='error' fontWeight={600}>
                                    Error:
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography color='error' sx={{ overflowWrap: 'break-word' }}>
                                    {TTP.errorMessage}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    <ContentSection>
                        <DataRow title='Type:' value={getUIReadableTTPType(TTP.Type)} />
                        <DataRow title='Order Number:' value={TTP.OrderNumber || ''} />
                        <DataRow title='Marketplace:' value={TTP.Marketplace || ''} />
                        <DataRow title='Date:' value={TTP.Date || new Date().toDateString()} />
                        <DataRow title='Description:' value={TTP.Description || ''} />
                        <DataRow title='Vendor Number:' value={TTP.VendorNumber || ''} />
                        <DataRow title='Payment Source:' value={TTP.PaymentSource || ''} />
                        <DataRow title='Amount:' value={TTP.Amount || ''} />
                        <DataRow title='SKU:' value={TTP.SKU || ''} />
                        <DataRow title='Name:' value={TTP.Name || ''} />
                        <DataRow title='Price:' value={TTP.Price || ''} />
                        <DataRow title='Quantity:' value={TTP.Quantity || ''} />
                        <DataRow title='Brand:' value={TTP.Brand || ''} />
                        <DataRow title='Cancel Reason ID:' value={TTP.CancelReasonId || ''} />
                    </ContentSection>

                    <br />
                    <Divider />

                    <Grid container justifyContent='flex-end'>
                        <Button onClick={toggleModal}>Close</Button>
                    </Grid>
                </ModalPaper>
            </Modal>
        </>
    );
};
