import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { getInvoiceRunStatus } from '../../redux/reducers/invoiceRunStatus';
import {
    selectCanCreateNewInvoiceRun,
    selectIsLoadingInvoiceRunStatus,
    selectHasInvoiceRunStatusError,
} from '../../redux/reducers';

export const useFetchInvoiceRunStatus = () => {
    const dispatch = useAppDispatch();
    const isLoadingInvoiceRunStatus = useAppSelector(selectIsLoadingInvoiceRunStatus);
    const hasInvoiceRunStatusError = useAppSelector(selectHasInvoiceRunStatusError);
    const canCreateNewInvoiceRun = useAppSelector(selectCanCreateNewInvoiceRun);

    useEffect(() => {
        dispatch(getInvoiceRunStatus());
    }, [dispatch]);

    return { isLoadingInvoiceRunStatus, hasInvoiceRunStatusError, canCreateNewInvoiceRun };
};
