import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    canCreateNewInvoiceRun: false as InvoiceRunStatus['canCreateNewInvoiceRun'],
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const invoiceRunStatusSlice = createSlice({
    name: 'invoiceRunStatus',
    initialState,
    reducers: {
        invoiceRunStatusLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        invoiceRunStatusReceived(state, action: PayloadAction<InvoiceRunStatus>) {
            return {
                ...state,
                canCreateNewInvoiceRun: action.payload.canCreateNewInvoiceRun,
                isLoading: false,
            };
        },
        invoiceRunStatusError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
    },
});

export default invoiceRunStatusSlice.reducer;

const { invoiceRunStatusLoading, invoiceRunStatusReceived, invoiceRunStatusError } = invoiceRunStatusSlice.actions;

export const getInvoiceRunStatus = () => async (dispatch: Dispatch) => {
    dispatch(invoiceRunStatusLoading());
    try {
        const result = await getGatewayApiInstance().get('/invoice-runs/can-create-new');
        dispatch(invoiceRunStatusReceived(result.data));
    } catch (err: unknown) {
        dispatch(invoiceRunStatusError());
        handleAxiosErrors(err);
    }
};

// Selectors
export const selectCanCreateNewInvoiceRun = (state: State): InvoiceRunStatus['canCreateNewInvoiceRun'] =>
    state.canCreateNewInvoiceRun;
export const selectIsLoadingInvoiceRunStatus = (state: State): boolean => state.isLoading;
export const selectHasInvoiceRunStatusError = (state: State): boolean => state.hasError;
