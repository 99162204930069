import React from 'react';
import { DataGrid, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import {
    DataGridExportToolbar,
    DataGridLinearProgress,
    useDataGridServerSidePagination,
} from '../../../shared/DataGrid';
import { Error } from '../../../shared';
import { DownloadButton } from '../../../shared/Buttons';
import { useFetchInvoices } from '../../../Invoices/subComponents/InvoiceList/useFetchInvoices';
import { useDownloadInvoice } from '../../../Invoices/subComponents/InvoiceList/useDownloadInvoice';
import { toYearMonthDayTime } from '../../../../utils/dates';
import { formatPrice } from '../../../../utils/formatting';
import styles from './invoicesFromInvoiceRun.module.scss';

export const InvoicesFromInvoiceRun = () => {
    const INITIAL_PAGE_SIZE = 10;

    const { rowCountState, page, pageSize, handlePageChange, handlePageSizeChange, setRowCount } =
        useDataGridServerSidePagination({
            initialPageSize: INITIAL_PAGE_SIZE,
        });

    const { invoices, isLoading, hasError } = useFetchInvoices({ page, pageSize, setRowCount });

    const { handleDownloadClick, hasInvoiceDownloadError } = useDownloadInvoice();

    const renderDownloadButton = () => (
        <DownloadButton
            handleClick={() => {
                // This is intentional
            }}
        />
    );

    const columns: GridColDef[] = [
        {
            field: 'number',
            headerName: 'Invoice number',
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'date',
            headerName: 'Date',
            valueFormatter: (params: GridValueFormatterParams<Invoice['date']>) => toYearMonthDayTime(params.value),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'dateFrom',
            headerName: 'From date',
            valueFormatter: (params: GridValueFormatterParams<Invoice['dateFrom']>) => toYearMonthDayTime(params.value),
            flex: 0.5,
            minWidth: 150,
        },
        {
            field: 'dateUntil',
            headerName: 'Until date',
            valueFormatter: (params: GridValueFormatterParams<Invoice['dateUntil']>) =>
                toYearMonthDayTime(params.value),
            flex: 0.5,
            minWidth: 150,
        },
        {
            field: 'addressName',
            headerName: 'Company name',
            flex: 1,
            minWidth: 180,
        },
        {
            field: 'payable',
            headerName: 'Amount payable',
            valueFormatter: (params: GridValueFormatterParams<Invoice['payable']>) => formatPrice(params.value),
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'marketplaceCode',
            headerName: 'Marketplace',
            valueFormatter: (params: GridValueFormatterParams<Invoice['marketplaceCode']>) =>
                params.value.toUpperCase(),
            flex: 0.5,
            minWidth: 100,
        },
        {
            field: 'download-button',
            headerName: 'Download',
            flex: 0,
            width: 80,
            renderCell: renderDownloadButton,
        },
    ];

    if (hasError) return <Error errorMsg='Could not fetch invoices' />;
    return (
        <>
            {hasInvoiceDownloadError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Download of the PDF invoice failed' />
                </div>
            )}
            <DataGrid
                columns={columns}
                rows={invoices}
                rowCount={rowCountState}
                rowsPerPageOptions={[INITIAL_PAGE_SIZE, 25, 50, 100]}
                onCellClick={handleDownloadClick}
                loading={isLoading}
                components={{
                    LoadingOverlay: DataGridLinearProgress,
                    Toolbar: DataGridExportToolbar,
                }}
                paginationMode='server'
                page={page}
                pageSize={pageSize}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                autoHeight
                sx={{ cursor: 'pointer' }}
            />
        </>
    );
};
