export const formatShopifyLink = ({
    shopifyOrderId,
    marketplaceCode,
    shopifyUrlNl,
    shopifyUrlDe,
}: {
    shopifyOrderId: number;
    marketplaceCode: Marketplace;
    shopifyUrlNl: string | undefined;
    shopifyUrlDe: string | undefined;
}) => {
    if (!shopifyUrlNl || !shopifyUrlDe) return 'No valid link';
    if (marketplaceCode === 'nl') return `${shopifyUrlNl}${shopifyOrderId}`;
    return `${shopifyUrlDe}${shopifyOrderId}`;
};
