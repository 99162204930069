import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { GridRenderCellParams } from '@mui/x-data-grid';

/**
 * Copied from MUI
 * By default, the grid cuts the content of a cell and renders an ellipsis if the content of the cell does not fit in the cell.
 * As a workaround, you can create a cell renderer that will allow seeing the full content of the cell in the grid.
 * Source: https://mui.com/x/react-data-grid/columns/#RenderExpandCellGrid.tsx
 *
 * !!! NOTE: For the ease of use we are exporting the renderCellExpand function
 */

interface GridCellExpandProps {
    value: string;
    width: number;
}

const isOverflown = (element: Element): boolean =>
    element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;

const GridCellExpand = React.memo(function GridCellExpand(props: GridCellExpandProps) {
    const { width, value } = props;
    const wrapper = useRef<HTMLDivElement | null>(null);
    const cellDiv = useRef(null);
    const cellValue = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showFullCell, setShowFullCell] = useState(false);
    const [showPopper, setShowPopper] = useState(false);

    const handleMouseEnter = () => {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const isCurrentlyOverflown = isOverflown(cellValue.current!);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: 1,
                height: 1,
                position: 'relative',
                display: 'flex',
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <Box ref={cellValue} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {value}
            </Box>
            {showPopper && (
                <Popper open={showFullCell && anchorEl !== null} anchorEl={anchorEl} style={{ width }}>
                    <Paper elevation={1} style={{ minHeight: wrapper.current!.offsetHeight - 3 }}>
                        <Typography variant='body2' style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </Box>
    );
});

// Render tooltip to match the column width as default, or pass a custom width
export const renderCellExpand = (params: GridRenderCellParams<string>, width = 0) => (
    <GridCellExpand value={params.value || ''} width={width || params.colDef.computedWidth} />
);
