import React, { useId } from 'react';
import { InputLabel, Select, SelectChangeEvent, MenuItem, FormControl, FormHelperText } from '@mui/material';
import { capitalize } from '../../../../../utils/formatting';
import styles from '../inputs.module.scss';

interface SelectFieldProps {
    label: string;
    value: string;
    options: { value: string | null; text: string }[] | (string | null)[];
    handleChange: (e: SelectChangeEvent) => void;
    hasExtraLabel?: boolean;
    error?: boolean;
    helperText?: string;
}

export const SelectInput = ({
    label,
    hasExtraLabel = true,
    options,
    value,
    handleChange,
    error = false,
    helperText = '',
}: SelectFieldProps) => {
    const id = useId();

    const selectOptions = options.map((option) => {
        // Need additional check against null as typeof null === 'object' is true
        if (typeof option === 'object' && option !== null) {
            const { value: optionValue, text } = option;
            return (
                <MenuItem
                    key={optionValue === null ? 'unknown' : optionValue}
                    value={optionValue === null ? '' : optionValue}
                >
                    {text}
                </MenuItem>
            );
        }
        return (
            <MenuItem key={option === null ? 'unknown' : option} value={option === null ? '' : option}>
                {option === null ? 'Unknown' : capitalize(option)}
            </MenuItem>
        );
    });

    return (
        <div className={styles.selectInput}>
            {hasExtraLabel && (
                <label htmlFor={id} aria-hidden='true' className={styles.selectInput__label}>
                    {label}
                </label>
            )}
            <div className={styles.selectInput__inputWrapper}>
                <FormControl fullWidth error={error}>
                    <InputLabel id={id}>{label}</InputLabel>
                    <Select
                        id={id}
                        name={`select-input-${id}`}
                        labelId={id}
                        value={value}
                        label={label}
                        onChange={handleChange}
                    >
                        {selectOptions}
                    </Select>
                    {error && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            </div>
        </div>
    );
};
