import { format } from 'date-fns';

export const toDateTime = (dateString: string): string => format(new Date(dateString), 'yyyy-MM-dd HH:mm:ss');

export const toYearMonthDayTime = (date: string | Date): string => format(new Date(date), 'yyyy-MM-dd HH:mm');

export const toYearMonthDay = (date: Date): string => format(new Date(date), 'yyyy-MM-dd');

export const getYear = (date: Date): string => format(new Date(date), 'yyyy');

export const getMonth = (date: Date): string => format(new Date(date), 'MM');

export const currentYear = new Date().getFullYear();

export const isDateValid = (date: unknown) => date instanceof Date && !Number.isNaN(date.valueOf());
