import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';
import { currentYear } from '../../utils/dates';
import { toUrlParams } from '../../utils/formatting';

const initialState = {
    invoicesParams: {
        marketplace: '' as InvoicesParams['marketplace'],
        dateFrom: `${currentYear}-01-01`,
        dateUntil: `${currentYear}-12-31`,
        invoiceRunId: '',
        search: '',
    } as InvoicesParams,
    invoices: [] as Invoice[],
    paging: {
        currentPage: 1,
        totalPages: 1,
        totalCount: 1,
        pageCount: 1,
    } as Pagination,
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const invoicesSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        invoicesLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        invoicesReceived(state, action: PayloadAction<Invoices>) {
            return {
                ...state,
                invoices: action.payload.invoices,
                paging: action.payload.paging,
                isLoading: false,
            };
        },
        invoicesParamsUpdated(state, action: PayloadAction<Partial<InvoicesParams>>) {
            return {
                ...state,
                invoicesParams: {
                    ...state.invoicesParams,
                    ...action.payload,
                },
            };
        },
        invoicesError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
    },
});

export default invoicesSlice.reducer;

const { invoicesLoading, invoicesReceived, invoicesError } = invoicesSlice.actions;
export const { invoicesParamsUpdated: updateInvoicesParams } = invoicesSlice.actions;

type GetInvoicesParams = {
    page: number;
    pageSize: number;
    params: InvoicesParams;
};

export const getInvoices =
    ({ page, pageSize, params }: GetInvoicesParams) =>
    async (dispatch: Dispatch) => {
        dispatch(invoicesLoading());
        try {
            const urlParams = toUrlParams(params);
            const result = await getGatewayApiInstance().get(`/invoices?limit=${pageSize}&page=${page}${urlParams}`);
            dispatch(invoicesReceived(result.data));
        } catch (err: unknown) {
            dispatch(invoicesError());
            handleAxiosErrors(err);
        }
    };

// Selectors
export const selectInvoices = (state: State): Invoice[] => state.invoices;
export const selectInvoicesTotal = (state: State): number => state.paging.totalCount;
export const selectInvoicesParams = (state: State): InvoicesParams => state.invoicesParams;
// Loading
export const selectIsLoadingInvoices = (state: State): boolean => state.isLoading;
export const selectHasInvoicesError = (state: State): boolean => state.hasError;
