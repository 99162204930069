import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../../../redux/typed-hooks';
import { addTemporaryTransaction } from '../../../../../../redux/reducers/transactions';
import {
    selectOrderNumber,
    selectUniqueOrderSkus,
    selectPriceBasedOnSku,
    selectProductNameBasedOnSku,
    selectSupplierNumberBasedOnSku,
    selectQuantityBasedOnSku,
    selectOrderMarketplace,
    selectPaymentSourceBasedOnPaymentTransaction,
} from '../../../../../../redux/reducers';
import { useDateInput, useNumberInput, useSelectInput, useTextInput } from '../../../../../shared/Form/Inputs';
import { useFetchTransactionCancellationInfo } from './useFetchTransactionCancellationInfo';
import { validateRequiredDateField, validateRequiredField } from '../../../../../../utils/validation';
import { toDateTime } from '../../../../../../utils/dates';
import { capitalize, toNumber } from '../../../../../../utils/formatting';

export const useNewTransactionForm = ({
    manualTransactionsInfo,
    handleHideTransactionForm,
}: {
    manualTransactionsInfo: TransactionInfo[];
    handleHideTransactionForm: () => void;
}) => {
    const dispatch = useAppDispatch();
    const transactionTypes = manualTransactionsInfo.map((info) => info.transaction);
    const transactionType = useSelectInput(transactionTypes[0] || '');

    const transactionInfoBasedOnTransactionType: TransactionInfo = manualTransactionsInfo.filter(
        (info) => info.transaction === transactionType.value
    )[0];

    const {
        requiredFields: requiredFieldsBasedOnTransactionType,
        balanceType: balanceTypeBasedOnTransactionType,
        description: transactionTypeDescription,
    } = transactionInfoBasedOnTransactionType;

    const datetime = useDateInput(new Date().toString());
    const dateTimeString = datetime.value ? toDateTime(datetime.value.toString()) : '';
    const orderNumber = useAppSelector(selectOrderNumber);
    const defaultDescription = `${capitalize(transactionType.value)} order #${orderNumber}`;
    const [description, setDescription] = useState({ value: defaultDescription, isTyping: false });
    const amount = useNumberInput(0);
    const sku = useSelectInput('');
    const supplierNumberBasedOnSku = useAppSelector((state) => selectSupplierNumberBasedOnSku(state, sku.value));
    const [supplierNumber, setSupplierNumber] = useState<{ value: number | ''; isTyping: boolean }>({
        value: supplierNumberBasedOnSku,
        isTyping: false,
    });
    const priceBasedOnSku = useAppSelector((state) => selectPriceBasedOnSku(state, sku.value));
    const [price, setPrice] = useState<{ value: number | ''; isTyping: boolean }>({
        value: priceBasedOnSku,
        isTyping: false,
    });
    const quantityBasedOnSku = useAppSelector((state) => selectQuantityBasedOnSku(state, sku.value));
    const [quantity, setQuantity] = useState<{ value: number | ''; isTyping: boolean }>({
        value: quantityBasedOnSku,
        isTyping: false,
    });
    const productName = useAppSelector((state) => selectProductNameBasedOnSku(state, sku.value));
    const paymentSourceBasedOnPaymentTransaction = useAppSelector(selectPaymentSourceBasedOnPaymentTransaction);
    const paymentSource = useTextInput(paymentSourceBasedOnPaymentTransaction);
    const marketplaceCode = useAppSelector(selectOrderMarketplace);
    const uniqueOrderSkus = useAppSelector(selectUniqueOrderSkus);

    const { cancellationReasons, isLoadingCancellationReasons, hasCancellationReasonsError } =
        useFetchTransactionCancellationInfo(transactionType.value === 'cancellation');
    const cancelReasonId = useSelectInput('');

    const [formErrors, setFormErrors] = useState({
        externalTransactionId: '',
        datetime: '',
        amount: '',
        description: '',
        paymentSource: '',
        supplierNumber: '',
        sku: '',
        productName: '',
        brand: '',
        price: '',
        quantity: '',
        orderNumber: '',
        marketplaceCode: '',
        type: '',
        cancelReasonId: '',
        trackingCompany: '',
        trackingNumbers: '',
        trackingUrls: '',
        shipmentStatus: '',
    });

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDescription({ value: e.target.value, isTyping: true });
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setPrice({ value: value ? toNumber(value) : '', isTyping: true });
    };

    const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setQuantity({ value: value ? toNumber(value) : '', isTyping: true });
    };

    const handleSupplierNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSupplierNumber({ value: value ? toNumber(value) : '', isTyping: true });
    };

    useEffect(() => {
        setDescription({
            value: `${capitalize(transactionType.value)} order #${orderNumber}`,
            isTyping: false,
        });
    }, [transactionType.value, orderNumber]);

    useEffect(() => {
        setSupplierNumber({
            value: supplierNumberBasedOnSku,
            isTyping: false,
        });
    }, [supplierNumberBasedOnSku]);

    useEffect(() => {
        setPrice({
            value: priceBasedOnSku,
            isTyping: false,
        });
    }, [priceBasedOnSku]);

    useEffect(() => {
        setQuantity({
            value: quantityBasedOnSku,
            isTyping: false,
        });
    }, [quantityBasedOnSku]);

    const handleAddTemporaryTransaction = () => {
        const newFormErrors = {
            datetime: validateRequiredDateField({ value: datetime.value, fieldName: 'Date' }),
            description: validateRequiredField({
                value: description.value,
                fieldName: 'Description',
            }),
            amount: validateRequiredField({
                value: amount.value,
                fieldName: 'Amount',
            }),
            paymentSource: validateRequiredField({
                value: paymentSource.value,
                fieldName: 'Payment source',
            }),
            supplierNumber: validateRequiredField({
                value: supplierNumber.value,
                fieldName: 'Supplier number',
            }),
            price: validateRequiredField({
                value: price.value,
                fieldName: 'Price',
            }),
            quantity: validateRequiredField({
                value: quantity.value,
                fieldName: 'Quantity',
            }),
            type: validateRequiredField({
                value: transactionType.value,
                fieldName: 'Transaction type',
            }),
            sku: validateRequiredField({
                value: sku.value,
                fieldName: 'SKU',
            }),
            cancelReasonId: validateRequiredField({
                value: cancelReasonId.value,
                fieldName: 'Cancellation reason',
            }),
            // We do not provide inputs for these fields - we retrieve values from the store
            productName: '',
            orderNumber: '',
            externalTransactionId: '',
            marketplaceCode: '',
            brand: '', // always sending an empty string
            trackingCompany: '',
            trackingNumbers: '',
            trackingUrls: '',
            shipmentStatus: '',
        };

        setFormErrors(newFormErrors);

        // Check if fields required by the selected transaction type are valid (and ignore remaining fields)
        const formHasNoErrors = requiredFieldsBasedOnTransactionType.every(
            (field) => newFormErrors[field as keyof CreateTransaction] === ''
        );

        if (!formHasNoErrors) return;

        const temporaryTransaction: TemporaryTransaction = {
            type: transactionType.value,
            datetime: dateTimeString,
            // Expecting marketplace code to be present so casting as Marketplace. In the event of Marketplace being null, we will get a server error
            marketplaceCode: marketplaceCode as Marketplace,
            orderNumber: orderNumber || 0,
            externalTransactionId: orderNumber?.toString() || '0',
            amount: amount.value || 0,
            balanceType: balanceTypeBasedOnTransactionType,
            description: description.value,
            paymentSource: paymentSource.value,
            supplierNumber: supplierNumber.value || 0,
            sku: sku.value,
            productName,
            brand: '', // always sending an empty string
            price: price.value || 0,
            quantity: quantity.value || 0,
            cancelReasonId: cancelReasonId.value ? toNumber(cancelReasonId.value) : null,
            // Extra keys below are necessary to populate the DataGrid
            id: 'temporary-transaction-x', // X gets replaced by a unique id number in Redux
            isAccountable: true,
            finalised: null,
            trackingCompany: '',
            trackingNumbers: [],
            trackingUrls: [],
            shipmentStatus: '',
        };

        dispatch(addTemporaryTransaction(temporaryTransaction));
        handleHideTransactionForm();
    };

    return {
        handleAddTemporaryTransaction,
        transactionTypes,
        requiredFieldsBasedOnTransactionType,
        transactionTypeDescription,
        uniqueOrderSkus,
        formErrors,
        transactionType,
        datetime,
        description,
        defaultDescription,
        handleDescriptionChange,
        sku,
        amount,
        supplierNumber,
        supplierNumberBasedOnSku,
        handleSupplierNumberChange,
        price,
        priceBasedOnSku,
        handlePriceChange,
        quantity,
        quantityBasedOnSku,
        handleQuantityChange,
        paymentSource,
        cancelReasonId,
        isLoadingCancellationReasons,
        hasCancellationReasonsError,
        cancellationReasons,
    };
};
