export const getTemporaryInstantDownloadLink = ({
    url,
    fileName,
    fileExtension,
}: {
    url: string;
    fileName: string;
    fileExtension: 'csv' | 'pdf' | 'zip';
}) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.${fileExtension}`);
    document.body.appendChild(link);
    return link;
};
