import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { getExactExportRunStatus } from '../../redux/reducers/exactExportRunStatus';
import {
    selectCanCreateNewExactExportRun,
    selectIsLoadingExactExportRunStatus,
    selectHasExactExportRunStatusError,
} from '../../redux/reducers';

export const useFetchExactExportRunStatus = () => {
    const dispatch = useAppDispatch();
    const isLoadingExactExportRunStatus = useAppSelector(selectIsLoadingExactExportRunStatus);
    const hasExactExportRunStatusError = useAppSelector(selectHasExactExportRunStatusError);
    const canCreateNewExactExportRun = useAppSelector(selectCanCreateNewExactExportRun);

    useEffect(() => {
        dispatch(getExactExportRunStatus());
    }, [dispatch]);

    return { isLoadingExactExportRunStatus, hasExactExportRunStatusError, canCreateNewExactExportRun };
};
