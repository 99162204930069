import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

export const useSelectInput = (initialValue: string | null) => {
    const [value, setValue] = useState(initialValue === null ? '' : initialValue);

    const handleChange = (e: SelectChangeEvent<string>) => {
        setValue(e.target.value);
    };

    return { value, handleChange };
};
