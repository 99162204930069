import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    transactionsInfo: [] as TransactionInfo[],
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const transactionTypesSlice = createSlice({
    name: 'transactionTypes',
    initialState,
    reducers: {
        transactionTypesLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        transactionTypesReceived(state, action: PayloadAction<TransactionInfo[]>) {
            return {
                ...state,
                transactionsInfo: action.payload,
                isLoading: false,
            };
        },
        transactionTypesError(state) {
            return {
                ...state,
                hasError: true,
                isLoading: false,
            };
        },
        transactionTypesErrorCleared(state) {
            return {
                ...state,
                hasError: false,
            };
        },
    },
});

export default transactionTypesSlice.reducer;

const { transactionTypesLoading, transactionTypesReceived, transactionTypesError } = transactionTypesSlice.actions;

export const { transactionTypesErrorCleared: clearTransactionTypesError } = transactionTypesSlice.actions;

export const getTransactionTypes = () => async (dispatch: Dispatch) => {
    dispatch(transactionTypesLoading());
    try {
        const res = await getGatewayApiInstance().get(`/transactions/types`);
        dispatch(transactionTypesReceived(res.data));
    } catch (err: unknown) {
        dispatch(transactionTypesError());
        // Temporarily logging errors to the console
        handleAxiosErrors(err);
    }
};

// Selectors
// Data
export const selectManualTransactionsInfo = (state: State): TransactionInfo[] =>
    state.transactionsInfo.filter((transactionInfo) => transactionInfo.allowManualCreate);
export const selectAllTransactionsInfo = (state: State): TransactionInfo[] => state.transactionsInfo;
// Loading
export const selectIsLoadingTransactionTypes = (state: State) => state.isLoading;
// Errors
export const selectHasTransactionTypesError = (state: State) => state.hasError;
