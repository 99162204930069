import React from 'react';
import { DataGrid, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import {
    DataGridExportToolbar,
    DataGridNoRowsOverlay,
    DataGridLinearProgress,
    useDataGridServerSidePagination,
} from '../shared/DataGrid';
import { Error } from '../shared';
import { DownloadButton } from '../shared/Buttons';
import { useFetchExactExports } from './useFetchExactExports';
import { useDownloadExactExport } from './useDownloadExactExport';
import { formatExactExportType } from '../../utils/exactExports';
import { capitalize } from '../../utils/formatting';
import { toYearMonthDayTime } from '../../utils/dates';
import styles from './exactExports.module.scss';

export const ExactExports = () => {
    const INITIAL_PAGE_SIZE = 10;
    const { rowCountState, page, pageSize, handlePageChange, handlePageSizeChange, setRowCount } =
        useDataGridServerSidePagination({
            initialPageSize: INITIAL_PAGE_SIZE,
        });

    const { exactExports, isLoading, hasError } = useFetchExactExports({ page, pageSize, setRowCount });

    const { handleDownloadClick, hasExactExportDownloadError } = useDownloadExactExport();

    const renderDownloadButton = () => (
        <DownloadButton
            handleClick={() => {
                // This is intentional
            }}
        />
    );

    const columns: GridColDef[] = [
        {
            field: 'created',
            headerName: 'Created',
            valueFormatter: (params: GridValueFormatterParams<ExactExport['created']>) =>
                toYearMonthDayTime(params.value),
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'maxOrderNumber',
            headerName: 'Max Order Number',
            flex: 0.8,
            minWidth: 100,
        },
        {
            field: 'maxTransactionDateTime',
            headerName: 'Max Transaction Date',
            valueFormatter: (params: GridValueFormatterParams<ExactExport['maxTransactionDateTime']>) =>
                toYearMonthDayTime(params.value),
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'finalised',
            headerName: 'Finalised',
            valueFormatter: (params: GridValueFormatterParams<ExactExport['finalised']>) =>
                toYearMonthDayTime(params.value),
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'marketplaceCode',
            headerName: 'Marketplace',
            valueFormatter: (params: GridValueFormatterParams<ExactExport['marketplaceCode']>) =>
                params.value.toUpperCase(),
            flex: 0.8,
            minWidth: 100,
        },
        {
            field: 'type',
            headerName: 'Type',
            valueFormatter: (params: GridValueFormatterParams<ExactExport['type']>) =>
                capitalize(formatExactExportType(params.value)),
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'download-button',
            headerName: 'Download',
            flex: 0,
            width: 80,
            renderCell: renderDownloadButton,
        },
    ];

    if (hasError) return <Error />;
    return (
        // Use wrapper to prevent the DataGrid from stretching vertically
        <div className={styles.exactExports}>
            {hasExactExportDownloadError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Exact Export CSV download failed' />
                </div>
            )}
            <DataGrid
                columns={columns}
                rows={exactExports}
                getRowId={(row) => row.id}
                rowCount={rowCountState}
                rowsPerPageOptions={[INITIAL_PAGE_SIZE, 25, 50, 100]}
                onCellClick={handleDownloadClick}
                paginationMode='server'
                page={page}
                pageSize={pageSize}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                loading={isLoading}
                components={{
                    LoadingOverlay: DataGridLinearProgress,
                    Toolbar: DataGridExportToolbar,
                    NoRowsOverlay: DataGridNoRowsOverlay,
                }}
                autoHeight
                sx={{ cursor: 'pointer' }}
            />
        </div>
    );
};
