import { useEffect, useState } from 'react';
import { debounce } from '@app/utils/debounce';
import { useAppDispatch, useAppSelector } from '@app/redux/typed-hooks';
import { getInvoiceRunSuppliers } from '@app/redux/reducers/invoiceRunSuppliers';
import {
    selectInvoiceRunSuppliers,
    selectIsLoadingInvoiceRunSuppliers,
    selectHasInvoiceRunSuppliersError,
    selectInvoiceRunSuppliersPage,
} from '@app/redux/reducers';

export const useFetchInvoiceRunSuppliers = () => {
    const dispatch = useAppDispatch();
    const isLoadingInvoiceRunSuppliers = useAppSelector(selectIsLoadingInvoiceRunSuppliers);
    const hasInvoiceRunSuppliersError = useAppSelector(selectHasInvoiceRunSuppliersError);
    const invoiceRunSuppliers = useAppSelector(selectInvoiceRunSuppliers);
    const invoiceRunSuppliersPage = useAppSelector(selectInvoiceRunSuppliersPage);

    const [searchValue, setSearchValue] = useState<string>('');
    const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    // eslint-disable-next-line
    useEffect(
        debounce(() => {
            dispatch(getInvoiceRunSuppliers(invoiceRunSuppliersPage, searchValue));
        }, 600),
        [dispatch, invoiceRunSuppliersPage, searchValue]
    );

    return {
        invoiceRunSuppliers,
        isLoadingInvoiceRunSuppliers,
        hasInvoiceRunSuppliersError,
        searchValue,
        onSearchValueChange,
    };
};
