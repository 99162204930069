import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface CheckboxInputProps {
    label: string;
    checked: boolean;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxInput = ({ label, checked, handleChange }: CheckboxInputProps) => {
    return <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label={label} />;
};
