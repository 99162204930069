import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { notificationUpdated } from './notifications';
import { redirectLinkUpdated } from './redirect';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';
import { currentYear } from '../../utils/dates';
import { toUrlParams } from '../../utils/formatting';

const initialState = {
    invoiceRuns: [] as InvoiceRun[],
    invoiceRunsParams: {
        dateFrom: `${currentYear - 2}-01-01`,
        dateUntil: `${currentYear}-12-31`,
    } as InvoiceRunsParams,
    paging: {
        currentPage: 1,
        totalPages: 1,
        totalCount: 1,
        pageCount: 1,
    } as Pagination,
    isLoading: false,
    isLoadingNewInvoiceRun: false,
    hasError: false,
    hasNewInvoiceRunError: false,
};

type State = typeof initialState;

const invoiceRunsSlice = createSlice({
    name: 'invoiceRuns',
    initialState,
    reducers: {
        invoiceRunsLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        newInvoiceRunLoading(state) {
            return {
                ...state,
                isLoadingNewInvoiceRun: true,
                hasNewInvoiceRunError: false,
            };
        },
        invoiceRunsReceived(state, action: PayloadAction<InvoiceRuns>) {
            return {
                ...state,
                invoiceRuns: action.payload.invoiceRuns,
                paging: action.payload.paging,
                isLoading: false,
            };
        },
        newInvoiceRunStarted(state) {
            return {
                ...state,
                isLoadingNewInvoiceRun: false,
            };
        },
        invoiceRunsParamsUpdated(state, action: PayloadAction<Partial<InvoiceRunsParams>>) {
            return {
                ...state,
                invoiceRunsParams: {
                    ...state.invoiceRunsParams,
                    ...action.payload,
                },
            };
        },
        invoiceRunsError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
        newInvoiceRunError(state) {
            return {
                ...state,
                isLoadingNewInvoiceRun: false,
                hasNewInvoiceRunError: true,
            };
        },
    },
});

export default invoiceRunsSlice.reducer;

const {
    invoiceRunsLoading,
    newInvoiceRunLoading,
    invoiceRunsReceived,
    newInvoiceRunStarted,
    invoiceRunsError,
    newInvoiceRunError,
} = invoiceRunsSlice.actions;
export const { invoiceRunsParamsUpdated: updateInvoiceRunsParams } = invoiceRunsSlice.actions;

type GetInvoiceRunsParams = {
    page: number;
    pageSize: number;
    params: InvoiceRunsParams;
};

export const getInvoiceRuns =
    ({ page, pageSize, params }: GetInvoiceRunsParams) =>
    async (dispatch: Dispatch) => {
        dispatch(invoiceRunsLoading());
        try {
            const urlParams = toUrlParams(params);
            const result = await getGatewayApiInstance().get(
                `/invoice-runs?limit=${pageSize}&page=${page}${urlParams}`
            );
            dispatch(invoiceRunsReceived(result.data));
        } catch (err: unknown) {
            dispatch(invoiceRunsError());
            handleAxiosErrors(err);
        }
    };

export const startNewInvoiceRun = (newInvoiceRun: NewInvoiceRun) => async (dispatch: Dispatch) => {
    dispatch(newInvoiceRunLoading());
    try {
        await getGatewayApiInstance().post('/invoice-runs', newInvoiceRun);
        dispatch(newInvoiceRunStarted());
        dispatch(redirectLinkUpdated('/invoice-runs'));
        dispatch(notificationUpdated('New invoice run is in progress'));
    } catch (err: unknown) {
        dispatch(newInvoiceRunError());
        // Temporarily logging errors to the console
        handleAxiosErrors(err);
    }
};

// Selectors
// Data
export const selectInvoiceRuns = (state: State): InvoiceRun[] => state.invoiceRuns;
export const selectInvoiceRunsTotal = (state: State): Pagination['totalCount'] => state.paging.totalCount;
export const selectInvoiceRunsParams = (state: State): InvoiceRunsParams => state.invoiceRunsParams;
// Loading
export const selectIsLoadingInvoiceRuns = (state: State): boolean => state.isLoading;
export const selectIsLoadingNewInvoiceRun = (state: State): boolean => state.isLoadingNewInvoiceRun;
// Errors
export const selectHasInvoiceRunsError = (state: State): boolean => state.hasError;
export const selectHasNewInvoiceRunError = (state: State): boolean => state.hasNewInvoiceRunError;
