import React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

interface VerticalTableProps {
    title?: string;
    tableContent: { heading: string; data: string | number; rowAlert?: Alert }[];
}

export const VerticalTable = ({ title, tableContent }: VerticalTableProps) => {
    const getBgColor = (rowAlert: Alert | undefined) => {
        if (rowAlert === 'warn' || rowAlert === 'error') return '#fdeded';
        if (rowAlert === 'success') return '#edf7ed';
        return '#fff';
    };

    const tableRows = tableContent.map((content) => {
        const { heading, data, rowAlert } = content;
        return (
            <TableRow
                key={heading}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: getBgColor(rowAlert) }}
            >
                <TableCell variant='head' component='th'>
                    {heading}
                </TableCell>
                <TableCell>{data}</TableCell>
            </TableRow>
        );
    });
    return (
        <TableContainer>
            <Table>
                {title && (
                    <TableHead>
                        <TableRow>
                            <TableCell
                                variant='head'
                                colSpan={2}
                                sx={{ borderBottom: '2px solid black', fontSize: '1rem' }}
                            >
                                {title}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>{tableRows}</TableBody>
            </Table>
        </TableContainer>
    );
};
