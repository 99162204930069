import * as yup from 'yup';

export const TTPFileValidationSchema = yup.object({
    Marketplace: yup.string().required(),
    Type: yup.string().required(),
    OrderNumber: yup.string().required(),
    Date: yup.string().required(),
    Description: yup.string().required(),
    VendorNumber: yup.string(),
    PaymentSource: yup.string(),
    SKU: yup.string(),
    Name: yup.string(),
    Brand: yup.string(),
    Price: yup.string(),
    Amount: yup.string(),
    Quantity: yup.string(),
    CancelReasonId: yup.string(),
});
