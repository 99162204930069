import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { notificationUpdated } from './notifications';
import { getRemainingTempTransactions } from '../../utils/transactions';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    temporaryTransactions: [] as TemporaryTransaction[],
    rejectedTransactions: [] as TemporaryTransaction[],
    temporaryTransactionIdGenerator: 0,
    selectedTemporaryTransactionsIds: [] as string[],
    isLoadingPostTransaction: false,
    hasPostTransactionError: false,
    isConfirmDialogOpen: false,
};

type State = typeof initialState;

const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        newTransactionLoading(state) {
            return {
                ...state,
                isLoadingPostTransaction: true,
                hasPostTransactionError: false,
            };
        },
        temporaryTransactionAdded(state, action: PayloadAction<TemporaryTransaction>) {
            return {
                ...state,
                temporaryTransactionIdGenerator: state.temporaryTransactionIdGenerator + 1,
                temporaryTransactions: [
                    ...state.temporaryTransactions,
                    {
                        ...action.payload,
                        id: `temporary-transaction-${state.temporaryTransactionIdGenerator + 1}`,
                    },
                ],
            };
        },
        temporaryTransactionsSelected(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                selectedTemporaryTransactionsIds: action.payload,
            };
        },
        selectedTemporaryTransactionsDeleted(state) {
            return {
                ...state,
                temporaryTransactions: getRemainingTempTransactions({
                    transactions: state.temporaryTransactions,
                    idsToDelete: state.selectedTemporaryTransactionsIds,
                }),
                rejectedTransactions: getRemainingTempTransactions({
                    transactions: state.rejectedTransactions,
                    idsToDelete: state.selectedTemporaryTransactionsIds,
                }),
            };
        },
        newTransactionReceived(state, action: PayloadAction<{ tempId: string; transaction: Transaction }>) {
            const { tempId } = action.payload;
            return {
                ...state,
                isLoadingPostTransaction: false,
                temporaryTransactions: getRemainingTempTransactions({
                    transactions: state.temporaryTransactions,
                    idsToDelete: [tempId],
                }),
                rejectedTransactions: getRemainingTempTransactions({
                    transactions: state.rejectedTransactions,
                    idsToDelete: [tempId],
                }),
            };
        },
        newTransactionError(state, action: PayloadAction<string>) {
            const tempId = action.payload;
            const rejectedTransaction = state.temporaryTransactions.filter(
                (tempTransaction) => tempTransaction.id === tempId
            )[0];
            return {
                ...state,
                hasPostTransactionError: true,
                isLoadingPostTransaction: false,
                rejectedTransactions: [...state.rejectedTransactions, rejectedTransaction],
                temporaryTransactions: getRemainingTempTransactions({
                    transactions: state.temporaryTransactions,
                    idsToDelete: [tempId],
                }),
            };
        },
        newTransactionErrorCleared(state) {
            return {
                ...state,
                hasPostTransactionError: false,
            };
        },
        confirmDialogOpened(state) {
            return {
                ...state,
                isConfirmDialogOpen: true,
            };
        },
        confirmDialogClosed(state) {
            return {
                ...state,
                isConfirmDialogOpen: false,
            };
        },
    },
});

export default transactionsSlice.reducer;

const { newTransactionLoading, newTransactionError } = transactionsSlice.actions;

export const {
    newTransactionErrorCleared: clearNewTransactionError,
    temporaryTransactionAdded: addTemporaryTransaction,
    temporaryTransactionsSelected: saveSelectedTemporaryTransactionsIds,
    selectedTemporaryTransactionsDeleted: deleteSelectedTemporaryTransactions,
    confirmDialogOpened: openConfirmDialog,
    confirmDialogClosed: closeConfirmDialog,
    newTransactionReceived,
} = transactionsSlice.actions;

export const createNewTransaction =
    ({ newTransaction, tempId }: { newTransaction: CreateTransaction; tempId: string }) =>
    async (dispatch: Dispatch) => {
        dispatch(newTransactionLoading());
        try {
            const res = await getGatewayApiInstance().post(`/transactions`, newTransaction);
            dispatch(newTransactionReceived({ tempId, transaction: res.data }));
            dispatch(notificationUpdated('New transaction was successfully created'));
        } catch (err: unknown) {
            dispatch(newTransactionError(tempId));
            // Temporarily logging errors to the console
            handleAxiosErrors(err);
        }
    };

// Selectors
// Data
export const selectTemporaryTransactionsPerOrder = (
    state: State,
    orderNumber: Transaction['orderNumber'] | null
): TemporaryTransaction[] =>
    state.temporaryTransactions.filter((tempTransaction) => tempTransaction.orderNumber === orderNumber);

export const selectRejectedTransactionsPerOrder = (
    state: State,
    orderNumber: Transaction['orderNumber'] | null
): TemporaryTransaction[] =>
    state.rejectedTransactions.filter((rejectedTransaction) => rejectedTransaction.orderNumber === orderNumber);

export const selectTemporaryTransactionsInclRejectedPerOrder = (
    state: State,
    orderNumber: Transaction['orderNumber'] | null
): TemporaryTransaction[] =>
    [...state.temporaryTransactions, ...state.rejectedTransactions].filter(
        (tempTransaction) => tempTransaction.orderNumber === orderNumber
    );

export const selectSelectedTemporaryTransactionsIds = (state: State): string[] =>
    state.selectedTemporaryTransactionsIds;

export const selectIsSubmitTransactionsConfirmDialogOpen = (state: State): boolean => state.isConfirmDialogOpen;
// Loading
export const selectIsLoadingNewTransaction = (state: State) => state.isLoadingPostTransaction;
// Errors
export const selectHasNewTransactionError = (state: State) => state.hasPostTransactionError;
