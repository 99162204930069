import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { selectHasExactExportRunDownloadError } from '../../redux/reducers';
import { downloadExactExportRun, clearExactExportRunDownloadError } from '../../redux/reducers/exactExportRunDownload';

export const useDownloadExactExportRun = () => {
    const dispatch = useAppDispatch();
    const hasExactExportRunDownloadError = useAppSelector(selectHasExactExportRunDownloadError);

    const handleDownloadClick = ({ id, fileName }: { id: number; fileName: string }) => {
        dispatch(downloadExactExportRun({ id, fileName }));
    };

    useEffect(() => {
        dispatch(clearExactExportRunDownloadError());
    }, [dispatch]);

    return { handleDownloadClick, hasExactExportRunDownloadError };
};
