export enum TTPStatus {
    TO_PROCESS = 'TO_PROCESS',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export enum TTPType {
    ConsumerDiscountRefundTransaction = 'ConsumerDiscountRefundTransaction',
    ConsumerDiscountTransaction = 'ConsumerDiscountTransaction',
    ConsumerPaymentDiscrepancyTransaction = 'ConsumerPaymentDiscrepancyTransaction',
    ConsumerPaymentTransaction = 'ConsumerPaymentTransaction',
    ConsumerRefundTransaction = 'ConsumerRefundTransaction',
    ConsumerSettledReturnShippingCostsTransaction = 'ConsumerSettledReturnShippingCostsTransaction',
    MarketplaceChargebackCostsTransaction = 'MarketplaceChargebackCostsTransaction',
    MarketplaceCompensationForConsumerTransaction = 'MarketplaceCompensationForConsumerTransaction',
    MarketplaceExplanationForCreditPaymentDiscrepancyTransaction = 'MarketplaceExplanationForCreditPaymentDiscrepancyTransaction',
    MarketplaceRevenueCorrectionTransaction = 'MarketplaceRevenueCorrectionTransaction',
    MarketplaceVatShiftedForConsumerTransaction = 'MarketplaceVatShiftedForConsumerTransaction',
    SupplierCancellationTransaction = 'SupplierCancellationTransaction',
    SupplierCompensationAsRevenueTransaction = 'SupplierCompensationAsRevenueTransaction',
    SupplierCompensationFixedTransaction = 'SupplierCompensationFixedTransaction',
    SupplierCompensationForConsumerTransaction = 'SupplierCompensationForConsumerTransaction',
    SupplierCompensationForMarketplaceTransaction = 'SupplierCompensationForMarketplaceTransaction',
    SupplierVatShiftedCompensationForMarketplaceTransaction = 'SupplierVatShiftedCompensationForMarketplaceTransaction',
    ThirdPartyDeliveryCostsTransaction = 'ThirdPartyDeliveryCostsTransaction',
    SupplierFulfillmentTransaction = 'SupplierFulfillmentTransaction',
}

export interface TTPFileData {
    Marketplace?: string;
    Type?: string;
    OrderNumber?: string;
    Date?: string;
    Description?: string;
    VendorNumber?: string;
    PaymentSource?: string;
    SKU?: string;
    Name?: string;
    Brand?: string;
    Price?: string;
    Amount?: string;
    Quantity?: string;
    CancelReasonId?: string;
}

export interface ITTP {
    id: string;
    status: TTPStatus;
    transactionNumber: number;
    errorMessage?: string;

    Marketplace?: string;
    Type: TTPType;
    OrderNumber?: string;
    Date?: string;
    Description?: string;
    VendorNumber?: string;
    PaymentSource?: string;
    SKU?: string;
    Name?: string;
    Brand?: string;
    Price?: string;
    Amount?: string;
    Quantity?: string;
    CancelReasonId?: string;
}
