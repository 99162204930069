import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';
import { getTemporaryInstantDownloadLink } from '../../utils/download';

const initialState = {
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const exactExportRunDownloadSlice = createSlice({
    name: 'exactExportRunDownload',
    initialState,
    reducers: {
        exactExportRunDownloadLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        exactExportRunDownloaded(state) {
            return {
                ...state,
                isLoading: false,
            };
        },
        exactExportRunDownloadError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
        exactExportRunDownloadErrorCleared(state) {
            return {
                ...state,
                hasError: false,
            };
        },
    },
});

export default exactExportRunDownloadSlice.reducer;

const { exactExportRunDownloadLoading, exactExportRunDownloaded, exactExportRunDownloadError } =
    exactExportRunDownloadSlice.actions;
export const { exactExportRunDownloadErrorCleared: clearExactExportRunDownloadError } =
    exactExportRunDownloadSlice.actions;

type DownloadExactExportRunParams = {
    id: ExactExportRun['id'];
    fileName: string;
};

export const downloadExactExportRun =
    ({ id, fileName }: DownloadExactExportRunParams) =>
    async (dispatch: Dispatch) => {
        dispatch(exactExportRunDownloadLoading());
        try {
            const res = await getGatewayApiInstance().get(`/exact-export-runs/${id}/zip`, {
                responseType: 'blob',
            });
            // Create a temporary link to the file and click to download
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = getTemporaryInstantDownloadLink({ url, fileName, fileExtension: 'zip' });
            link.click();
            dispatch(exactExportRunDownloaded());
        } catch (err: unknown) {
            dispatch(exactExportRunDownloadError());
            handleAxiosErrors(err);
        }
    };

// Selectors
export const selectHasExactExportRunDownloadError = (state: State): boolean => state.hasError;
