import React, { useState } from 'react';

export const useCheckboxInput = (initialValue: boolean) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.checked);
    };

    return { value, handleChange };
};
