import React from 'react';
import { Error, Loader } from '../../../../../shared';
import { NewTransactionForm } from './NewTransactionForm';
import { useFetchTransactionTypes } from './useFetchTransactionTypes';

interface NewTransactionFormWithFetchProps {
    handleHideTransactionForm: () => void;
}

export const NewTransactionFormWithFetch = ({ handleHideTransactionForm }: NewTransactionFormWithFetchProps) => {
    const { isLoadingTransactionTypes, hasTransactionTypesError, manualTransactionsInfo } = useFetchTransactionTypes();

    if (isLoadingTransactionTypes) return <Loader />;
    if (hasTransactionTypesError)
        return <Error errorMsg='Could not fetch transaction types - please try again. If the error persists:' />;

    if (manualTransactionsInfo.length > 0)
        return (
            <NewTransactionForm
                manualTransactionsInfo={manualTransactionsInfo}
                handleHideTransactionForm={handleHideTransactionForm}
            />
        );
    return null;
};
