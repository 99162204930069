import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { notificationUpdated } from './notifications';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    invoiceRun: null as null | InvoiceRun,
    isLoading: false,
    isLoadingInvoiceRunCancellation: false,
    isLoadingInvoiceRunMarkAsTest: false,
    isLoadingInvoiceRunFinalisation: false,
    isLoadingInvoiceRunEmail: false,
    hasError: false,
    hasInvoiceRunCancellationError: false,
    hasInvoiceRunMarkAsTestError: false,
    hasInvoiceRunFinalisationError: false,
    hasInvoiceRunEmailError: false,
};

type State = typeof initialState;

const invoiceRunDetailsSlice = createSlice({
    name: 'invoiceRunDetails',
    initialState,
    reducers: {
        invoiceRunDetailsLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        invoiceRunCancellationLoading(state) {
            return {
                ...state,
                isLoadingInvoiceRunCancellation: true,
                hasInvoiceRunCancellationError: false,
            };
        },
        invoiceRunFinalisationLoading(state) {
            return {
                ...state,
                isLoadingInvoiceRunFinalisation: true,
                hasInvoiceRunFinalisationError: false,
            };
        },
        invoiceRunMarkAsTestLoading(state) {
            return {
                ...state,
                isLoadingInvoiceRunMarkAsTest: true,
                hasInvoiceRunMarkAsTestError: false,
            };
        },
        invoiceRunEmailLoading(state) {
            return {
                ...state,
                isLoadingInvoiceRunEmail: true,
                hasInvoiceRunEmailError: false,
            };
        },
        invoiceRunDetailsReceived(state, action: PayloadAction<InvoiceRun>) {
            return {
                ...state,
                invoiceRun: action.payload,
                isLoading: false,
                isLoadingInvoiceRunCancellation: false,
                isLoadingInvoiceRunFinalisation: false,
                isLoadingInvoiceRunEmail: false,
            };
        },
        invoiceRunDetailsError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
        cancelInvoiceRunError(state) {
            return {
                ...state,
                isLoadingInvoiceRunCancellation: false,
                hasInvoiceRunCancellationError: true,
            };
        },
        markAsTestInvoiceRunError(state) {
            return {
                ...state,
                isLoadingInvoiceRunMarkAsTest: false,
                hasInvoiceRunMarkAsTestError: true,
            };
        },
        finaliseInvoiceRunError(state) {
            return {
                ...state,
                isLoadingInvoiceRunFinalisation: false,
                hasInvoiceRunFinalisationError: true,
            };
        },
        emailInvoiceRunError(state) {
            return {
                ...state,
                isLoadingInvoiceRunEmail: false,
                hasInvoiceRunEmailError: true,
            };
        },
        invoiceRunActionErrorsCleared(state) {
            return {
                ...state,
                hasInvoiceRunCancellationError: false,
                hasInvoiceRunFinalisationError: false,
                hasInvoiceRunEmailError: false,
            };
        },
    },
});

export default invoiceRunDetailsSlice.reducer;

const {
    invoiceRunDetailsLoading,
    invoiceRunCancellationLoading,
    invoiceRunMarkAsTestLoading,
    invoiceRunFinalisationLoading,
    invoiceRunEmailLoading,
    invoiceRunDetailsReceived,
    invoiceRunDetailsError,
    cancelInvoiceRunError,
    markAsTestInvoiceRunError,
    finaliseInvoiceRunError,
    emailInvoiceRunError,
} = invoiceRunDetailsSlice.actions;

export const { invoiceRunActionErrorsCleared: clearInvoiceRunActionErrors } = invoiceRunDetailsSlice.actions;

export const getInvoiceRunDetails = (id: number) => async (dispatch: Dispatch) => {
    dispatch(invoiceRunDetailsLoading());
    try {
        const res = await getGatewayApiInstance().get(`/invoice-runs/${id}`);
        dispatch(invoiceRunDetailsReceived(res.data));
    } catch (err: unknown) {
        dispatch(invoiceRunDetailsError());
        handleAxiosErrors(err);
    }
};

export const cancelInvoiceRun = (id: number) => async (dispatch: Dispatch) => {
    dispatch(invoiceRunCancellationLoading());
    try {
        const res = await getGatewayApiInstance().put(`/invoice-runs/${id}/cancel`);
        dispatch(invoiceRunDetailsReceived(res.data));
        dispatch(notificationUpdated('Invoice run was successfully cancelled'));
    } catch (err: unknown) {
        dispatch(cancelInvoiceRunError());
        handleAxiosErrors(err);
    }
};

export const markAsTestInvoiceRun = (id: number) => async (dispatch: Dispatch) => {
    dispatch(invoiceRunMarkAsTestLoading());
    try {
        const res = await getGatewayApiInstance().put(`/invoice-runs/${id}/mark-as-test`);
        dispatch(invoiceRunDetailsReceived(res.data));
        dispatch(notificationUpdated('Invoice run was successfully marked as test'));
    } catch (err: unknown) {
        dispatch(markAsTestInvoiceRunError());
        handleAxiosErrors(err);
    }
};

export const finaliseInvoiceRun = (id: number) => async (dispatch: Dispatch) => {
    dispatch(invoiceRunFinalisationLoading());
    try {
        const res = await getGatewayApiInstance().put(`/invoice-runs/${id}/finalize`);
        dispatch(invoiceRunDetailsReceived(res.data));
        dispatch(notificationUpdated('Invoice run was successfully finalised'));
    } catch (err: unknown) {
        dispatch(finaliseInvoiceRunError());
        handleAxiosErrors(err);
    }
};

export const emailInvoiceRun = (id: number) => async (dispatch: Dispatch) => {
    dispatch(invoiceRunEmailLoading());
    try {
        const res = await getGatewayApiInstance().put(`/invoice-runs/${id}/email`);
        dispatch(invoiceRunDetailsReceived(res.data));
        dispatch(notificationUpdated('Invoice run was successfully emailed'));
    } catch (err: unknown) {
        dispatch(emailInvoiceRunError());
        handleAxiosErrors(err);
    }
};

// Selectors
export const selectInvoiceRun = (state: State): null | InvoiceRun => state.invoiceRun;
export const selectInvoiceRunId = (state: State): null | InvoiceRun['id'] => state.invoiceRun?.id || null;
export const selectIsInvoiceRunCancellable = (state: State): boolean => state.invoiceRun?.status === 'generating';
export const selectIsInvoiceRunGenerated = (state: State): boolean => Boolean(state.invoiceRun?.generated);
export const selectCanInvoiceRunBeMarkedAsTest = (state: State): boolean =>
    Boolean(state.invoiceRun?.generated) &&
    Boolean(!state.invoiceRun?.finalized) &&
    Boolean(!state.invoiceRun?.markedAsTest);
export const selectCanInvoiceRunBeFinalised = (state: State): boolean =>
    Boolean(state.invoiceRun?.generated) &&
    Boolean(!state.invoiceRun?.finalized) &&
    Boolean(!state.invoiceRun?.markedAsTest);
export const selectCanInvoiceRunBeEmailed = (state: State): boolean =>
    Boolean(state.invoiceRun?.finalized) &&
    state.invoiceRun?.status !== 'emailing' &&
    Boolean(!state.invoiceRun?.emailed);
// Loading
export const selectIsLoadingInvoiceRun = (state: State): boolean => state.isLoading;
// Errors
export const selectHasInvoiceRunError = (state: State): boolean => state.hasError;
export const selectHasCancelInvoiceRunError = (state: State): boolean => state.hasInvoiceRunCancellationError;
export const selectHasMarkAsTestInvoiceRunError = (state: State): boolean => state.hasInvoiceRunMarkAsTestError;
export const selectHasFinaliseInvoiceRunError = (state: State): boolean => state.hasInvoiceRunFinalisationError;
export const selectHasEmailInvoiceRunError = (state: State): boolean => state.hasInvoiceRunEmailError;
