import React from 'react';
import { DateInput, NumberInput, SelectInput, SearchInput } from '../../../shared/Form/Inputs';
import { useEditOrdersParams } from './useEditOrdersParams';
import styles from './editOrdersParams.module.scss';

export const EditOrdersParams = () => {
    const {
        balanceStateParam,
        minBalanceParam,
        maxBalanceParam,
        accountableParam,
        marketplaceParam,
        minDateParam,
        maxDateParam,
        searchParam,
        handleBalanceStateParamChange,
        handleMinBalanceParamChange,
        handleMaxBalanceParamChange,
        handleAccountableParamChange,
        handleMarketplaceParamChange,
        handleMinDateParamChange,
        handleMaxDateParamChange,
        handleSearchParamChange,
    } = useEditOrdersParams();

    return (
        <div className={styles.flexContainer}>
            <div className={styles.flexContainer__item}>
                <SelectInput
                    label='Balance state'
                    options={[
                        { value: '', text: 'All orders' },
                        { value: 'balanced', text: 'Balanced orders' },
                        { value: 'unbalanced', text: 'Unbalanced orders' },
                    ]}
                    value={balanceStateParam.value}
                    hasExtraLabel={false}
                    handleChange={handleBalanceStateParamChange}
                />
                <NumberInput
                    label='Min balance'
                    hasExtraLabel={false}
                    value={minBalanceParam.value}
                    handleChange={handleMinBalanceParamChange}
                />
                <NumberInput
                    label='Max balance'
                    hasExtraLabel={false}
                    value={maxBalanceParam.value}
                    handleChange={handleMaxBalanceParamChange}
                />

                <SelectInput
                    label='Accountable?'
                    options={[
                        { value: '', text: 'All orders' },
                        { value: 'true', text: 'Accountable orders' },
                        { value: 'false', text: 'Non-accountable orders' },
                    ]}
                    value={accountableParam.value}
                    hasExtraLabel={false}
                    handleChange={handleAccountableParamChange}
                />
            </div>
            <div className={styles.flexContainer__item}>
                <SelectInput
                    label='Marketplace'
                    options={[
                        { value: '', text: 'All orders' },
                        { value: 'nl', text: 'NL' },
                        { value: 'de', text: 'DE' },
                    ]}
                    value={marketplaceParam.value}
                    hasExtraLabel={false}
                    handleChange={handleMarketplaceParamChange}
                />

                <DateInput
                    label='From'
                    value={minDateParam.value}
                    hasExtraLabel={false}
                    handleChange={handleMinDateParamChange}
                />
                <DateInput
                    label='Until'
                    value={maxDateParam.value}
                    hasExtraLabel={false}
                    handleChange={handleMaxDateParamChange}
                />
                <SearchInput value={searchParam.value} handleChange={handleSearchParamChange} />
            </div>
            {/*  Re-enable searching by categories/ tags (this property does not currently exist on the order object.) */}
            {/* <Form.Select
                    style={{ width: 'auto' }}
                    name='tag'
                    onChange={handleParamChange}
                    aria-label='tag selector'
                >
                    <option value=''>All categories</option>
                    <option value='fin'>FIN</option>
                    <option value='cs'>CS</option>
                    <option value='tech'>TECH</option>
                </Form.Select> */}
        </div>
    );
};
