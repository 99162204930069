import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

interface InfoAlertProps {
    title: string;
    children?: React.ReactNode;
}

export const InfoAlert = ({ title, children = null }: InfoAlertProps) => {
    return (
        <Alert severity='info'>
            <AlertTitle>{title}</AlertTitle>
            {children}
        </Alert>
    );
};
