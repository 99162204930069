import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

interface StartNewRunButtonProps {
    isLoading?: boolean;
    isInProgress?: boolean;
    isDisabled?: boolean;
    handleClick: () => void;
}
export const StartNewRunButton = ({
    isLoading = false,
    isInProgress = false,
    isDisabled = false,
    handleClick,
}: StartNewRunButtonProps) => {
    let label = 'Start New Run';
    if (isLoading) {
        label = 'New Run is starting...';
    } else if (isInProgress) {
        label = 'New Run in progress';
    }

    return (
        <LoadingButton
            type='button'
            variant='contained'
            disabled={isDisabled}
            loading={isLoading || isInProgress}
            loadingPosition='end'
            endIcon={<RestartAltIcon />}
            onClick={handleClick}
            fullWidth
        >
            {label}
        </LoadingButton>
    );
};
