import React from 'react';
import { Error, Loader, VerticalTable } from '../shared';
import { ExactExportRunDetailsActionMenu } from './components';
import { useFetchExactExportRunDetails } from './useFetchExactExportRunDetails';
import { useHandleExactExportRunActionErrors } from './useHandleExactExportRunActionErrors';
import { toYearMonthDayTime } from '../../utils/dates';
import styles from './exactExportRunDetails.module.scss';

export const ExactExportRunDetails = () => {
    const { isLoading, hasError, exactExportRun } = useFetchExactExportRunDetails();
    const {
        hasCancelRunError,
        hasExactExportRunDownloadError,
        hasMarkedAsTestExactExportRunError,
        hasFinaliseExactExportRunError
    } = useHandleExactExportRunActionErrors();

    const tableContent1 = [
        {
            heading: 'ID',
            data: exactExportRun?.id || '',
        },
        { heading: 'Created', data: exactExportRun?.created ? toYearMonthDayTime(exactExportRun.created) : '' },

        {
            heading: 'Month',
            data: exactExportRun?.month || '',
        },
        {
            heading: 'Year',
            data: exactExportRun?.year || '',
        },
        {
            heading: 'Cut-off date',
            data: exactExportRun?.cutoffDateTime ? toYearMonthDayTime(exactExportRun.cutoffDateTime) : '',
        },

        { heading: 'Max order number', data: exactExportRun?.maxOrderNumber || '' },
    ];

    const tableContent2 = [
        { heading: 'Status', data: exactExportRun?.status || '' },
        {
            heading: 'Generated',
            data: exactExportRun?.generated ? toYearMonthDayTime(exactExportRun.generated) : '',
        },
        { heading: 'Cancelled', data: exactExportRun?.cancelled ? toYearMonthDayTime(exactExportRun.cancelled) : '' },
        { heading: 'Finalised', data: exactExportRun?.finalized ? toYearMonthDayTime(exactExportRun.finalized) : '' },
        { heading: 'Marked as test', data: exactExportRun?.markedAsTest ? toYearMonthDayTime(exactExportRun.markedAsTest) : '' },
        { heading: 'Progress', data: `${exactExportRun?.generationProgress || ''}%` },
        {
            heading: 'Marketplaces',
            data: exactExportRun?.marketplaceCodes
                ? exactExportRun.marketplaceCodes.map((code) => code.toUpperCase()).join(', ')
                : '',
        },
    ];

    if (hasError) return <Error />;
    if (isLoading) return <Loader />;
    return (
        <>
            <div className={styles.menuWrapper}>
                <ExactExportRunDetailsActionMenu />
            </div>
            {hasCancelRunError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not cancel the run.' />
                </div>
            )}
            {hasMarkedAsTestExactExportRunError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not mark the run as test.' />
                </div>
            )}
            {hasExactExportRunDownloadError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not download the run.' />
                </div>
            )}
            {hasFinaliseExactExportRunError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not finalise the run.' />
                </div>
            )}
            <div className={styles.tableWrapper}>
                <div className={styles.tableWrapper__table}>
                    <VerticalTable tableContent={tableContent1} />
                </div>
                <div className={styles.tableWrapper__table}>
                    <VerticalTable tableContent={tableContent2} />
                </div>
            </div>
        </>
    );
};
