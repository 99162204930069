import React from 'react';
import ButtonComponent from '@mui/material/Button';
import { MuiEvent } from '@mui/x-data-grid';

interface ButtonProps {
    label: string;
    icon: React.ReactNode;
    handleClick: (e: MuiEvent<React.MouseEvent<HTMLElement>>) => void;
}

export const Button = ({ label, icon, handleClick }: ButtonProps) => {
    return (
        <ButtonComponent onClick={handleClick} type='button' variant='contained' endIcon={icon}>
            {label}
        </ButtonComponent>
    );
};
