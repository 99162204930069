import { useNavigate } from 'react-router-dom';

export const useExactExportRunsActionMenu = () => {
    const navigate = useNavigate();

    const handleStartNewExactExportRun = () => {
        navigate('/exact-export-runs/new');
    };

    return { handleStartNewExactExportRun };
};
