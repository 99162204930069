export const indexOf = <T>(arr: T[], cb: (arg0: T, arg1: number, arg2: T[]) => boolean): number => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr.length; i++) {
        if (cb(arr[i], i, arr)) return i;
    }

    return -1;
};

export const asyncMap = async <T, C>(arr: T[], cb: (arg0: T, arg1: number, arg2: T[]) => Promise<C>): Promise<C[]> => {
    const output: C[] = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const res = await cb(arr[i], i, arr);
        output.push(res);
    }

    return output;
};
