import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/redux/typed-hooks';
import { getInvoiceRunDetails } from '@app/redux/reducers/invoiceRunDetails';
import { selectInvoiceRun, selectIsLoadingInvoiceRun, selectHasInvoiceRunError } from '@app/redux/reducers';
import { toNumber } from '@app/utils/formatting';

export const useFetchInvoiceRunDetails = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const isLoading = useAppSelector(selectIsLoadingInvoiceRun);
    const hasError = useAppSelector(selectHasInvoiceRunError);
    const invoiceRun = useAppSelector(selectInvoiceRun);

    useEffect(() => {
        if (id) {
            dispatch(getInvoiceRunDetails(toNumber(id)));
        }
    }, [dispatch, id]);

    return { isLoading, hasError, invoiceRun };
};
