import React from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

interface SaveButtonProps {
    withLoading?: boolean;
    isLoading?: boolean;
    handleClick: () => void;
}

export const SaveButton = ({ withLoading = false, isLoading = false, handleClick }: SaveButtonProps) => {
    if (withLoading)
        return (
            <LoadingButton
                type='button'
                variant='contained'
                loading={isLoading}
                loadingPosition='end'
                endIcon={<SaveIcon />}
                onClick={handleClick}
            >
                {isLoading ? 'Saving...' : 'Save'}
            </LoadingButton>
        );
    return (
        <Button onClick={handleClick} variant='contained' endIcon={<SaveIcon />}>
            Save
        </Button>
    );
};
