import React from 'react';
import { EditOrdersParams, OrderList } from './subcomponents';
import styles from './orders.module.scss';

export const Orders = () => (
    <>
        <div className={styles.ordersParamsWrapper}>
            <EditOrdersParams />
        </div>
        <OrderList />
    </>
);
