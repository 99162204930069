import React from 'react';
import styles from './link.module.scss';

interface LinkProps {
    href: string;
    text: string;
}

export const Link = ({ href, text }: LinkProps) => (
    <a className={styles.link} href={href} target='_blank' rel='noopener noreferrer'>
        {text}
    </a>
);
