import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import { selectInvoiceRunsParams } from '../../../../redux/reducers';
import { updateInvoiceRunsParams } from '../../../../redux/reducers/invoiceRuns';
import { useDateInput } from '../../../shared/Form/Inputs';
import { isDateValid, toYearMonthDay } from '../../../../utils/dates';

export const useEditInvoiceRunsParams = () => {
    const dispatch = useAppDispatch();
    const invoiceRunsParams = useAppSelector(selectInvoiceRunsParams);
    const dateFrom = useDateInput(invoiceRunsParams.dateFrom);
    const dateUntil = useDateInput(invoiceRunsParams.dateUntil);

    const handleDateFromParamChange = (date: Date | null) => {
        dateFrom.handleChange(date);
        // Dispatch when user finished typing in the date (with 4-digit year format) or deleting the date
        if ((date && isDateValid(date) && date.getFullYear() > 1000) || !date) {
            dispatch(updateInvoiceRunsParams({ dateFrom: date ? toYearMonthDay(date) : '' }));
        }
    };

    const handleDateUntilParamChange = (date: Date | null) => {
        dateUntil.handleChange(date);
        // Dispatch when user finished typing in the date (with 4-digit year format) or deleting the date
        if ((date && isDateValid(date) && date.getFullYear() > 1000) || !date) {
            dispatch(updateInvoiceRunsParams({ dateUntil: date ? toYearMonthDay(date) : '' }));
        }
    };

    return {
        dateFrom,
        dateUntil,
        handleDateFromParamChange,
        handleDateUntilParamChange,
    };
};
