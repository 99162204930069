import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import {
    selectIsExactExportRunGenerated,
    selectExactExportRunMaxOrderNumber,
    selectExactExportRunCutoffDateTime,
    selectCanExactExportRunBeFinalised,
    selectCanExactExportRunBeMarkedAsTest,
    selectIsExactExportRunCancellable,
} from '../../../../redux/reducers';
import { downloadExactExportRun } from '../../../../redux/reducers/exactExportRunDownload';
import {
    markAsTestExactExportRun,
    finaliseExactExportRun,
    cancelExactExportRun
} from '../../../../redux/reducers/exactExportRunDetails';
import { getExactExportRunFileName } from '../../../../utils/exactExports';
import { toYearMonthDay } from '../../../../utils/dates';
import { toNumber } from '../../../../utils/formatting';

export const useExactExportRunDetailsActionMenu = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const isGenerated = useAppSelector(selectIsExactExportRunGenerated);
    const maxOrderNumber = useAppSelector(selectExactExportRunMaxOrderNumber);
    const cutoffDateTime = useAppSelector(selectExactExportRunCutoffDateTime);
    const canBeFinalised = useAppSelector(selectCanExactExportRunBeFinalised);
    const canBeMarkedAsTest = useAppSelector(selectCanExactExportRunBeMarkedAsTest);
    const isCancellable = useAppSelector(selectIsExactExportRunCancellable);

    const handleCancelRun = () => {
        if (id) {
            dispatch(cancelExactExportRun(toNumber(id)));
        }
    };

    const handleDownloadRun = () => {
        if (id && maxOrderNumber && cutoffDateTime) {
            const fileName = getExactExportRunFileName({
                id: toNumber(id),
                maxOrderNumber,
                cutoffDateTime: new Date(cutoffDateTime),
                toYearMonthDay,
            });

            dispatch(downloadExactExportRun({ id: toNumber(id), fileName }));
        }
    };

    const handleFinaliseRun = () => {
        if (id) {
            dispatch(finaliseExactExportRun(toNumber(id)));
        }
    };

    const handleMarkAsTestRun = () => {
        if (id) {
            dispatch(markAsTestExactExportRun(toNumber(id)));
        }
    };

    return {
        isGenerated,
        isCancellable,
        canBeFinalised,
        canBeMarkedAsTest,
        handleDownloadRun,
        handleFinaliseRun,
        handleMarkAsTestRun,
        handleCancelRun
    };
};
