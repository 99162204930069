import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';
import { getTemporaryInstantDownloadLink } from '../../utils/download';

const initialState = {
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const invoiceDownloadSlice = createSlice({
    name: 'invoiceDownload',
    initialState,
    reducers: {
        invoiceDownloadLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        invoiceDownloaded(state) {
            return {
                ...state,
                isLoading: false,
            };
        },
        invoiceDownloadError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
        invoiceDownloadErrorCleared(state) {
            return {
                ...state,
                hasError: false,
            };
        },
    },
});

export default invoiceDownloadSlice.reducer;

const { invoiceDownloadLoading, invoiceDownloaded, invoiceDownloadError } = invoiceDownloadSlice.actions;
export const { invoiceDownloadErrorCleared: clearInvoiceDownloadError } = invoiceDownloadSlice.actions;

type DownloadInvoiceParams = {
    id: Invoice['id'];
    fileName: string;
};

export const downloadInvoicePDF =
    ({ id, fileName }: DownloadInvoiceParams) =>
    async (dispatch: Dispatch) => {
        dispatch(invoiceDownloadLoading());
        try {
            const res = await getGatewayApiInstance().get(`/invoices/${id}/pdf`, { responseType: 'blob' });
            // Create a temporary link to the file and click to download
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = getTemporaryInstantDownloadLink({ url, fileName, fileExtension: 'pdf' });
            link.click();
            dispatch(invoiceDownloaded());
        } catch (err: unknown) {
            dispatch(invoiceDownloadError());
            handleAxiosErrors(err);
        }
    };

// Selectors
export const selectHasInvoiceDownloadError = (state: State): boolean => state.hasError;
