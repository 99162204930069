import React from 'react';

/**
    Defining a structure for our routes doesn't tie us to one routing library.
    It also allows us to build custom Breadcrumbs, but note:
    For breadcrumbs to work correctly, if two routes match (e.g. '/orders/create-order' matches '/orders/:orderId'), 
    the route without parameters has to be defined first.
 */

interface Components {
    [index: string]: React.FunctionComponent;
}

type Route = {
    path: string;
    name: string;
    Component: React.FunctionComponent;
    permissions: 'everyone' | 'viewer' | 'manager' | 'admin';
};

export const getRoutes = (
    {
        orderNo,
        invoiceRunNo,
        exactExportRunNo,
    }: { orderNo: number | null; invoiceRunNo: number | null; exactExportRunNo: number | null },
    {
        FourOhFourPage,
        InvoicesPage,
        InvoiceRunsPage,
        InvoiceRunDetailsPage,
        LoginPage,
        NewInvoiceRunPage,
        OrderDetailsPage,
        OrdersPage,
        ExactExportsPage,
        ExactExportRunsPage,
        NewExactExportRunPage,
        ExactExportRunDetailsPage,
        TransactionsPage,
    }: Components = {}
): Route[] => [
    {
        path: '/login',
        name: 'Login',
        Component: LoginPage,
        permissions: 'everyone',
    },
    {
        path: '*',
        name: '404',
        Component: FourOhFourPage,
        permissions: 'everyone',
    },
    {
        path: '/',
        name: 'Home',
        Component: OrdersPage,
        permissions: 'viewer',
    },
    {
        path: '/orders',
        name: 'Orders',
        Component: OrdersPage,
        permissions: 'viewer',
    },
    {
        path: '/orders/:orderId',
        name: orderNo ? `Order number ${orderNo}` : 'Order',
        Component: OrderDetailsPage,
        permissions: 'viewer',
    },
    {
        path: '/invoices',
        name: 'Invoices',
        Component: InvoicesPage,
        permissions: 'viewer',
    },
    {
        path: '/invoice-runs',
        name: 'Invoice Runs',
        Component: InvoiceRunsPage,
        permissions: 'viewer',
    },
    {
        path: '/invoice-runs/new',
        name: 'New Invoice Run',
        Component: NewInvoiceRunPage,
        permissions: 'manager',
    },
    {
        path: '/invoice-runs/:id',
        name: invoiceRunNo ? `Invoice Run number ${invoiceRunNo}` : 'Invoice Run',
        Component: InvoiceRunDetailsPage,
        permissions: 'viewer',
    },
    {
        path: '/exact-exports',
        name: 'Exact Exports',
        Component: ExactExportsPage,
        permissions: 'viewer',
    },
    {
        path: '/exact-export-runs',
        name: 'Exact Export Runs',
        Component: ExactExportRunsPage,
        permissions: 'viewer',
    },
    {
        path: '/exact-export-runs/new',
        name: 'New Exact Export Run',
        Component: NewExactExportRunPage,
        permissions: 'manager',
    },
    {
        path: '/exact-export-runs/:id',
        name: exactExportRunNo ? `Exact Export Run number ${exactExportRunNo}` : 'Exact Export Run',
        Component: ExactExportRunDetailsPage,
        permissions: 'viewer',
    },
    {
        path: '/transactions',
        name: 'Transactions Page',
        Component: TransactionsPage,
        permissions: 'manager',
    },
];
