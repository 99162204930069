import React from 'react';
import { useAppSelector } from '../../../../redux/typed-hooks';
import { selectOrderDetailsBalanceData } from '../../../../redux/reducers';
import { VerticalTable } from '../../../shared';
import { formatBool, formatPrice } from '../../../../utils/formatting';

export const BalanceInfoBlock = () => {
    const balanceData = useAppSelector(selectOrderDetailsBalanceData);

    if (!balanceData) return null;

    const {
        balanceAmount,
        jettiReturnValue,
        jettiReturnStatus,
        willComeIntoBalance,
        unmatchedRefund,
        unmatchedCancellation,
    } = balanceData;

    const tableContent = [
        {
            heading: 'Balance',
            data: formatPrice(balanceAmount || 0),
            rowAlert: balanceAmount === 0 ? ('success' as Alert) : ('warn' as Alert),
        },
        {
            heading: 'Jetti return value',
            data: jettiReturnValue || '',
        },
        {
            heading: 'Jetti return states',
            data: jettiReturnStatus || '',
        },
        {
            heading: 'Balanced after return ?',
            data: willComeIntoBalance === null ? '' : formatBool(willComeIntoBalance),
        },
        { heading: 'Unmatched refund ?', data: unmatchedRefund === null ? '' : formatBool(unmatchedRefund) },
        {
            heading: 'Unmatched cancellation ?',
            data: unmatchedCancellation === null ? '' : formatBool(unmatchedCancellation),
        },
    ];

    return <VerticalTable title='Balance info' tableContent={tableContent} />;
};
