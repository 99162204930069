import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { notificationUpdated } from './notifications';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    exactExportRun: null as null | ExactExportRun,
    isLoading: false,
    isLoadingExactExportRunCancellation: false,
    isLoadingMarkAsTestExactExportRun: false,
    isLoadingFinaliseExactExportRun: false,
    hasError: false,
    hasExactExportRunCancellationError: false,
    hasMarkedAsTestExactExportRunError: false,
    hasFinaliseExactExportRunError: false,
};

type State = typeof initialState;

const exactExportRunDetailsSlice = createSlice({
    name: 'exactExportRunDetails',
    initialState,
    reducers: {
        exactExportRunDetailsLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        exactExportRunCancellationLoading(state) {
            return {
                ...state,
                isLoadingExactExportRunCancellation: true,
                hasExactExportRunCancellationError: false,
            };
        },
        markAsTestExactExportRunLoading(state) {
            return {
                ...state,
                isLoadingMarkAsTestExactExportRun: true,
                hasMarkedAsTestExactExportRunError: false,
            };
        },
        finaliseExactExportRunLoading(state) {
            return {
                ...state,
                isLoadingFinaliseExactExportRun: true,
                hasFinaliseExactExportRunError: false,
            };
        },
        exactExportRunDetailsReceived(state, action: PayloadAction<ExactExportRun>) {
            return {
                ...state,
                exactExportRun: action.payload,
                isLoading: false,
                isLoadingExactExportRunCancellation: false,
                isLoadingMarkAsTestExactExportRun: false,
                isLoadingFinaliseExactExportRun: false,
            };
        },
        exactExportRunDetailsError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
        cancelExactExportRunError(state) {
            return {
                ...state,
                isLoadingExactExportRunCancellation: false,
                hasExactExportRunCancellationError: true,
            };
        },
        markAsTestExactExportRunError(state) {
            return {
                ...state,
                isLoadingMarkAsTestExactExportRun: false,
                hasMarkedAsTestExactExportRunError: true,
            };
        },
        finaliseExactExportRunError(state) {
            return {
                ...state,
                isLoadingFinaliseExactExportRun: false,
                hasFinaliseExactExportRunError: true,
            };
        },
        exactExportRunActionErrorsCleared(state) {
            return {
                ...state,
                hasExactExportRunCancellationError: false,
                hasMarkedAsTestExactExportRunError: false,
                hasFinaliseExactExportRunError: false,
            };
        },
    },
});

export default exactExportRunDetailsSlice.reducer;

const {
    exactExportRunDetailsLoading,
    exactExportRunCancellationLoading,
    markAsTestExactExportRunLoading,
    finaliseExactExportRunLoading,
    exactExportRunDetailsReceived,
    exactExportRunDetailsError,
    markAsTestExactExportRunError,
    finaliseExactExportRunError,
    cancelExactExportRunError,
} = exactExportRunDetailsSlice.actions;

export const { exactExportRunActionErrorsCleared: clearExactExportRunActionErrors } =
    exactExportRunDetailsSlice.actions;

export const getExactExportRunDetails = (id: number) => async (dispatch: Dispatch) => {
    dispatch(exactExportRunDetailsLoading());
    try {
        const res = await getGatewayApiInstance().get(`/exact-export-runs/${id}`);
        dispatch(exactExportRunDetailsReceived(res.data));
    } catch (err: unknown) {
        dispatch(exactExportRunDetailsError());
        handleAxiosErrors(err);
    }
};

export const cancelExactExportRun = (id: number) => async (dispatch: Dispatch) => {
    dispatch(exactExportRunCancellationLoading());
    try {
        const res = await getGatewayApiInstance().put(`/exact-export-runs/${id}/cancel`);
        dispatch(exactExportRunDetailsReceived(res.data));
        dispatch(notificationUpdated('Exact export run was successfully cancelled'));
    } catch (err: unknown) {
        dispatch(cancelExactExportRunError());
        handleAxiosErrors(err);
    }
};

export const markAsTestExactExportRun = (id: number) => async (dispatch: Dispatch) => {
    dispatch(markAsTestExactExportRunLoading());
    try {
        const res = await getGatewayApiInstance().put(`/exact-export-runs/${id}/mark-as-test`);
        dispatch(exactExportRunDetailsReceived(res.data));
        dispatch(notificationUpdated('Exact Export run was successfully marked as testq'));
    } catch (err: unknown) {
        dispatch(markAsTestExactExportRunError());
        handleAxiosErrors(err);
    }
};

export const finaliseExactExportRun = (id: number) => async (dispatch: Dispatch) => {
    dispatch(finaliseExactExportRunLoading());
    try {
        const res = await getGatewayApiInstance().put(`/exact-export-runs/${id}/finalize`);
        dispatch(exactExportRunDetailsReceived(res.data));
        dispatch(notificationUpdated('Exact Export run was successfully finalised'));
    } catch (err: unknown) {
        dispatch(finaliseExactExportRunError());
        handleAxiosErrors(err);
    }
};

// Selectors
export const selectExactExportRun = (state: State): null | ExactExportRun => state.exactExportRun;
export const selectExactExportRunId = (state: State): null | ExactExportRun['id'] => state.exactExportRun?.id || null;
export const selectExactExportRunMaxOrderNumber = (state: State): null | ExactExportRun['maxOrderNumber'] =>
    state.exactExportRun?.maxOrderNumber || null;
export const selectExactExportRunCutoffDateTime = (state: State): null | ExactExportRun['cutoffDateTime'] =>
    state.exactExportRun?.cutoffDateTime || null;
export const selectIsExactExportRunCancellable = (state: State): boolean => state.exactExportRun?.status === 'generating';
export const selectIsExactExportRunGenerated = (state: State): boolean => Boolean(state.exactExportRun?.generated);
export const selectCanExactExportRunBeFinalised = (state: State): boolean =>
    Boolean(state.exactExportRun?.generated) &&
    Boolean(!state.exactExportRun?.finalized) &&
    Boolean(!state.exactExportRun?.markedAsTest);
export const selectCanExactExportRunBeMarkedAsTest = (state: State): boolean =>
    Boolean(state.exactExportRun?.generated) &&
    Boolean(!state.exactExportRun?.markedAsTest) &&
    Boolean(!state.exactExportRun?.finalized);
// Loading
export const selectIsLoadingExactExportRun = (state: State): boolean => state.isLoading;
// Errors
export const selectHasExactExportRunError = (state: State): boolean => state.hasError;
export const selectHasFinaliseExactExportRunError = (state: State): boolean => state.hasFinaliseExactExportRunError;
export const selectHasMarkAsTestExactExportRunError = (state: State): boolean => state.hasMarkedAsTestExactExportRunError;
export const selectHasCancelExactExportRunError = (state: State): boolean => state.hasExactExportRunCancellationError;
