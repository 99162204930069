import React from 'react';
import IconButton from '@mui/lab/LoadingButton/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';

interface DownloadButtonProps {
    isDisabled?: boolean;
    handleClick: () => void;
}

export const DownloadButton = ({ handleClick, isDisabled = false }: DownloadButtonProps) => {
    return (
        <IconButton type='button' onClick={handleClick} disabled={isDisabled}>
            <DownloadIcon />
        </IconButton>
    );
};
