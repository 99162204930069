import React, { useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import { selectInvoicesParams } from '../../../../redux/reducers';
import { updateInvoicesParams } from '../../../../redux/reducers/invoices';
import { useDateInput, useSearchInput, useSelectInput } from '../../../shared/Form/Inputs';
import { useDebounce } from '../../../../hooks';
import { isDateValid, toYearMonthDay } from '../../../../utils/dates';

export const useEditInvoiceParams = () => {
    const dispatch = useAppDispatch();
    const invoicesParams = useAppSelector(selectInvoicesParams);
    const dateFrom = useDateInput(invoicesParams.dateFrom);
    const dateUntil = useDateInput(invoicesParams.dateUntil);
    const marketplace = useSelectInput(invoicesParams.marketplace);
    const search = useSearchInput(invoicesParams.search);
    const query = search.value;
    const debouncedSearchTerm = useDebounce(query, 300);

    const handleDateFromParamChange = (date: Date | null) => {
        dateFrom.handleChange(date);
        // Dispatch when user finished typing in the date (with 4-digit year format) or deleting the date
        if ((date && isDateValid(date) && date.getFullYear() > 1000) || !date) {
            dispatch(updateInvoicesParams({ dateFrom: date ? toYearMonthDay(date) : '' }));
        }
    };

    const handleDateUntilParamChange = (date: Date | null) => {
        dateUntil.handleChange(date);
        // Dispatch when user finished typing in the date (with 4-digit year format) or deleting the date
        if ((date && isDateValid(date) && date.getFullYear() > 1000) || !date) {
            dispatch(updateInvoicesParams({ dateUntil: date ? toYearMonthDay(date) : '' }));
        }
    };

    const handleMarketplaceChange = (e: SelectChangeEvent<string>) => {
        marketplace.handleChange(e);
        dispatch(updateInvoicesParams({ marketplace: e.target.value as InvoicesParams['marketplace'] }));
    };

    const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        search.handleChange(e);
    };

    useEffect(() => {
        dispatch(updateInvoicesParams({ search: debouncedSearchTerm }));
    }, [debouncedSearchTerm, dispatch]);

    return {
        dateFrom,
        dateUntil,
        marketplace,
        query,
        handleDateFromParamChange,
        handleDateUntilParamChange,
        handleMarketplaceChange,
        handleQueryChange,
    };
};
