import React from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { ActionMenu } from '../../../shared';
import { useInvoiceRunsActionMenu } from './useInvoiceRunsActionMenu';

export const InvoiceRunsActionMenu = () => {
    const { handleStartNewInvoiceRun } = useInvoiceRunsActionMenu();

    const menuItems = [
        {
            text: 'New Invoice Run',
            icon: <RestartAltIcon />,
            handler: handleStartNewInvoiceRun,
        },
    ];

    return <ActionMenu menuItems={menuItems} />;
};
