import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../../firebase';
import { redirectLinkUpdated } from './redirect';
import { getFirebaseUserFromAccessToken } from '../../utils/authentication';
import { handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    token: localStorage.getItem('authToken'),
    user: getFirebaseUserFromAccessToken(localStorage.getItem('authToken')),
    isUserLoading: false,
    hasLoginError: false,
};

type State = typeof initialState;

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        userLoading(state) {
            return {
                ...state,
                isUserLoading: true,
                hasLoginError: false,
            };
        },
        userLoggedIn(state, action: PayloadAction<string>) {
            localStorage.setItem('authToken', action.payload);
            return {
                ...state,
                token: action.payload,
                user: getFirebaseUserFromAccessToken(action.payload),
                isUserLoading: false,
            };
        },
        userLoginError(state) {
            return {
                ...state,
                hasLoginError: true,
            };
        },
        userLoginErrorCleared(state) {
            return {
                ...state,
                hasLoginError: false,
            };
        },
        userLoggedOut(state) {
            localStorage.removeItem('authToken');
            return {
                ...state,
                token: null,
                user: null,
            };
        },
    },
});

export default authSlice.reducer;

const { userLoading, userLoggedIn, userLoggedOut, userLoginError } = authSlice.actions;
export const { userLoginErrorCleared: clearUserLoginError } = authSlice.actions;

// Sign in with Firebase + Google
export const login = () => async (dispatch: Dispatch) => {
    dispatch(userLoading());
    provider.setCustomParameters({ prompt: 'select_account' });
    try {
        const result = await signInWithPopup(auth, provider);
        const token = await result.user.getIdToken();
        dispatch(userLoggedIn(token));
        dispatch(redirectLinkUpdated(`/orders`));
    } catch (error: unknown) {
        dispatch(userLoginError());
        // Log errors to the console
        handleAxiosErrors(error);
    }
};

export const logout = () => async (dispatch: Dispatch) => {
    dispatch(userLoggedOut());
    dispatch(redirectLinkUpdated('/login'));
};

// Selectors
export const selectUserName = (state: State): string => state.user?.name || '';
export const selectUserPicture = (state: State): string => state.user?.picture || '';
export const selectUserIsAuthenticated = (state: State): boolean => Boolean(state.user);
export const selectUserHasNoRights = (state: State): boolean =>
    (state.user !== null && state.user?.roles === undefined) ||
    (state.user !== null && state.user?.roles?.finance.viewer === false);
export const selectUserHasViewerRights = (state: State): boolean => state.user?.roles?.finance.viewer || false;
export const selectUserHasManagerRights = (state: State): boolean => state.user?.roles?.finance.manager || false;
// Errors
export const selectHasLoginError = (state: State): boolean => state.hasLoginError;
