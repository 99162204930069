import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { getExactExportRunDetails } from '../../redux/reducers/exactExportRunDetails';
import {
    selectExactExportRun,
    selectIsLoadingExactExportRun,
    selectHasExactExportRunError,
} from '../../redux/reducers';
import { toNumber } from '../../utils/formatting';

export const useFetchExactExportRunDetails = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const isLoading = useAppSelector(selectIsLoadingExactExportRun);
    const hasError = useAppSelector(selectHasExactExportRunError);
    const exactExportRun = useAppSelector(selectExactExportRun);

    useEffect(() => {
        if (id) {
            dispatch(getExactExportRunDetails(toNumber(id)));
        }
    }, [dispatch, id]);

    return { isLoading, hasError, exactExportRun };
};
