export const getRemainingTempTransactions = ({
    transactions,
    idsToDelete,
}: {
    transactions: TemporaryTransaction[];
    idsToDelete: TemporaryTransaction['id'][];
}) => transactions.filter((transaction) => !idsToDelete.includes(transaction.id));

export const getDebitTotal = (transactions: (Transaction | TemporaryTransaction)[]) =>
    transactions
        .filter((transaction) => transaction.balanceType === 'debit' && transaction.isAccountable) // filter debit transactions
        .map((transaction) => transaction.amount) // map to array of debit values
        .reduce((acc, curr) => acc + curr, 0); // add debit values

export const getCreditTotal = (transactions: (Transaction | TemporaryTransaction)[]) =>
    transactions
        .filter((transaction) => transaction.balanceType === 'credit' && transaction.isAccountable) // filter credit transactions
        .map((transaction) => transaction.amount) // map to array of credit values
        .reduce((acc, curr) => acc + curr, 0); // add credit values

export const getUniqueSkusFromSaleTransactions = (transactions: Transaction[]): string[] =>
    transactions
        .filter((transaction) => transaction.type === 'sale')
        .map((transaction) => transaction.sku)
        // (string | null)[] => string[]
        .flatMap((f) => (f ? [f] : []))
        .filter((sku, i, self) => self.indexOf(sku) === i);

export const getProductNameBasedOnSku = ({
    transactions,
    sku,
}: {
    transactions: Transaction[];
    sku: Transaction['sku'];
}): Transaction['productName'] => {
    const transactionsBasedOnSku = transactions.filter((transaction) => transaction.sku === sku);
    if (transactionsBasedOnSku.length > 0) return transactionsBasedOnSku[0].productName;
    return '';
};

export const getQuantityBasedOnSku = ({
    transactions,
    sku,
}: {
    transactions: Transaction[];
    sku: Transaction['sku'];
}): Transaction['quantity'] => {
    const transactionsBasedOnSku = transactions.filter((transaction) => transaction.sku === sku);
    if (transactionsBasedOnSku.length > 0) return transactionsBasedOnSku[0].quantity;
    return 0;
};

export const getPriceBasedOnSku = ({
    transactions,
    sku,
}: {
    transactions: Transaction[];
    sku: Transaction['sku'];
}): Transaction['price'] => {
    const transactionsBasedOnSku = transactions.filter((transaction) => transaction.sku === sku);
    if (transactionsBasedOnSku.length > 0) return transactionsBasedOnSku[0].price;
    return 0;
};

export const getSupplierNumberBasedOnSku = ({
    transactions,
    sku,
}: {
    transactions: Transaction[];
    sku: Transaction['sku'];
}): Transaction['supplierNumber'] => {
    const transactionsBasedOnSku = transactions.filter((transaction) => transaction.sku === sku);
    if (transactionsBasedOnSku.length > 0) return transactionsBasedOnSku[0].supplierNumber;
    return 0;
};

export const getPaymentSourceBasedOnPaymentTransaction = (
    transactions: Transaction[]
): Transaction['paymentSource'] => {
    const paymentTransactions = transactions.filter((transaction) => transaction.type === 'payment');
    if (paymentTransactions.length > 0) return paymentTransactions[0].paymentSource;
    return '';
};
