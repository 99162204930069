import React, { useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import { updateOrdersParams } from '../../../../redux/reducers/orders';
import { selectOrdersParams } from '../../../../redux/reducers';
import { useDateInput, useNumberInput, useSelectInput, useSearchInput } from '../../../shared/Form/Inputs';
import { useDebounce } from '../../../../hooks/useDebounce';
import { isDateValid, toYearMonthDayTime } from '../../../../utils/dates';
import { toNumber } from '../../../../utils/formatting';

export const useEditOrdersParams = () => {
    const dispatch = useAppDispatch();
    const ordersParams = useAppSelector(selectOrdersParams);
    const balanceStateParam = useSelectInput(ordersParams.balanceState);
    const minBalanceParam = useNumberInput(ordersParams['balance[gte]'] || null);
    const maxBalanceParam = useNumberInput(ordersParams['balance[lte]'] || null);
    const accountableParam = useSelectInput(ordersParams.accountable);
    const marketplaceParam = useSelectInput(ordersParams.marketplace);
    const minDateParam = useDateInput(ordersParams['createdAt[gte]']);
    const maxDateParam = useDateInput(ordersParams['createdAt[lte]']);
    const searchParam = useSearchInput(ordersParams.search);

    const query = searchParam.value;
    const debouncedSearchTerm = useDebounce(query, 300);

    const handleBalanceStateParamChange = (e: SelectChangeEvent<string>) => {
        balanceStateParam.handleChange(e);
        dispatch(updateOrdersParams({ balanceState: e.target.value as OrdersParams['balanceState'] }));
    };

    const handleMinBalanceParamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        minBalanceParam.handleChange(e);
        dispatch(updateOrdersParams({ 'balance[gte]': toNumber(e.target.value) }));
    };

    const handleMaxBalanceParamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        maxBalanceParam.handleChange(e);
        dispatch(updateOrdersParams({ 'balance[lte]': toNumber(e.target.value) }));
    };

    const handleAccountableParamChange = (e: SelectChangeEvent<string>) => {
        accountableParam.handleChange(e);
        dispatch(updateOrdersParams({ accountable: e.target.value as OrdersParams['accountable'] }));
    };

    const handleMarketplaceParamChange = (e: SelectChangeEvent<string>) => {
        marketplaceParam.handleChange(e);
        dispatch(updateOrdersParams({ marketplace: e.target.value as OrdersParams['marketplace'] }));
    };

    const handleMinDateParamChange = (date: Date | null) => {
        minDateParam.handleChange(date);
        // Dispatch when user finished typing in the date (with 4-digit year format) or deleting the date
        if ((date && isDateValid(date) && date.getFullYear() > 1000) || !date) {
            dispatch(updateOrdersParams({ 'createdAt[gte]': date ? toYearMonthDayTime(date) : '' }));
        }
    };

    const handleMaxDateParamChange = (date: Date | null) => {
        maxDateParam.handleChange(date);
        // Dispatch when user finished typing in the date (with 4-digit year format) or deleting the date
        if ((date && isDateValid(date) && date.getFullYear() > 1000) || !date) {
            dispatch(updateOrdersParams({ 'createdAt[lte]': date ? toYearMonthDayTime(date) : '' }));
        }
    };

    const handleSearchParamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        searchParam.handleChange(e);
    };

    useEffect(() => {
        dispatch(updateOrdersParams({ search: debouncedSearchTerm }));
    }, [debouncedSearchTerm, dispatch]);

    return {
        balanceStateParam,
        minBalanceParam,
        maxBalanceParam,
        accountableParam,
        marketplaceParam,
        minDateParam,
        maxDateParam,
        searchParam,
        handleBalanceStateParamChange,
        handleMinBalanceParamChange,
        handleMaxBalanceParamChange,
        handleAccountableParamChange,
        handleMarketplaceParamChange,
        handleMinDateParamChange,
        handleMaxDateParamChange,
        handleSearchParamChange,
    };
};
