import React from 'react';
import { formatPrice } from '../../../../../../utils/formatting';
import styles from './transactionTableFooter.module.scss';

interface TransactionTableFooterProps {
    debitTotal: number;
    creditTotal: number;
    selectedDebitTotal: number;
    selectedCreditTotal: number;
}

export const TransactionTableFooter = ({
    debitTotal,
    creditTotal,
    selectedDebitTotal,
    selectedCreditTotal,
}: TransactionTableFooterProps) => {
    const debitEuroTotal = formatPrice(debitTotal);
    const creditEuroTotal = formatPrice(creditTotal);
    const selectedDebitEuroTotal = formatPrice(selectedDebitTotal);
    const selectedCreditEuroTotal = formatPrice(selectedCreditTotal);

    const balancedTotalClassName =
        debitEuroTotal === creditEuroTotal ? styles.gridFooter__totalBalanced : styles.gridFooter__totalUnbalanced;

    const balancedSelectedTotalClassName =
        selectedDebitEuroTotal === selectedCreditEuroTotal
            ? styles.gridFooterSelected__totalBalanced
            : styles.gridFooterSelected__totalUnbalanced;

    return (
        <>
            <div className={styles.gridFooter}>
                {/* Mobile */}
                <p className={styles.gridFooter__mobileRow}>
                    <span className={styles.gridFooter__mobileHeading}>Total debit</span>{' '}
                    <span className={balancedTotalClassName}>{debitEuroTotal}</span>
                </p>
                <p className={styles.gridFooter__mobileRow}>
                    <span className={styles.gridFooter__mobileHeading}>Total credit</span>{' '}
                    <span className={balancedTotalClassName}>{creditEuroTotal}</span>
                </p>
                {/* Desktop */}
                <p className={styles.gridFooter__heading}>Total</p>{' '}
                <p className={styles.gridFooter__total}>
                    <span className={balancedTotalClassName}>{debitEuroTotal}</span>
                </p>
                <p className={styles.gridFooter__total}>
                    {' '}
                    <span className={balancedTotalClassName}>{creditEuroTotal}</span>
                </p>
            </div>
            <div className={styles.gridFooterSelected}>
                {/* Mobile */}
                <p className={styles.gridFooterSelected__mobileRow}>
                    <span className={styles.gridFooterSelected__mobileHeading}>Selected total debit</span>{' '}
                    <span className={balancedSelectedTotalClassName}>{selectedDebitEuroTotal}</span>
                </p>
                <p className={styles.gridFooterSelected__mobileRow}>
                    <span className={styles.gridFooterSelected__mobileHeading}>Selected total credit</span>{' '}
                    <span className={balancedSelectedTotalClassName}>{selectedCreditEuroTotal}</span>
                </p>
                {/* Desktop */}
                <p className={styles.gridFooterSelected__heading}>Selected Total</p>{' '}
                <p className={styles.gridFooterSelected__total}>
                    <span className={balancedSelectedTotalClassName}>{selectedDebitEuroTotal}</span>
                </p>
                <p className={styles.gridFooterSelected__total}>
                    <span className={balancedSelectedTotalClassName}>{selectedCreditEuroTotal}</span>
                </p>
            </div>
        </>
    );
};
