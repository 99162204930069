import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    exactExports: [] as ExactExport[],
    paging: {
        currentPage: 1,
        totalPages: 1,
        totalCount: 1,
        pageCount: 1,
    } as Pagination,
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const exactExportsSlice = createSlice({
    name: 'exactExports',
    initialState,
    reducers: {
        exactExportsLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        exactExportsReceived(state, action: PayloadAction<ExactExports>) {
            return {
                ...state,
                exactExports: action.payload.exports,
                paging: action.payload.paging,
                isLoading: false,
            };
        },
        exactExportsError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
    },
});

export default exactExportsSlice.reducer;

const { exactExportsLoading, exactExportsReceived, exactExportsError } = exactExportsSlice.actions;

type GetExactExportsParams = {
    page: number;
    pageSize: number;
};

export const getExactExports =
    ({ page, pageSize }: GetExactExportsParams) =>
    async (dispatch: Dispatch) => {
        dispatch(exactExportsLoading());
        try {
            const result = await getGatewayApiInstance().get(
                `/accounting/exact-exports?limit=${pageSize}&page=${page}`
            );
            dispatch(exactExportsReceived(result.data));
        } catch (err: unknown) {
            dispatch(exactExportsError());
            handleAxiosErrors(err);
        }
    };

// Selectors
export const selectExactExports = (state: State): ExactExport[] => state.exactExports;
export const selectExactExportsTotal = (state: State): number => state.paging.totalCount;
export const selectIsLoadingExactExports = (state: State): boolean => state.isLoading;
export const selectHasExactExportsError = (state: State): boolean => state.hasError;
