import React from 'react';
import { PageTemplate } from '../../components/templates/PageTemplate/PageTemplate';
import { NewInvoiceRun } from '../../components/NewInvoiceRun/NewInvoiceRun';

export const NewInvoiceRunPage = () => {
    return (
        <PageTemplate headerText='New Invoice Run' hasBackButton>
            <NewInvoiceRun />
        </PageTemplate>
    );
};
