import React from 'react';
import { Dialog, Error } from '@app/components/shared';
import { useRedirect } from '@app/hooks';
import { CheckboxGroup, CheckboxInput, DateInput } from '../shared/Form/Inputs';
import { CancelButton, StartNewRunButton } from '../shared/Buttons';
import { useStartNewInvoiceRun } from './useStartNewInvoiceRun';
import { useFetchInvoiceRunStatus } from './useFetchInvoiceRunStatus';
import SupplierSelect from './components/SupplierSelect';
import SupplierExclude from './components/SupplierExclude';
import styles from './newInvoiceRun.module.scss';

export const NewInvoiceRun = () => {
    const { isLoadingInvoiceRunStatus, hasInvoiceRunStatusError, canCreateNewInvoiceRun } = useFetchInvoiceRunStatus();

    const {
        period,
        isMarketplaceNL,
        isMarketplaceDE,
        formErrors,
        isConfirmationDialogOpen,
        handleOpenNewInvoiceRunConfirmationDialog,
        handleCancelNewInvoiceRunRequest,
        isLoadingNewInvoiceRun,
        hasNewInvoiceRunError,
        handleStartNewInvoiceRunClick,
        selectedSuppliers,
        onSupplierSelect,
        onSupplierRemove,
        suppliersToExclude,
        onSelectSupplierToExclude,
        onRemoveSupplierToExclude,
    } = useStartNewInvoiceRun();

    // Redirect to Invoice Runs page upon successful creation of new invoice run
    useRedirect();

    return (
        <>
            {isLoadingInvoiceRunStatus && (
                <p className={styles.textWrapper}>Checking if there is currently an invoice run in progress...</p>
            )}
            {hasInvoiceRunStatusError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not determine whether there is an invoice run already in progress' />
                </div>
            )}
            {hasNewInvoiceRunError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not start new invoice run' />
                </div>
            )}
            <div className={styles.form}>
                <form noValidate>
                    <DateInput
                        variant='monthly'
                        label='Period'
                        hasExtraLabel={false}
                        value={period.value}
                        handleChange={period.handleChange}
                        error={formErrors.period !== ''}
                        helperText={formErrors.period}
                    />
                    <CheckboxGroup
                        label='Marketplaces'
                        error={formErrors.marketplaceCodes !== ''}
                        helperText={formErrors.marketplaceCodes}
                    >
                        <CheckboxInput
                            label='NL'
                            checked={isMarketplaceNL.value}
                            handleChange={isMarketplaceNL.handleChange}
                        />
                        <CheckboxInput
                            label='DE'
                            checked={isMarketplaceDE.value}
                            handleChange={isMarketplaceDE.handleChange}
                        />
                    </CheckboxGroup>

                    {!suppliersToExclude.length && (
                        <SupplierSelect
                            selectedSuppliersList={selectedSuppliers}
                            onSupplierSelect={onSupplierSelect}
                            onSupplierRemove={onSupplierRemove}
                        />
                    )}

                    {!selectedSuppliers.length && (
                        <SupplierExclude
                            selectedSuppliersList={suppliersToExclude}
                            onSupplierSelect={onSelectSupplierToExclude}
                            onSupplierRemove={onRemoveSupplierToExclude}
                        />
                    )}
                </form>
                <StartNewRunButton
                    isLoading={isLoadingNewInvoiceRun}
                    isInProgress={!canCreateNewInvoiceRun}
                    isDisabled={isLoadingInvoiceRunStatus || hasInvoiceRunStatusError}
                    handleClick={handleOpenNewInvoiceRunConfirmationDialog}
                />
            </div>
            <Dialog titleText='Are you sure you want to Start New Invoice Run?' dialogOpen={isConfirmationDialogOpen}>
                <div>
                    <CancelButton handleClick={handleCancelNewInvoiceRunRequest} />
                </div>
                <div>
                    <StartNewRunButton handleClick={handleStartNewInvoiceRunClick} />
                </div>
            </Dialog>
        </>
    );
};
