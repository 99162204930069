import React from 'react';
import { CancelButton, SaveButton } from '../../../../../shared/Buttons';
import { Dialog } from '../../../../../shared';
import { useConfirmTransactionSubmissionDialog } from './useConfirmTransactionSubmissionDialog';

export const ConfirmTransactionSubmissionDialog = () => {
    const { isConfirmDialogOpen, isLoadingNewTransaction, handleCloseConfirmDialog, handleSubmitNewTransactions } =
        useConfirmTransactionSubmissionDialog();
    return (
        <Dialog titleText='Are you sure you want to submit all new transactions?' dialogOpen={isConfirmDialogOpen}>
            <CancelButton handleClick={handleCloseConfirmDialog} />
            <SaveButton withLoading isLoading={isLoadingNewTransaction} handleClick={handleSubmitNewTransactions} />
        </Dialog>
    );
};
