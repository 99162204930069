import React from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { ActionMenu } from '../../../shared';
import { useExactExportRunsActionMenu } from './useExactExportRunsActionMenu';

export const ExactExportRunsActionMenu = () => {
    const { handleStartNewExactExportRun } = useExactExportRunsActionMenu();

    const menuItems = [
        {
            text: 'New Exact Export Run',
            icon: <RestartAltIcon />,
            handler: handleStartNewExactExportRun,
        },
    ];

    return <ActionMenu menuItems={menuItems} />;
};
