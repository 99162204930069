import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAppSelector } from './redux/typed-hooks';
import {
    selectUserHasViewerRights,
    selectUserHasNoRights,
    selectUserIsAuthenticated,
    selectUserHasManagerRights,
} from './redux/reducers';
import { NavBar } from './components/NavBar/NavBar';
import { SuccessNotification } from './components/SuccessNotification/SuccessNotification';
import {
    FourOhFourPage,
    ExactExportRunDetailsPage,
    ExactExportRunsPage,
    ExactExportsPage,
    ForbiddenPage,
    InvoicesPage,
    InvoiceRunsPage,
    InvoiceRunDetailsPage,
    LoginPage,
    NewExactExportRunPage,
    NewInvoiceRunPage,
    OrderDetailsPage,
    OrdersPage,
    UnauthorizedPage,
    TransactionsPage,
} from './pages';
import { getRoutes } from './utils/routing';
import styles from './app.module.scss';

const RequireAuth = ({
    isAuthenticated,
    isAuthorized,
    hasNoRights,
    children,
}: {
    isAuthenticated: boolean;
    isAuthorized: boolean;
    hasNoRights: boolean;
    children: JSX.Element;
}) => {
    if (isAuthorized) return children;
    if (hasNoRights) return <UnauthorizedPage />; // authenticated but has no rights at all
    if (isAuthenticated && !isAuthorized) return <ForbiddenPage />; // has no sufficient rights
    return <Navigate to='/login' />; // not authenticated
};

export const App = () => {
    const isAuthenticated = useAppSelector(selectUserIsAuthenticated);
    const hasNoRights = useAppSelector(selectUserHasNoRights);
    const hasViewerRights = useAppSelector(selectUserHasViewerRights);
    const hasManagerRights = useAppSelector(selectUserHasManagerRights);

    const routes = getRoutes(
        { orderNo: null, invoiceRunNo: null, exactExportRunNo: null },
        {
            FourOhFourPage,
            ExactExportRunDetailsPage,
            ExactExportRunsPage,
            ExactExportsPage,
            InvoicesPage,
            InvoiceRunsPage,
            InvoiceRunDetailsPage,
            LoginPage,
            NewExactExportRunPage,
            NewInvoiceRunPage,
            OrderDetailsPage,
            OrdersPage,
            TransactionsPage,
        }
    );

    return (
        <>
            <div className={styles.notifications}>
                <SuccessNotification />
            </div>
            <NavBar
                pages={[
                    {
                        name: 'Orders',
                        path: '/orders',
                    },
                    {
                        name: 'Invoices',
                        path: '/invoices',
                    },
                    {
                        name: 'Invoice Runs',
                        path: '/invoice-runs',
                    },
                    {
                        name: 'Exact Exports',
                        path: '/exact-exports',
                    },
                    {
                        name: 'Exact Export Runs',
                        path: '/exact-export-runs',
                    },
                    {
                        name: 'Transactions',
                        path: '/transactions',
                    },
                ]}
                appName='Finance'
                landingPage='/orders'
            />
            <Routes>
                {routes.map(({ path, permissions, Component }) => {
                    const isAuthorized = () => {
                        if (permissions === 'everyone') return true;
                        if (permissions === 'viewer') return hasViewerRights;
                        if (permissions === 'manager') return hasManagerRights;
                        return false;
                    };

                    if (permissions === 'everyone') return <Route path={path} key={path} element={<Component />} />;
                    return (
                        <Route
                            path={path}
                            key={path}
                            element={
                                <RequireAuth
                                    isAuthenticated={isAuthenticated}
                                    isAuthorized={isAuthorized()}
                                    hasNoRights={hasNoRights}
                                >
                                    <Component />
                                </RequireAuth>
                            }
                        />
                    );
                })}
            </Routes>
        </>
    );
};
