import React from 'react';
import { OrderDetails } from '../../components/OrderDetails/OrderDetails';
import { PageTemplate } from '../../components/templates/PageTemplate/PageTemplate';

export const OrderDetailsPage = () => {
    return (
        <PageTemplate headerText='Order details' hasBackButton>
            <OrderDetails />
        </PageTemplate>
    );
};
