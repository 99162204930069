import React from 'react';
import { Login } from '../../components/Login/Login';
import styles from './loginPage.module.scss';

export const LoginPage = () => (
    <div className={styles.loginPage}>
        <div className={styles.loginPage__login}>
            <Login />
        </div>
    </div>
);
