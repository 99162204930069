import { useEffect } from 'react';
import { GridCellParams, MuiEvent } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import { selectHasInvoiceDownloadError } from '../../../../redux/reducers';
import { downloadInvoicePDF, clearInvoiceDownloadError } from '../../../../redux/reducers/invoiceDownload';
import { formatFileName } from '../../../../utils/formatting';

export const useDownloadInvoice = () => {
    const dispatch = useAppDispatch();
    const hasInvoiceDownloadError = useAppSelector(selectHasInvoiceDownloadError);

    const handleDownloadClick = (params: GridCellParams, e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        if (params.field !== 'download-button') return;
        const { id, number: invoiceNumber, addressName } = params.row;
        const companyName = formatFileName(addressName);
        const fileName = `invoice-${invoiceNumber}-${companyName}`;
        dispatch(downloadInvoicePDF({ id, fileName }));
    };

    useEffect(() => {
        dispatch(clearInvoiceDownloadError());
    }, [dispatch]);

    return { handleDownloadClick, hasInvoiceDownloadError };
};
