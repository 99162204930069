import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { getExactExportRuns } from '../../redux/reducers/exactExportRuns';
import {
    selectExactExportRuns,
    selectExactExportRunsTotal,
    selectExactExportRunsParams,
    selectIsLoadingExactExportRuns,
    selectHasExactExportRunsError,
} from '../../redux/reducers';

type FetchExactExportRunsParams = {
    page: number;
    pageSize: number;
    setRowCount: (rowCount: number | undefined) => void;
};

export const useFetchExactExportRuns = ({ page, pageSize, setRowCount }: FetchExactExportRunsParams) => {
    const dispatch = useAppDispatch();
    const exactExportRuns = useAppSelector(selectExactExportRuns);
    const exactExportRunsTotal = useAppSelector(selectExactExportRunsTotal);
    const exactExportRunsParams = useAppSelector(selectExactExportRunsParams);
    const isLoading = useAppSelector(selectIsLoadingExactExportRuns);
    const hasError = useAppSelector(selectHasExactExportRunsError);

    useEffect(() => {
        setRowCount(undefined);
        dispatch(getExactExportRuns({ page: page + 1, pageSize, params: exactExportRunsParams }));
        setRowCount(exactExportRunsTotal);
    }, [dispatch, page, pageSize, exactExportRunsParams, exactExportRunsTotal, setRowCount]);

    return { isLoading, hasError, exactExportRuns };
};
