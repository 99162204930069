import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../../../redux/typed-hooks';
import {
    selectCancellationReasons,
    selectIsLoadingTransactionCancellationInfo,
    selectHasTransactionCancellationInfoError,
} from '../../../../../../redux/reducers';
import { getTransactionCancellationInfo } from '../../../../../../redux/reducers/transactionCancellationInfo';

export const useFetchTransactionCancellationInfo = (isCancellationTransaction: boolean) => {
    const dispatch = useAppDispatch();
    const cancellationReasons = useAppSelector(selectCancellationReasons);
    const isLoadingCancellationReasons = useAppSelector(selectIsLoadingTransactionCancellationInfo);
    const hasCancellationReasonsError = useAppSelector(selectHasTransactionCancellationInfoError);

    useEffect(() => {
        if (isCancellationTransaction) {
            dispatch(getTransactionCancellationInfo());
        }
    }, [dispatch, isCancellationTransaction]);

    return { cancellationReasons, isLoadingCancellationReasons, hasCancellationReasonsError };
};
