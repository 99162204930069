import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { newTransactionReceived } from './transactions';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';
import { 
    getUniqueSkusFromSaleTransactions, 
    getPriceBasedOnSku, 
    getProductNameBasedOnSku, 
    getSupplierNumberBasedOnSku,
    getQuantityBasedOnSku, 
    getPaymentSourceBasedOnPaymentTransaction 
} from '../../utils/transactions';

const initialState = {
    orderData: null as null | OrderData,
    balanceData: null as null | BalanceData,
    isLoading: false,
    isOrderCommentLoading: false,
    hasError: false,
    hasOrderCommentError: false,
};

type State = typeof initialState;

const orderDetailsSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        orderLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        orderReceived(state, action: PayloadAction<OrderDetails>) {
            return {
                ...state,
                orderData: action.payload.orderData,
                balanceData: action.payload.balanceData,
                isLoading: false,
            };
        },
        orderCommentLoading(state) {
            return {
                ...state,
                isOrderCommentLoading: true,
            };
        },
        orderCommentUpdated(state, action: PayloadAction<OrderDetails>) {
            return {
                ...state,
                orderData: action.payload.orderData,
                balanceData: action.payload.balanceData,
                isOrderCommentLoading: false,
            };
        },
        orderError(state) {
            return {
                ...state,
                hasError: true,
                isLoading: false,
            };
        },
        orderCommentHasError(state) {
            return {
                ...state,
                isOrderCommentLoading: false,
                hasOrderCommentError: true,
            };
        },
        orderCommentErrorCleared(state) {
            return {
                ...state,
                hasOrderCommentError: false,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(newTransactionReceived, (state, action: PayloadAction<{ tempId: string; transaction: Transaction }>) => {
            if (state.balanceData) 
                return {
                    ...state,
                    balanceData: {
                        ...state.balanceData,
                        transactions: [...state.balanceData?.transactions || [], action.payload.transaction]
                    }
                }
            return {
                ...state
            }
        });
    }
});

export default orderDetailsSlice.reducer;

const { orderLoading, orderReceived, orderCommentLoading, orderCommentUpdated, orderError, orderCommentHasError } =
    orderDetailsSlice.actions;
export const { orderCommentErrorCleared: clearOrderCommentError } = orderDetailsSlice.actions;

export const getOrder = (orderId: string) => async (dispatch: Dispatch) => {
    dispatch(orderLoading());
    try {
        const res = await getGatewayApiInstance().get(`/orders/${orderId}`);
        dispatch(orderReceived(res.data));
    } catch (err: unknown) {
        dispatch(orderError());
        // Temporarily logging errors to the console
        handleAxiosErrors(err);
    }
};

export const editOrderComment = (comment: string, orderId: string) => async (dispatch: Dispatch) => {
    dispatch(orderCommentLoading());
    try {
        const res = await getGatewayApiInstance().patch(`/orders/${orderId}`, { comment });
        dispatch(orderCommentUpdated(res.data));
    } catch (err: unknown) {
        dispatch(orderCommentHasError());
        handleAxiosErrors(err);
    }
};

// SELECTORS
// Data
export const selectOrderDetails = (state: State): null | OrderDetails => {
    if (state.orderData && state.balanceData) 
    return ({
        orderData: state.orderData,
        balanceData: state.balanceData
    });
    return null;
}; 
export const selectOrderDetailsBalanceData = (state: State): null | BalanceData => state.balanceData;
export const selectOrderDetailsTransactions = (state: State): null | Transaction[] => state.balanceData?.transactions || null;
export const selectOrderDetailsOrderData = (state: State): null | OrderData => state.orderData;
export const selectOrderNumber = (state: State): null | OrderData['orderNumber'] => state.orderData?.orderNumber || null;
export const selectOrderComments = (state: State): OrderDetails['orderData']['comment'] => state.orderData?.comment || '';
export const selectUniqueOrderSkus = (state: State): string[] => getUniqueSkusFromSaleTransactions(state.balanceData?.transactions || []);
export const selectPriceBasedOnSku = (state: State, sku: Transaction['sku']): Transaction['price'] => getPriceBasedOnSku({transactions: state.balanceData?.transactions || [], sku});
export const selectProductNameBasedOnSku = (state: State, sku: Transaction['sku']): Transaction['productName'] => getProductNameBasedOnSku({transactions: state.balanceData?.transactions || [], sku});
export const selectSupplierNumberBasedOnSku = (state: State, sku: Transaction['sku']): Transaction['supplierNumber'] => getSupplierNumberBasedOnSku({transactions: state.balanceData?.transactions || [], sku});
export const selectQuantityBasedOnSku = (state: State, sku: Transaction['sku']): Transaction['quantity'] => getQuantityBasedOnSku({transactions: state.balanceData?.transactions || [], sku});
export const selectOrderMarketplace = (state: State): Marketplace | null => state.orderData?.marketplaceCode || null;
export const selectPaymentSourceBasedOnPaymentTransaction = (state: State): Transaction['paymentSource'] => getPaymentSourceBasedOnPaymentTransaction(state.balanceData?.transactions || []);
// Loading
export const selectIsLoadingOrderDetails = (state: State): boolean => state.isLoading;
export const selectIsLoadingOrderComments = (state: State): boolean => state.isOrderCommentLoading;
// Errors
export const selectHasOrderDetailsError = (state: State): boolean => state.hasError;
export const selectOrderCommentError = (state: State): boolean => state.hasOrderCommentError;
