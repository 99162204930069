import React from 'react';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { MuiEvent } from '@mui/x-data-grid';

interface DeleteButtonProps {
    label?: string;
    isDisabled?: boolean;
    handleClick: (e: MuiEvent<React.MouseEvent<HTMLElement>>) => void;
}

export const DeleteButton = ({ label = 'Delete', isDisabled = false, handleClick }: DeleteButtonProps) => {
    return (
        <Button onClick={handleClick} disabled={isDisabled} type='button' variant='contained' endIcon={<DeleteIcon />}>
            {label}
        </Button>
    );
};
