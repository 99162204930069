import React from 'react';
import { BalanceInfoBlock, OrderInfoBlock, ShopifyOrderLink, TransactionTable, Comment } from './subcomponents';
import { Error, Loader } from '../shared';
import { useOrderDetails } from './useOrderDetails';
import styles from './orderDetails.module.scss';

export const OrderDetails = () => {
    const { order, isLoading, hasError } = useOrderDetails();

    if (isLoading) return <Loader />;
    if (hasError) return <Error />;
    if (!order) return <p>Order details are not available for this order.</p>;

    const { marketplaceCode, shopifyOrderId, orderNumber } = order.orderData;

    return (
        <div className={styles.orderDetails}>
            <div className={styles.orderDetails__orderNo}>
                <ShopifyOrderLink
                    marketplaceCode={marketplaceCode}
                    shopifyOrderId={shopifyOrderId}
                    orderNumber={orderNumber}
                />
            </div>

            <div className={styles.infoBlockWrapper}>
                <div className={styles.blocksWrapper}>
                    <div className={styles.blocksWrapper__orderInfo}>
                        <OrderInfoBlock />
                    </div>
                    <div className={styles.blocksWrapper__balanceInfo}>
                        <BalanceInfoBlock />
                    </div>
                </div>
                <div className={styles.infoBlockWrapper__commentWrapper}>
                    <Comment />
                </div>
            </div>

            <div className={styles.orderDetails__transactions}>
                <TransactionTable transactions={order.balanceData.transactions} />
            </div>
        </div>
    );
};
