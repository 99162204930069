import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    transactionCancellationInfo: [] as TransactionCancellationInfo[],
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const transactionCancellationInfoSlice = createSlice({
    name: 'transactionCancellation',
    initialState,
    reducers: {
        transactionCancellationInfoLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        transactionCancellationInfoReceived(state, action: PayloadAction<TransactionCancellationInfo[]>) {
            return {
                ...state,
                transactionCancellationInfo: action.payload,
                isLoading: false,
            };
        },
        transactionCancellationInfoError(state) {
            return {
                ...state,
                hasError: true,
                isLoading: false,
            };
        },
        transactionCancellationInfoErrorCleared(state) {
            return {
                ...state,
                hasError: false,
            };
        },
    },
});

export default transactionCancellationInfoSlice.reducer;

const { transactionCancellationInfoLoading, transactionCancellationInfoReceived, transactionCancellationInfoError } =
    transactionCancellationInfoSlice.actions;

export const { transactionCancellationInfoErrorCleared: clearTransactionCancellationInfoError } =
    transactionCancellationInfoSlice.actions;

export const getTransactionCancellationInfo = () => async (dispatch: Dispatch) => {
    dispatch(transactionCancellationInfoLoading());
    try {
        const res = await getGatewayApiInstance().get(`/transactions/cancellation-reasons`);
        dispatch(transactionCancellationInfoReceived(res.data));
    } catch (err: unknown) {
        dispatch(transactionCancellationInfoError());
        // Temporarily logging errors to the console
        handleAxiosErrors(err);
    }
};

// Selectors
// Data
export const selectCancellationReasons = (state: State): TransactionCancellationReason[] =>
    state.transactionCancellationInfo.map((info) => ({
        id: info.id,
        description: info.description,
    }));
// Loading
export const selectIsLoadingTransactionCancellationInfo = (state: State): boolean => state.isLoading;
// Errors
export const selectHasTransactionCancellationInfoError = (state: State): boolean => state.hasError;
