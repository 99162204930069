import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/redux/typed-hooks';
import { startNewInvoiceRun } from '@app/redux/reducers/invoiceRuns';
import { selectIsLoadingNewInvoiceRun, selectHasNewInvoiceRunError } from '@app/redux/reducers';
import {
    objectHasNonEmptyValue,
    validateRequiredCheckboxGroup,
    validateRequiredDateField,
} from '@app/utils/validation';
import { getMonth, getYear } from '@app/utils/dates';
import { convertCheckboxGroupToList, toNumber } from '@app/utils/formatting';
import { useCheckboxInput, useDateInput } from '../shared/Form/Inputs';

export const useStartNewInvoiceRun = () => {
    const dispatch = useAppDispatch();
    const isLoadingNewInvoiceRun = useAppSelector(selectIsLoadingNewInvoiceRun);
    const hasNewInvoiceRunError = useAppSelector(selectHasNewInvoiceRunError);

    const period = useDateInput(null);
    const isMarketplaceNL = useCheckboxInput(true);
    const isMarketplaceDE = useCheckboxInput(false);
    const marketplaceCodes = convertCheckboxGroupToList([
        { value: 'nl', checked: isMarketplaceNL.value },
        { value: 'de', checked: isMarketplaceDE.value },
    ]);

    const [formErrors, setFormErrors] = useState({
        period: '',
        marketplaceCodes: '',
    });

    const [selectedSuppliers, setSelectedSuppliers] = useState<InvoiceRunSupplier[]>([]);
    const onSupplierSelect = (supplier: InvoiceRunSupplier) => {
        if (!selectedSuppliers.find((s) => s.id === supplier.id)) {
            setSelectedSuppliers([...selectedSuppliers, supplier]);
        }
    };
    const onSupplierRemove = (supplier: InvoiceRunSupplier) => {
        setSelectedSuppliers(selectedSuppliers.filter((s) => s.id !== supplier.id));
    };

    const [suppliersToExclude, setSuppliersToExclude] = useState<InvoiceRunSupplier[]>([]);
    const onSelectSupplierToExclude = (supplier: InvoiceRunSupplier) => {
        if (!suppliersToExclude.find((s) => s.id === supplier.id)) {
            setSuppliersToExclude([...suppliersToExclude, supplier]);
        }
    };
    const onRemoveSupplierToExclude = (supplier: InvoiceRunSupplier) => {
        setSuppliersToExclude(suppliersToExclude.filter((s) => s.id !== supplier.id));
    };

    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

    const handleOpenNewInvoiceRunConfirmationDialog = () => {
        // Open the confirmation dialog only if parameters pass validation, else show form errors
        const newFormErrors = {
            period: validateRequiredDateField({ value: period.value, fieldName: 'Period' }),
            marketplaceCodes: validateRequiredCheckboxGroup(marketplaceCodes, 'marketplace codes'),
        };
        setFormErrors(newFormErrors);
        if (objectHasNonEmptyValue(newFormErrors)) return;
        setIsConfirmationDialogOpen(true);
    };

    const handleCancelNewInvoiceRunRequest = () => {
        setIsConfirmationDialogOpen(false);
    };

    const handleStartNewInvoiceRunClick = () => {
        const newInvoiceRun: NewInvoiceRun = {
            month: period.value ? toNumber(getMonth(period.value)) : 0,
            year: period.value ? toNumber(getYear(period.value)) : 0,
            marketplaceCodes: marketplaceCodes as InvoiceRun['marketplaceCodes'],
            supplierNumbers: selectedSuppliers.map((supplier) => supplier.supplierNumber),
            supplierNumbersToExclude: suppliersToExclude.map((supplier) => supplier.supplierNumber),
        };

        dispatch(startNewInvoiceRun(newInvoiceRun));
        setIsConfirmationDialogOpen(false);
    };

    return {
        period,
        isMarketplaceNL,
        isMarketplaceDE,
        formErrors,
        isConfirmationDialogOpen,
        handleOpenNewInvoiceRunConfirmationDialog,
        handleCancelNewInvoiceRunRequest,
        isLoadingNewInvoiceRun,
        hasNewInvoiceRunError,
        handleStartNewInvoiceRunClick,
        selectedSuppliers,
        onSupplierSelect,
        onSupplierRemove,
        suppliersToExclude,
        onSelectSupplierToExclude,
        onRemoveSupplierToExclude,
    };
};
