import { useNavigate } from 'react-router-dom';

export const useInvoiceRunsActionMenu = () => {
    const navigate = useNavigate();

    const handleStartNewInvoiceRun = () => {
        navigate('/invoice-runs/new');
    };

    return { handleStartNewInvoiceRun };
};
