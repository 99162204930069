import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { notificationUpdated } from './notifications';
import { redirectLinkUpdated } from './redirect';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';
import { currentYear } from '../../utils/dates';
import { toUrlParams } from '../../utils/formatting';

const initialState = {
    exactExportRuns: [] as ExactExportRun[],
    exactExportRunsParams: {
        dateFrom: `${currentYear - 2}-01-01`,
        dateUntil: `${currentYear}-12-31`,
    } as ExactExportRunsParams,
    paging: {
        currentPage: 1,
        totalPages: 1,
        totalCount: 1,
        pageCount: 1,
    } as Pagination,
    isLoading: false,
    isLoadingNewExactExportRun: false,
    hasError: false,
    hasNewExactExportRunError: false,
};

type State = typeof initialState;

const exactExportRunsSlice = createSlice({
    name: 'exactExportRuns',
    initialState,
    reducers: {
        exactExportRunsLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        newExactExportRunLoading(state) {
            return {
                ...state,
                isLoadingNewExactExportRun: true,
                hasNewExactExportRunError: false,
            };
        },
        exactExportRunsReceived(state, action: PayloadAction<ExactExportRuns>) {
            return {
                ...state,
                exactExportRuns: action.payload.exactExportRuns,
                paging: action.payload.paging,
                isLoading: false,
            };
        },
        newExactExportRunStarted(state) {
            return {
                ...state,
                isLoadingNewExactExportRun: false,
            };
        },
        exactExportRunsParamsUpdated(state, action: PayloadAction<Partial<ExactExportRunsParams>>) {
            return {
                ...state,
                exactExportRunsParams: {
                    ...state.exactExportRunsParams,
                    ...action.payload,
                },
            };
        },
        exactExportRunsError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
        newExactExportRunError(state) {
            return {
                ...state,
                isLoadingNewExactExportRun: false,
                hasNewExactExportRunError: true,
            };
        },
        newExactExportRunErrorCleared(state) {
            return {
                ...state,
                hasNewExactExportRunError: false,
            };
        },
    },
});

export default exactExportRunsSlice.reducer;

const {
    exactExportRunsLoading,
    newExactExportRunLoading,
    exactExportRunsReceived,
    newExactExportRunStarted,
    exactExportRunsError,
    newExactExportRunError,
} = exactExportRunsSlice.actions;
export const {
    exactExportRunsParamsUpdated: updateExactExportRunsParams,
    newExactExportRunErrorCleared: clearNewExactExportRunError,
} = exactExportRunsSlice.actions;

type GetExactExportRunsParams = {
    page: number;
    pageSize: number;
    params: ExactExportRunsParams;
};

export const getExactExportRuns =
    ({ page, pageSize, params }: GetExactExportRunsParams) =>
    async (dispatch: Dispatch) => {
        dispatch(exactExportRunsLoading());
        try {
            const urlParams = toUrlParams(params);
            const res = await getGatewayApiInstance().get(
                `/exact-export-runs?limit=${pageSize}&page=${page}${urlParams}`
            );
            dispatch(exactExportRunsReceived(res.data));
        } catch (err: unknown) {
            dispatch(exactExportRunsError());
            handleAxiosErrors(err);
        }
    };

export const startNewExactExportRun = (newExactExportRun: NewExactExportRun) => async (dispatch: Dispatch) => {
    dispatch(newExactExportRunLoading());
    try {
        await getGatewayApiInstance().post('/exact-export-runs', newExactExportRun);
        dispatch(newExactExportRunStarted());
        dispatch(redirectLinkUpdated('/exact-export-runs'));
        dispatch(notificationUpdated('New Exact Export run is in progress'));
    } catch (err: unknown) {
        dispatch(newExactExportRunError());
        // Temporarily logging errors to the console
        handleAxiosErrors(err);
    }
};

// Selectors
// Data
export const selectExactExportRuns = (state: State): ExactExportRun[] => state.exactExportRuns;
export const selectExactExportRunsTotal = (state: State): Pagination['totalCount'] => state.paging.totalCount;
export const selectExactExportRunsParams = (state: State): ExactExportRunsParams => state.exactExportRunsParams;
// Loading
export const selectIsLoadingExactExportRuns = (state: State): boolean => state.isLoading;
export const selectIsLoadingNewExactExportRun = (state: State): boolean => state.isLoadingNewExactExportRun;
// Errors
export const selectHasExactExportRunsError = (state: State): boolean => state.hasError;
export const selectHasNewExactExportRunError = (state: State): boolean => state.hasNewExactExportRunError;
