import React from 'react';
import DialogComponent from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

interface DialogParams {
    dialogOpen: boolean;
    titleText: string;
    children: React.ReactNode;
}

export const Dialog = ({ dialogOpen, titleText, children }: DialogParams) => {
    return (
        <DialogComponent open={dialogOpen} aria-labelledby='alert-dialog-title'>
            <DialogTitle id='alert-dialog-title'>{titleText}</DialogTitle>
            <DialogActions>{children}</DialogActions>
        </DialogComponent>
    );
};
