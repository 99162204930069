import React from 'react';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import { Header } from '../../shared';
import styles from './page-template.module.scss';

type PageTemplateProps = {
    headerText: string;
    hasBackButton: boolean;
    children: React.ReactNode;
};

export const PageTemplate: React.FC<PageTemplateProps> = ({ headerText, hasBackButton, children }) => {
    return (
        <section className={styles.page}>
            <div className={styles.page__content}>
                <div className={styles.page__breadcrumbs}>
                    <Breadcrumbs />
                </div>
                <Header text={headerText} hasBackButton={hasBackButton} />
                <div className={styles.page__children}>{children}</div>
            </div>
        </section>
    );
};
