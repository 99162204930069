import React from 'react';
import { LinearProgress } from '@mui/material';

export const DataGridLinearProgress = () => {
    return <LinearProgress />;
};

/**
    USAGE:
    <DataGrid
        {...}
        loading={isLoading}
        components={{
            LoadingOverlay: DataGridLinearProgress,
        }}
    />
*/
