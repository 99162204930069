import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueFormatterParams, GridRowParams } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import { getOrders } from '../../../../redux/reducers/orders';
import {
    selectOrders,
    selectOrdersParams,
    selectOrdersTotal,
    selectIsLoadingOrders,
    selectHasOrdersError,
} from '../../../../redux/reducers';
import { Error, Link } from '../../../shared';
import {
    useDataGridServerSidePagination,
    DataGridExportToolbar,
    DataGridLinearProgress,
} from '../../../shared/DataGrid';
import { formatShopifyLink, formatBool } from '../../../../utils/formatting';
import { toYearMonthDayTime } from '../../../../utils/dates';
import { shopifyUrlNl, shopifyUrlDe } from '../../../../utils/shopify';

export const OrderList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const orders = useAppSelector(selectOrders);
    const ordersTotal = useAppSelector(selectOrdersTotal);
    const ordersParams = useAppSelector(selectOrdersParams);
    const isLoading = useAppSelector(selectIsLoadingOrders);
    const hasError = useAppSelector(selectHasOrdersError);

    const INITIAL_PAGE_SIZE = 10;
    const { rowCountState, page, pageSize, handlePageChange, handlePageSizeChange, setRowCount } =
        useDataGridServerSidePagination({
            initialPageSize: INITIAL_PAGE_SIZE,
        });

    useEffect(() => {
        setRowCount(undefined);
        // DataGrid uses zero-based indexing while backend pagination uses one-based indexing: that's why `page: page + 1`
        dispatch(getOrders({ page: page + 1, pageSize, params: ordersParams }));
        setRowCount(ordersTotal);
    }, [dispatch, page, pageSize, ordersParams, ordersTotal, setRowCount]);

    const handleRowClick = (params: GridRowParams) => {
        navigate(`/orders/${params.row.id}`);
    };

    const columns: GridColDef[] = [
        {
            field: 'orderNumber',
            headerName: 'Order Number',
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'balanceAmount',
            headerName: 'Balance',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'created',
            headerName: 'Created at',
            valueFormatter: (params: GridValueFormatterParams<string>) => toYearMonthDayTime(params.value),
            flex: 1,
            minWidth: 150,
        },
        // This property does not currently exist on the order object.
        // {
        //     field: 'tags',
        //     headerName: 'Tags',
        //     flex: 1,
        // },
        {
            field: 'marketplaceCode',
            headerName: 'Marketplace',
            valueFormatter: (params: GridValueFormatterParams<Marketplace>) => params.value.toUpperCase(),
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'shopifyOrderId',
            headerName: 'Shopify Link',
            renderCell: (params: GridRenderCellParams<{ shopifyOrderId: number; marketplaceCode: Marketplace }>) => {
                const { shopifyOrderId, marketplaceCode } = params.row;
                return (
                    <Link
                        href={formatShopifyLink({ shopifyOrderId, marketplaceCode, shopifyUrlNl, shopifyUrlDe })}
                        text={params.row.shopifyOrderId.toString()}
                    />
                );
            },
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'isAccountable',
            headerName: 'Accountable?',
            valueFormatter: (params: GridValueFormatterParams<boolean>) => formatBool(params.value),
            flex: 1,
            minWidth: 120,
        },
    ];

    if (hasError) return <Error />;
    return (
        <DataGrid
            columns={columns}
            rows={orders}
            rowCount={rowCountState}
            loading={isLoading}
            components={{
                LoadingOverlay: DataGridLinearProgress,
                Toolbar: DataGridExportToolbar,
            }}
            rowsPerPageOptions={[INITIAL_PAGE_SIZE, 25, 50, 100]}
            paginationMode='server'
            page={page}
            pageSize={pageSize}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onRowClick={handleRowClick}
            autoHeight
            sx={{ cursor: 'pointer' }}
        />
    );
};
