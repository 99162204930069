import React from 'react';
import { Dialog, Error } from '../shared';
import { CancelButton, StartNewRunButton } from '../shared/Buttons';
import { Fieldset } from '../shared/Form';
import { CheckboxGroup, CheckboxInput, DateInput } from '../shared/Form/Inputs';
import { useFetchExactExportRunStatus } from './useFetchExactExportRunStatus';
import { useStartNewExactExportRun } from './useStartNewExactExportRun';
import { useRedirect } from '../../hooks';
import styles from './newExactExportRun.module.scss';

export const NewExactExportRun = () => {
    const { isLoadingExactExportRunStatus, hasExactExportRunStatusError, canCreateNewExactExportRun } =
        useFetchExactExportRunStatus();

    const {
        period,
        isMarketplaceNL,
        isMarketplaceDE,
        formErrors,
        isConfirmationDialogOpen,
        handleOpenNewExactExportRunConfirmationDialog,
        handleCancelNewExactExportRunRequest,
        isLoadingNewExactExportRun,
        hasNewExactExportRunError,
        handleStartNewExactExportRunClick,
    } = useStartNewExactExportRun();

    // Redirect to Exact Exports Runs page upon successful creation of new Exact Export run
    useRedirect();

    return (
        <>
            {isLoadingExactExportRunStatus && (
                <p className={styles.textWrapper}>Checking if there is currently an Exact Export run in progress...</p>
            )}
            {hasExactExportRunStatusError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not determine whether there is an Exact Export run already in progress' />
                </div>
            )}
            {hasNewExactExportRunError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not start new Exact Export run' />
                </div>
            )}
            <div className={styles.form}>
                <form noValidate>
                    <Fieldset legend='New Exact Export Run'>
                        <DateInput
                            variant='monthly'
                            label='Period'
                            hasExtraLabel={false}
                            value={period.value}
                            handleChange={period.handleChange}
                            error={formErrors.period !== ''}
                            helperText={formErrors.period}
                        />
                        <CheckboxGroup
                            label='Marketplaces'
                            error={formErrors.marketplaceCodes !== ''}
                            helperText={formErrors.marketplaceCodes}
                        >
                            <CheckboxInput
                                label='NL'
                                checked={isMarketplaceNL.value}
                                handleChange={isMarketplaceNL.handleChange}
                            />
                            <CheckboxInput
                                label='DE'
                                checked={isMarketplaceDE.value}
                                handleChange={isMarketplaceDE.handleChange}
                            />
                        </CheckboxGroup>
                    </Fieldset>
                </form>

                <StartNewRunButton
                    isLoading={isLoadingNewExactExportRun}
                    isInProgress={!canCreateNewExactExportRun}
                    isDisabled={isLoadingExactExportRunStatus || hasExactExportRunStatusError}
                    handleClick={handleOpenNewExactExportRunConfirmationDialog}
                />
            </div>
            <Dialog
                titleText='Are you sure you want to start new Exact Export run?'
                dialogOpen={isConfirmationDialogOpen}
            >
                <div>
                    <CancelButton handleClick={handleCancelNewExactExportRunRequest} />
                </div>
                <div>
                    <StartNewRunButton handleClick={handleStartNewExactExportRunClick} />
                </div>
            </Dialog>
        </>
    );
};
