import React, { useState } from 'react';

export const useNumberInput = (initialValue: number | null) => {
    // Empty string allows the controlled number input to be empty (null values are not allowed)
    const [value, setValue] = useState<number | ''>(initialValue || '');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: targetValue } = e.target;
        if (!targetValue) {
            setValue('');
        } else {
            const numberValue = parseFloat(targetValue);
            setValue(numberValue);
        }
    };

    return { value, handleChange };
};
