import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import { selectExactExportRunsParams } from '../../../../redux/reducers';
import { updateExactExportRunsParams } from '../../../../redux/reducers/exactExportRuns';
import { useDateInput } from '../../../shared/Form/Inputs';
import { isDateValid, toYearMonthDay } from '../../../../utils/dates';

export const useEditExactExportRunsParams = () => {
    const dispatch = useAppDispatch();
    const exactExportRunsParams = useAppSelector(selectExactExportRunsParams);
    const dateFrom = useDateInput(exactExportRunsParams.dateFrom);
    const dateUntil = useDateInput(exactExportRunsParams.dateUntil);

    const handleDateFromParamChange = (date: Date | null) => {
        dateFrom.handleChange(date);
        // Dispatch when user finished typing in the date (with 4-digit year format) or deleting the date
        if ((date && isDateValid(date) && date.getFullYear() > 1000) || !date) {
            dispatch(updateExactExportRunsParams({ dateFrom: date ? toYearMonthDay(date) : '' }));
        }
    };

    const handleDateUntilParamChange = (date: Date | null) => {
        dateUntil.handleChange(date);
        // Dispatch when user finished typing in the date (with 4-digit year format) or deleting the date
        if ((date && isDateValid(date) && date.getFullYear() > 1000) || !date) {
            dispatch(updateExactExportRunsParams({ dateUntil: date ? toYearMonthDay(date) : '' }));
        }
    };

    return {
        dateFrom,
        dateUntil,
        handleDateFromParamChange,
        handleDateUntilParamChange,
    };
};
