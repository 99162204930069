import { useEffect } from 'react';
import { GridCellParams, MuiEvent } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { selectHasExactExportDownloadError } from '../../redux/reducers';
import { downloadExactExportCSV, clearExactExportDownloadError } from '../../redux/reducers/exactExportDownload';
import { formatExactExportType } from '../../utils/exactExports';
import { toYearMonthDay } from '../../utils/dates';

export const useDownloadExactExport = () => {
    const dispatch = useAppDispatch();
    const hasExactExportDownloadError = useAppSelector(selectHasExactExportDownloadError);

    const handleDownloadClick = (params: GridCellParams, e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        if (params.field !== 'download-button') return;
        const { id, marketplaceCode, type: exactExportType, maxOrderNumber, maxTransactionDateTime } = params.row;

        const type = formatExactExportType(exactExportType).replaceAll(' ', '_');
        const cutOffDate = toYearMonthDay(maxTransactionDateTime).replaceAll('-', '');

        const fileName = `${marketplaceCode}_${type}_${maxOrderNumber}_${cutOffDate}`;
        dispatch(downloadExactExportCSV({ id, fileName }));
    };

    useEffect(() => {
        dispatch(clearExactExportDownloadError());
    }, [dispatch]);

    return { handleDownloadClick, hasExactExportDownloadError };
};
