import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import {
    selectHasInvoicesError,
    selectInvoices,
    selectInvoicesParams,
    selectInvoicesTotal,
    selectIsLoadingInvoices,
} from '../../../../redux/reducers';
import { getInvoices } from '../../../../redux/reducers/invoices';

type FetchInvoicesParams = {
    page: number;
    pageSize: number;
    setRowCount: (rowCount: number | undefined) => void;
};

export const useFetchInvoices = ({ page, pageSize, setRowCount }: FetchInvoicesParams) => {
    const dispatch = useAppDispatch();
    const invoices = useAppSelector(selectInvoices);
    const invoicesTotal = useAppSelector(selectInvoicesTotal);
    const invoicesParams = useAppSelector(selectInvoicesParams);
    const isLoading = useAppSelector(selectIsLoadingInvoices);
    const hasError = useAppSelector(selectHasInvoicesError);

    useEffect(() => {
        setRowCount(undefined);
        dispatch(getInvoices({ page: page + 1, pageSize, params: invoicesParams }));
        setRowCount(invoicesTotal);
    }, [dispatch, page, pageSize, invoicesParams, invoicesTotal, setRowCount]);

    return { invoices, isLoading, hasError };
};
