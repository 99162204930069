import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { ITTP } from '@app/components/TransactionsUpload/types';
import { getUIReadableTTPType } from '@app/components/TransactionsUpload/utils';
import { TransactionDetailsModal } from '@app/components/TransactionsUpload/components/TransactionDetailsModal';

interface IProps {
    TTP: ITTP;
}

export const TransactionItem = ({ TTP }: IProps) => {
    const theme = useTheme();

    return (
        <TransactionDetailsModal TTP={TTP}>
            <Paper elevation={2} sx={{ p: theme.spacing(2), height: 'min-content' }}>
                <Typography fontWeight={900}>Transaction #{TTP.transactionNumber}</Typography>
                <Typography fontSize={14}>
                    <strong>Type:</strong> {getUIReadableTTPType(TTP.Type)}
                </Typography>
                <Typography fontSize={14}>
                    <strong>Order Number:</strong> {TTP.OrderNumber}
                </Typography>
            </Paper>
        </TransactionDetailsModal>
    );
};
