import { combineReducers } from '@reduxjs/toolkit';
import authReducer, * as fromAuth from './auth';
import ordersReducer, * as fromOrders from './orders';
import orderDetailsReducer, * as fromOrderDetails from './orderDetails';
import invoicesReducer, * as fromInvoices from './invoices';
import invoiceRunsReducer, * as fromInvoiceRuns from './invoiceRuns';
import invoiceRunDetailsReducer, * as fromInvoiceRunDetails from './invoiceRunDetails';
import invoiceRunDownloadReducer, * as fromInvoiceRunDownload from './invoiceRunDownload';
import invoiceRunStatusReducer, * as fromInvoiceRunStatus from './invoiceRunStatus';
import invoiceRunSuppliersReducer, * as fromInvoiceRunSuppliers from './invoiceRunSuppliers';
import invoiceDownloadReducer, * as fromInvoiceDownload from './invoiceDownload';
import exactExportRunDetailsReducer, * as fromExactExportRunDetails from './exactExportRunDetails';
import exactExportRunDownloadReducer, * as fromExactExportRunDownload from './exactExportRunDownload';
import exactExportRunsReducer, * as fromExactExportRuns from './exactExportRuns';
import exactExportRunStatusReducer, * as fromExactExportRunStatus from './exactExportRunStatus';
import exactExportsReducer, * as fromExactExports from './exactExports';
import exactExportDownloadReducer, * as fromExactExportsDownload from './exactExportDownload';
import notificationsReducer, * as fromNotifications from './notifications';
import redirectReducer, * as fromRedirect from './redirect';
import transactionTypesReducer, * as fromTransactionTypes from './transactionTypes';
import transactionCancellationInfoReducer, * as fromTransactionCancellationInfo from './transactionCancellationInfo';
import transactionsReducer, * as fromTransactions from './transactions';
import type { RootState } from '../store';

export default combineReducers({
    authReducer,
    ordersReducer,
    orderDetailsReducer,
    invoicesReducer,
    invoiceRunsReducer,
    invoiceRunDetailsReducer,
    invoiceRunDownloadReducer,
    invoiceRunStatusReducer,
    invoiceRunSuppliersReducer,
    invoiceDownloadReducer,
    exactExportRunDetailsReducer,
    exactExportRunDownloadReducer,
    exactExportRunsReducer,
    exactExportRunStatusReducer,
    exactExportsReducer,
    exactExportDownloadReducer,
    notificationsReducer,
    redirectReducer,
    transactionTypesReducer,
    transactionCancellationInfoReducer,
    transactionsReducer,
});

// GLOBAL SELECTORS
// Auth
export const selectUserName = (state: RootState): string => fromAuth.selectUserName(state.authReducer);
export const selectUserPicture = (state: RootState): string => fromAuth.selectUserPicture(state.authReducer);
export const selectUserIsAuthenticated = (state: RootState): boolean => fromAuth.selectUserIsAuthenticated(state.authReducer);
export const selectUserHasViewerRights = (state: RootState): boolean => fromAuth.selectUserHasViewerRights(state.authReducer);
export const selectUserHasManagerRights = (state: RootState): boolean => fromAuth.selectUserHasManagerRights(state.authReducer);
export const selectUserHasNoRights = (state: RootState): boolean => fromAuth.selectUserHasNoRights(state.authReducer);
// Errors
export const selectHasLoginError = (state: RootState): boolean => fromAuth.selectHasLoginError(state.authReducer);

// Orders
export const selectOrders = (state: RootState): Order[] => fromOrders.selectOrders(state.ordersReducer);
export const selectOrdersTotal = (state: RootState): number => fromOrders.selectOrdersTotal(state.ordersReducer);
export const selectOrdersParams = (state: RootState): OrdersParams => fromOrders.selectOrdersParams(state.ordersReducer);
// Loading
export const selectIsLoadingOrders = (state: RootState): boolean => fromOrders.selectIsLoadingOrders(state.ordersReducer);
// Errors
export const selectHasOrdersError = (state: RootState): boolean => fromOrders.selectHasOrdersError(state.ordersReducer);

// Order details
export const selectOrderDetailsBalanceData = (state: RootState): null | BalanceData => fromOrderDetails.selectOrderDetailsBalanceData(state.orderDetailsReducer);
export const selectOrderDetailsTransactions = (state: RootState): null | Transaction[] => fromOrderDetails.selectOrderDetailsTransactions(state.orderDetailsReducer);
export const selectOrderDetailsOrderData = (state: RootState): null | OrderData => fromOrderDetails.selectOrderDetailsOrderData(state.orderDetailsReducer);
export const selectOrderDetails = (state: RootState): null | OrderDetails => fromOrderDetails.selectOrderDetails(state.orderDetailsReducer);
export const selectOrderNumber = (state: RootState): null | OrderData['orderNumber'] => fromOrderDetails.selectOrderNumber(state.orderDetailsReducer);
export const selectOrderComments = (state: RootState): null | OrderDetails['orderData']['comment'] => fromOrderDetails.selectOrderComments(state.orderDetailsReducer);
export const selectUniqueOrderSkus = (state: RootState): string[] => fromOrderDetails.selectUniqueOrderSkus(state.orderDetailsReducer);
export const selectPriceBasedOnSku = (state: RootState, sku: Transaction['sku']): Transaction['price'] => fromOrderDetails.selectPriceBasedOnSku(state.orderDetailsReducer, sku);
export const selectProductNameBasedOnSku = (state: RootState, sku: Transaction['sku']): Transaction['productName'] => fromOrderDetails.selectProductNameBasedOnSku(state.orderDetailsReducer, sku);
export const selectSupplierNumberBasedOnSku = (state: RootState, sku: Transaction['sku']): Transaction['supplierNumber'] => fromOrderDetails.selectSupplierNumberBasedOnSku(state.orderDetailsReducer, sku);
export const selectQuantityBasedOnSku = (state: RootState, sku: Transaction['sku']): Transaction['quantity'] => fromOrderDetails.selectQuantityBasedOnSku(state.orderDetailsReducer, sku);
export const selectOrderMarketplace = (state: RootState): Marketplace | null => fromOrderDetails.selectOrderMarketplace(state.orderDetailsReducer);
export const selectPaymentSourceBasedOnPaymentTransaction = (state: RootState): Transaction['paymentSource'] => fromOrderDetails.selectPaymentSourceBasedOnPaymentTransaction(state.orderDetailsReducer);
// Loading
export const selectIsLoadingOrderDetails = (state: RootState): boolean => fromOrderDetails.selectIsLoadingOrderDetails(state.orderDetailsReducer);
export const selectIsLoadingOrderComments = (state: RootState): boolean => fromOrderDetails.selectIsLoadingOrderComments(state.orderDetailsReducer);
// Errors
export const selectHasOrderDetailsError = (state: RootState): boolean => fromOrderDetails.selectHasOrderDetailsError(state.orderDetailsReducer);
export const selectOrderCommentError = (state: RootState): boolean => fromOrderDetails.selectOrderCommentError(state.orderDetailsReducer);

// Transaction types
export const selectManualTransactionsInfo = (state: RootState): TransactionInfo[] => fromTransactionTypes.selectManualTransactionsInfo(state.transactionTypesReducer);
export const selectAllTransactionsInfo = (state: RootState): TransactionInfo[] => fromTransactionTypes.selectAllTransactionsInfo(state.transactionTypesReducer);
export const selectIsLoadingTransactionTypes = (state: RootState): boolean => fromTransactionTypes.selectIsLoadingTransactionTypes(state.transactionTypesReducer);
export const selectHasTransactionTypesError = (state: RootState): boolean => fromTransactionTypes.selectHasTransactionTypesError(state.transactionTypesReducer);

// Transaction cancellation info
export const selectCancellationReasons = (state: RootState): TransactionCancellationReason[] => fromTransactionCancellationInfo.selectCancellationReasons(state.transactionCancellationInfoReducer);
export const selectIsLoadingTransactionCancellationInfo = (state: RootState): boolean => fromTransactionCancellationInfo.selectIsLoadingTransactionCancellationInfo(state.transactionCancellationInfoReducer);
export const selectHasTransactionCancellationInfoError = (state: RootState): boolean => fromTransactionCancellationInfo.selectHasTransactionCancellationInfoError(state.transactionCancellationInfoReducer);

// Transactions
export const selectTemporaryTransactionsPerOrder = (state: RootState, orderNumber: Transaction['orderNumber'] | null): TemporaryTransaction[] => fromTransactions.selectTemporaryTransactionsPerOrder(state.transactionsReducer, orderNumber);
export const selectRejectedTransactionsPerOrder = (state: RootState, orderNumber: Transaction['orderNumber'] | null): TemporaryTransaction[] => fromTransactions.selectRejectedTransactionsPerOrder(state.transactionsReducer, orderNumber);
export const selectTemporaryTransactionsInclRejectedPerOrder = (state: RootState, orderNumber: Transaction['orderNumber'] | null): TemporaryTransaction[] => fromTransactions.selectTemporaryTransactionsInclRejectedPerOrder(state.transactionsReducer, orderNumber);
export const selectSelectedTemporaryTransactionsIds = (state: RootState): string[] => fromTransactions.selectSelectedTemporaryTransactionsIds(state.transactionsReducer);
export const selectIsSubmitTransactionsConfirmDialogOpen = (state: RootState): boolean => fromTransactions.selectIsSubmitTransactionsConfirmDialogOpen(state.transactionsReducer);
// Loading
export const selectIsLoadingNewTransaction = (state: RootState) => fromTransactions.selectIsLoadingNewTransaction(state.transactionsReducer);
// Errors
export const selectHasNewTransactionError = (state: RootState) => fromTransactions.selectHasNewTransactionError(state.transactionsReducer);

// Invoices
export const selectInvoices = (state: RootState): Invoice[] => fromInvoices.selectInvoices(state.invoicesReducer);
export const selectInvoicesTotal = (state: RootState): number => fromInvoices.selectInvoicesTotal(state.invoicesReducer);
export const selectInvoicesParams = (state: RootState): InvoicesParams => fromInvoices.selectInvoicesParams(state.invoicesReducer);
// Loading
export const selectIsLoadingInvoices = (state: RootState): boolean => fromInvoices.selectIsLoadingInvoices(state.invoicesReducer);
// Errors
export const selectHasInvoicesError = (state: RootState): boolean => fromInvoices.selectHasInvoicesError(state.invoicesReducer);

// Invoice Runs
export const selectInvoiceRuns = (state: RootState): InvoiceRun[] => fromInvoiceRuns.selectInvoiceRuns(state.invoiceRunsReducer);
export const selectInvoiceRunsTotal = (state: RootState): Pagination['totalCount'] => fromInvoiceRuns.selectInvoiceRunsTotal(state.invoiceRunsReducer);
export const selectInvoiceRunsParams = (state: RootState): InvoiceRunsParams => fromInvoiceRuns.selectInvoiceRunsParams(state.invoiceRunsReducer);
// Loading
export const selectIsLoadingInvoiceRuns = (state: RootState): boolean => fromInvoiceRuns.selectIsLoadingInvoiceRuns(state.invoiceRunsReducer);
export const selectIsLoadingNewInvoiceRun = (state: RootState): boolean => fromInvoiceRuns.selectIsLoadingNewInvoiceRun(state.invoiceRunsReducer);
// Errors
export const selectHasInvoiceRunsError = (state: RootState): boolean => fromInvoiceRuns.selectHasInvoiceRunsError(state.invoiceRunsReducer);
export const selectHasNewInvoiceRunError = (state: RootState): boolean => fromInvoiceRuns.selectHasNewInvoiceRunError(state.invoiceRunsReducer);

// Invoice Run
export const selectInvoiceRun = (state: RootState): null | InvoiceRun => fromInvoiceRunDetails.selectInvoiceRun(state.invoiceRunDetailsReducer);
export const selectInvoiceRunId = (state: RootState): null | InvoiceRun['id'] => fromInvoiceRunDetails.selectInvoiceRunId(state.invoiceRunDetailsReducer);
export const selectIsInvoiceRunCancellable = (state: RootState): boolean => fromInvoiceRunDetails.selectIsInvoiceRunCancellable(state.invoiceRunDetailsReducer);
export const selectIsInvoiceRunGenerated = (state: RootState): boolean => fromInvoiceRunDetails.selectIsInvoiceRunGenerated(state.invoiceRunDetailsReducer);
export const selectCanInvoiceRunBeMarkedAsTest = (state: RootState): boolean => fromInvoiceRunDetails.selectCanInvoiceRunBeMarkedAsTest(state.invoiceRunDetailsReducer);
export const selectCanInvoiceRunBeFinalised = (state: RootState): boolean => fromInvoiceRunDetails.selectCanInvoiceRunBeFinalised(state.invoiceRunDetailsReducer);
export const selectCanInvoiceRunBeEmailed = (state: RootState): boolean => fromInvoiceRunDetails.selectCanInvoiceRunBeEmailed(state.invoiceRunDetailsReducer);
// Loading
export const selectIsLoadingInvoiceRun = (state: RootState): boolean => fromInvoiceRunDetails.selectIsLoadingInvoiceRun(state.invoiceRunDetailsReducer);
// Errors
export const selectHasInvoiceRunError = (state: RootState): boolean => fromInvoiceRunDetails.selectHasInvoiceRunError(state.invoiceRunDetailsReducer);
export const selectHasCancelInvoiceRunError = (state: RootState): boolean => fromInvoiceRunDetails.selectHasCancelInvoiceRunError(state.invoiceRunDetailsReducer);
export const selectHasMarkAsTestInvoiceRunError = (state: RootState): boolean => fromInvoiceRunDetails.selectHasMarkAsTestInvoiceRunError(state.invoiceRunDetailsReducer);
export const selectHasFinaliseInvoiceRunError = (state: RootState): boolean => fromInvoiceRunDetails.selectHasFinaliseInvoiceRunError(state.invoiceRunDetailsReducer);
export const selectHasEmailInvoiceRunError = (state: RootState): boolean => fromInvoiceRunDetails.selectHasEmailInvoiceRunError(state.invoiceRunDetailsReducer);

// Invoice Run Download
export const selectHasInvoiceRunDownloadError = (state: RootState): boolean => fromInvoiceRunDownload.selectHasInvoiceRunDownloadError(state.invoiceRunDownloadReducer);

// Invoice Run Status
export const selectCanCreateNewInvoiceRun = (state: RootState): InvoiceRunStatus['canCreateNewInvoiceRun'] => fromInvoiceRunStatus.selectCanCreateNewInvoiceRun(state.invoiceRunStatusReducer);
export const selectIsLoadingInvoiceRunStatus = (state: RootState): boolean => fromInvoiceRunStatus.selectIsLoadingInvoiceRunStatus(state.invoiceRunStatusReducer);
export const selectHasInvoiceRunStatusError = (state: RootState): boolean => fromInvoiceRunStatus.selectHasInvoiceRunStatusError(state.invoiceRunStatusReducer);

// Invoice Run Suppliers
export const selectInvoiceRunSuppliers = (state: RootState): InvoiceRunSupplier[] => fromInvoiceRunSuppliers.selectInvoiceRunSuppliers(state.invoiceRunSuppliersReducer);
export const selectIsLoadingInvoiceRunSuppliers = (state: RootState): boolean => fromInvoiceRunSuppliers.selectIsLoadingInvoiceRunSuppliers(state.invoiceRunSuppliersReducer);
export const selectHasInvoiceRunSuppliersError = (state: RootState): boolean => fromInvoiceRunSuppliers.selectHasInvoiceRunSuppliersError(state.invoiceRunSuppliersReducer);
export const selectInvoiceRunSuppliersPage = (state: RootState): number => fromInvoiceRunSuppliers.selectInvoiceRunSuppliersPage(state.invoiceRunSuppliersReducer);

// Invoice Download
export const selectHasInvoiceDownloadError = (state: RootState): boolean => fromInvoiceDownload.selectHasInvoiceDownloadError(state.invoiceDownloadReducer);

// Exact Exports
export const selectExactExports = (state: RootState): ExactExport[] => fromExactExports.selectExactExports(state.exactExportsReducer);
export const selectExactExportsTotal = (state: RootState): number => fromExactExports.selectExactExportsTotal(state.exactExportsReducer);
export const selectIsLoadingExactExports = (state: RootState): boolean => fromExactExports.selectIsLoadingExactExports(state.exactExportsReducer);
export const selectHasExactExportsError = (state: RootState): boolean => fromExactExports.selectHasExactExportsError(state.exactExportsReducer);

// Exact Exports Download
export const selectHasExactExportDownloadError = (state: RootState): boolean => fromExactExportsDownload.selectHasExactExportDownloadError(state.exactExportDownloadReducer);

// Exact Export Runs
export const selectExactExportRuns = (state: RootState): ExactExportRun[] => fromExactExportRuns.selectExactExportRuns(state.exactExportRunsReducer);
export const selectExactExportRunsTotal = (state: RootState): Pagination['totalCount'] => fromExactExportRuns.selectExactExportRunsTotal(state.exactExportRunsReducer);
export const selectExactExportRunsParams = (state: RootState): ExactExportRunsParams => fromExactExportRuns.selectExactExportRunsParams(state.exactExportRunsReducer);
// Loading
export const selectIsLoadingExactExportRuns = (state: RootState): boolean => fromExactExportRuns.selectIsLoadingExactExportRuns(state.exactExportRunsReducer);
export const selectIsLoadingNewExactExportRun = (state: RootState): boolean => fromExactExportRuns.selectIsLoadingNewExactExportRun(state.exactExportRunsReducer);
// Errors
export const selectHasExactExportRunsError = (state: RootState): boolean => fromExactExportRuns.selectHasExactExportRunsError(state.exactExportRunsReducer);
export const selectHasNewExactExportRunError = (state: RootState): boolean => fromExactExportRuns.selectHasNewExactExportRunError(state.exactExportRunsReducer);

// Exact Export Run Details
export const selectExactExportRun = (state: RootState): null | ExactExportRun => fromExactExportRunDetails.selectExactExportRun(state.exactExportRunDetailsReducer);
export const selectExactExportRunId = (state: RootState): null | ExactExportRun['id'] => fromExactExportRunDetails.selectExactExportRunId(state.exactExportRunDetailsReducer);
export const selectExactExportRunMaxOrderNumber = (state: RootState): null | ExactExportRun['maxOrderNumber'] => fromExactExportRunDetails.selectExactExportRunMaxOrderNumber(state.exactExportRunDetailsReducer);
export const selectExactExportRunCutoffDateTime = (state: RootState): null | ExactExportRun['cutoffDateTime'] => fromExactExportRunDetails.selectExactExportRunCutoffDateTime(state.exactExportRunDetailsReducer);
export const selectIsExactExportRunCancellable = (state: RootState): boolean => fromExactExportRunDetails.selectIsExactExportRunCancellable(state.exactExportRunDetailsReducer);
export const selectIsExactExportRunGenerated = (state: RootState): boolean => fromExactExportRunDetails.selectIsExactExportRunGenerated(state.exactExportRunDetailsReducer);
export const selectCanExactExportRunBeFinalised = (state: RootState): boolean => fromExactExportRunDetails.selectCanExactExportRunBeFinalised(state.exactExportRunDetailsReducer);
export const selectCanExactExportRunBeMarkedAsTest = (state: RootState): boolean => fromExactExportRunDetails.selectCanExactExportRunBeMarkedAsTest(state.exactExportRunDetailsReducer);
// Loading
export const selectIsLoadingExactExportRun = (state: RootState): boolean => fromExactExportRunDetails.selectIsLoadingExactExportRun(state.exactExportRunDetailsReducer);
// Errors
export const selectHasExactExportRunError = (state: RootState): boolean => fromExactExportRunDetails.selectHasExactExportRunError(state.exactExportRunDetailsReducer);
export const selectHasFinaliseExactExportRunError = (state: RootState): boolean => fromExactExportRunDetails.selectHasFinaliseExactExportRunError(state.exactExportRunDetailsReducer);
export const selectHasMarkAsTestExactExportRunError = (state: RootState): boolean => fromExactExportRunDetails.selectHasMarkAsTestExactExportRunError(state.exactExportRunDetailsReducer);
export const selectHasCancelExactExportRunError = (state: RootState): boolean => fromExactExportRunDetails.selectHasCancelExactExportRunError(state.exactExportRunDetailsReducer);

// Exact Export Run Status
export const selectCanCreateNewExactExportRun = (state: RootState): ExactExportRunStatus['canCreateNewExactExportRun'] => fromExactExportRunStatus.selectCanCreateNewExactExportRun(state.exactExportRunStatusReducer);
export const selectIsLoadingExactExportRunStatus = (state: RootState): boolean => fromExactExportRunStatus.selectIsLoadingExactExportRunStatus(state.exactExportRunStatusReducer);
export const selectHasExactExportRunStatusError = (state: RootState): boolean => fromExactExportRunStatus.selectHasExactExportRunStatusError(state.exactExportRunStatusReducer);

// Exact Export Run Download
export const selectHasExactExportRunDownloadError = (state: RootState): boolean => fromExactExportRunDownload.selectHasExactExportRunDownloadError(state.exactExportRunDownloadReducer);

// Notifications
export const selectSuccessMsg = (state: RootState): SuccessMsg => fromNotifications.selectSuccessMsg(state.notificationsReducer);

// Redirect
export const selectRedirectLink = (state: RootState): string => fromRedirect.selectRedirectLink(state.redirectReducer);
