import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Loader } from '@app/components/shared';
import { useFetchInvoiceRunSuppliers } from './useFetchInvoiceRunSuppliers';

interface IProps {
    selectedSuppliersList: InvoiceRunSupplier[];
    onSupplierSelect: (supplier: InvoiceRunSupplier) => void;
    onSupplierRemove: (supplier: InvoiceRunSupplier) => void;
}

const SupplierList = ({
    suppliersList,
    selectedSuppliersList,
    onSupplierSelect,
    isLoading,
}: {
    selectedSuppliersList: InvoiceRunSupplier[];
    suppliersList: InvoiceRunSupplier[];
    onSupplierSelect: (supplier: InvoiceRunSupplier) => void;
    isLoading: boolean;
}) => {
    if (isLoading) return <Loader />;
    if (!suppliersList.length && !isLoading)
        return (
            <Typography p={3} textAlign='center' fontSize={18}>
                No suppliers found
            </Typography>
        );

    return (
        <List>
            <Grid container gap={2} justifyContent='center'>
                {suppliersList
                    .filter((supplier) => !selectedSuppliersList.find((s) => s.id === supplier.id))
                    .map((supplier) => (
                        <Grid item key={supplier.id}>
                            <Button
                                variant='outlined'
                                onClick={() => onSupplierSelect(supplier)}
                                sx={{ textTransform: 'none' }}
                            >
                                {supplier.supplierName}
                            </Button>
                        </Grid>
                    ))}
            </Grid>
        </List>
    );
};

const SupplierSelect = ({ selectedSuppliersList, onSupplierSelect, onSupplierRemove }: IProps) => {
    const { invoiceRunSuppliers, searchValue, onSearchValueChange, isLoadingInvoiceRunSuppliers } =
        useFetchInvoiceRunSuppliers();

    return (
        <>
            <Typography fontWeight={600}>Selected suppliers:</Typography>
            <Paper sx={{ maxHeight: 200, minWidth: 800, overflow: 'auto', mb: 2, pr: 2, pl: 2 }}>
                {!!selectedSuppliersList.length && (
                    <List>
                        <Grid container gap={2} justifyContent='center'>
                            {selectedSuppliersList.map((supplier) => (
                                <Grid item key={supplier.id}>
                                    <Button
                                        variant='outlined'
                                        color='success'
                                        onClick={() => onSupplierRemove(supplier)}
                                        sx={{ textTransform: 'none' }}
                                    >
                                        {supplier.supplierName}
                                        <CloseIcon fontSize='small' />
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </List>
                )}

                {!selectedSuppliersList.length && (
                    <Typography p={3} textAlign='center' fontSize={18}>
                        Currently no suppliers are selected and <strong>Invoice Run</strong> will be applied for all
                        suppliers in selected marketplaces
                    </Typography>
                )}
            </Paper>

            <TextField
                variant='outlined'
                placeholder='Search for suppliers'
                InputProps={{
                    endAdornment: (
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                    ),
                }}
                sx={{
                    mb: 2,
                    mt: 2,
                }}
                value={searchValue}
                onChange={onSearchValueChange}
            />

            <Paper sx={{ maxHeight: 200, minWidth: 800, overflow: 'auto', mb: 2, pr: 2, pl: 2 }}>
                <SupplierList
                    selectedSuppliersList={selectedSuppliersList}
                    suppliersList={invoiceRunSuppliers}
                    onSupplierSelect={onSupplierSelect}
                    isLoading={isLoadingInvoiceRunSuppliers}
                />
            </Paper>
        </>
    );
};

export default SupplierSelect;
