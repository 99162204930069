import React from 'react';
import { PageTemplate } from '../../components/templates/PageTemplate/PageTemplate';
import { InfoAlert } from '../../components/shared';

export const ForbiddenPage = () => {
    return (
        <PageTemplate headerText='Forbidden' hasBackButton>
            <InfoAlert title='You do not have sufficient rights to access this page' />
        </PageTemplate>
    );
};
