import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { useAppDispatch } from '../../../../redux/typed-hooks';
import { updateInvoicesParams } from '../../../../redux/reducers/invoices';
import { useSearchInput, useSelectInput } from '../../../shared/Form/Inputs';
import { useDebounce } from '../../../../hooks';
import { toNumber } from '../../../../utils/formatting';

export const useEditInvoicesFromInvoiceRunParams = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const marketplace = useSelectInput('');
    const search = useSearchInput('');
    const query = search.value;
    const debouncedSearchTerm = useDebounce(query, 300);

    const handleMarketplaceChange = (e: SelectChangeEvent<string>) => {
        marketplace.handleChange(e);
        dispatch(updateInvoicesParams({ marketplace: e.target.value as InvoicesParams['marketplace'] }));
    };

    const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        search.handleChange(e);
    };

    useEffect(() => {
        if (id) {
            dispatch(updateInvoicesParams({ invoiceRunId: toNumber(id), dateFrom: '', dateUntil: '' }));
        }
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(updateInvoicesParams({ search: debouncedSearchTerm }));
    }, [dispatch, debouncedSearchTerm]);

    return { marketplace, query, handleMarketplaceChange, handleQueryChange };
};
