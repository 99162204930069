import React, { useEffect } from 'react';
import GoogleButton from 'react-google-button';
import Avatar from '@mui/material/Avatar';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { login, clearUserLoginError } from '../../redux/reducers/auth';
import { selectHasLoginError } from '../../redux/reducers';
import { Error, Heading } from '../shared';
import { useRedirect } from '../../hooks';
import naduviLogo from './naduvi-logo.png';
import styles from './login.module.scss';

export const Login = () => {
    const dispatch = useAppDispatch();
    const hasError = useAppSelector(selectHasLoginError);

    const handleLogin = () => {
        dispatch(login());
    };

    useRedirect();

    useEffect(() => {
        dispatch(clearUserLoginError());
    }, [dispatch]);

    return (
        <div>
            <div className={styles.paper}>
                <div className={styles.headingWrapper}>
                    <Avatar className={styles.avatar} src={naduviLogo} />
                    <Heading level={1} text='Sign in' />
                </div>
                <GoogleButton label='with Google' style={{ width: '100%' }} onClick={handleLogin} />
                {hasError && (
                    <div className={styles.errorWrapper}>
                        <Error errorMsg='You have insufficient rights to log in.' />
                    </div>
                )}
            </div>
        </div>
    );
};
