import React from 'react';
import { PageTemplate } from '../../components/templates/PageTemplate/PageTemplate';
import { ExactExportRuns } from '../../components/ExactExportRuns/ExactExportRuns';

export const ExactExportRunsPage = () => {
    return (
        <PageTemplate headerText='Exact Export Runs' hasBackButton>
            <ExactExportRuns />
        </PageTemplate>
    );
};
