import React from 'react';
import Button from '@mui/material/Button';

interface CancelButtonProps {
    handleClick: () => void;
}

export const CancelButton = ({ handleClick }: CancelButtonProps) => (
    <Button onClick={handleClick} variant='outlined'>
        Cancel
    </Button>
);
