import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { getInvoiceRuns } from '../../redux/reducers/invoiceRuns';
import {
    selectInvoiceRuns,
    selectInvoiceRunsTotal,
    selectInvoiceRunsParams,
    selectIsLoadingInvoiceRuns,
    selectHasInvoiceRunsError,
} from '../../redux/reducers';

type FetchInvoiceRunsParams = {
    page: number;
    pageSize: number;
    setRowCount: (rowCount: number | undefined) => void;
};

export const useFetchInvoiceRuns = ({ page, pageSize, setRowCount }: FetchInvoiceRunsParams) => {
    const dispatch = useAppDispatch();
    const invoiceRuns = useAppSelector(selectInvoiceRuns);
    const invoiceRunsTotal = useAppSelector(selectInvoiceRunsTotal);
    const invoiceRunsParams = useAppSelector(selectInvoiceRunsParams);
    const isLoading = useAppSelector(selectIsLoadingInvoiceRuns);
    const hasError = useAppSelector(selectHasInvoiceRunsError);

    useEffect(() => {
        setRowCount(undefined);
        dispatch(getInvoiceRuns({ page: page + 1, pageSize, params: invoiceRunsParams }));
        setRowCount(invoiceRunsTotal);
    }, [dispatch, page, pageSize, invoiceRunsParams, invoiceRunsTotal, setRowCount]);

    return { isLoading, hasError, invoiceRuns };
};
