import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import {
    cancelInvoiceRun,
    markAsTestInvoiceRun,
    finaliseInvoiceRun,
    emailInvoiceRun,
} from '../../../../redux/reducers/invoiceRunDetails';
import { downloadInvoiceRunZip } from '../../../../redux/reducers/invoiceRunDownload';
import {
    selectIsInvoiceRunGenerated,
    selectCanInvoiceRunBeMarkedAsTest,
    selectCanInvoiceRunBeFinalised,
    selectIsInvoiceRunCancellable,
    selectCanInvoiceRunBeEmailed,
} from '../../../../redux/reducers';
import { toNumber } from '../../../../utils/formatting';

export const useInvoiceRunDetailsActionMenu = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const isGenerated = useAppSelector(selectIsInvoiceRunGenerated);
    const isCancellable = useAppSelector(selectIsInvoiceRunCancellable);
    const canBeMarkedAsTest = useAppSelector(selectCanInvoiceRunBeMarkedAsTest);
    const canBeFinalised = useAppSelector(selectCanInvoiceRunBeFinalised);
    const canBeEmailed = useAppSelector(selectCanInvoiceRunBeEmailed);

    const handleCancelRun = () => {
        if (id) {
            dispatch(cancelInvoiceRun(toNumber(id)));
        }
    };

    const handleDownloadRun = () => {
        if (id) {
            dispatch(downloadInvoiceRunZip(toNumber(id)));
        }
    };

    const handleMarkAsTestRun = () => {
        if (id) {
            dispatch(markAsTestInvoiceRun(toNumber(id)));
        }
    };

    const handleFinaliseRun = () => {
        if (id) {
            dispatch(finaliseInvoiceRun(toNumber(id)));
        }
    };

    const handleEmailRun = () => {
        if (id) {
            dispatch(emailInvoiceRun(toNumber(id)));
        }
    };

    return {
        isGenerated,
        isCancellable,
        canBeMarkedAsTest,
        canBeFinalised,
        canBeEmailed,
        handleCancelRun,
        handleMarkAsTestRun,
        handleDownloadRun,
        handleFinaliseRun,
        handleEmailRun,
    };
};
