import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FileUpload } from '@app/components/shared/Form/Inputs/FileUpload/FileUpload';
import { TTPGrid } from '@app/components/TransactionsUpload/components/TTPGrid';
import { useTTP } from './useTTP';

export const TransactionsUploadCSV = () => {
    const {
        onFileUpload,
        onClear,
        onProcess,
        TTPs,
        doesHaveTTPsToProcess,
        isProcessInProgress,
        isErrorReportAvailable,
        downloadTTPErrorReport,
    } = useTTP();

    return (
        <>
            <TTPGrid TTPs={TTPs} />

            <Grid container justifyContent='space-between'>
                <Grid item>
                    <FileUpload
                        accept='text/csv'
                        buttonTitle={TTPs.length ? 'Import another TTP CSV file' : 'Import TTP CSV file'}
                        onFileUpload={onFileUpload}
                    />
                </Grid>
                {Boolean(TTPs.length) && (
                    <Grid item>
                        <Button color='info' onClick={onClear}>
                            Clear
                        </Button>
                        <Button
                            color='success'
                            onClick={onProcess}
                            disabled={!doesHaveTTPsToProcess || isProcessInProgress}
                        >
                            Process Transactions
                        </Button>
                        <Button
                            color='error'
                            onClick={downloadTTPErrorReport}
                            disabled={!isErrorReportAvailable || isProcessInProgress}
                        >
                            Download Error Report
                        </Button>
                    </Grid>
                )}
            </Grid>
        </>
    );
};
