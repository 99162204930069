import React from 'react';
import { DateInput, SearchInput, SelectInput } from '../../../shared/Form/Inputs';
import { useEditInvoiceParams } from './useEditInvoiceParams';
import styles from './editInvoiceParams.module.scss';

export const EditInvoiceParams = () => {
    const {
        dateFrom,
        dateUntil,
        marketplace,
        query,
        handleDateFromParamChange,
        handleDateUntilParamChange,
        handleMarketplaceChange,
        handleQueryChange,
    } = useEditInvoiceParams();

    return (
        <div className={styles.outerFlexContainer}>
            <div className={styles.outerFlexContainer__item}>
                <div className={styles.innerFlexContainer}>
                    <div className={styles.innerFlexContainer__item}>
                        <DateInput
                            label='From date'
                            hasExtraLabel={false}
                            value={dateFrom.value}
                            handleChange={handleDateFromParamChange}
                        />
                    </div>
                    <div className={styles.innerFlexContainer__item}>
                        <DateInput
                            label='Until date'
                            hasExtraLabel={false}
                            value={dateUntil.value}
                            handleChange={handleDateUntilParamChange}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.outerFlexContainer__item}>
                <div className={styles.innerFlexContainer}>
                    <div className={styles.innerFlexContainer__item}>
                        <SelectInput
                            label='Marketplace'
                            hasExtraLabel={false}
                            options={['NL', 'DE']}
                            value={marketplace.value}
                            handleChange={handleMarketplaceChange}
                        />
                    </div>
                    <div className={styles.innerFlexContainer__item}>
                        <SearchInput value={query} handleChange={handleQueryChange} />
                    </div>
                </div>
            </div>
        </div>
    );
};
