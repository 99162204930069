import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../../../redux/typed-hooks';
import {
    selectHasTransactionTypesError,
    selectIsLoadingTransactionTypes,
    selectManualTransactionsInfo,
} from '../../../../../../redux/reducers';
import { getTransactionTypes } from '../../../../../../redux/reducers/transactionTypes';

export const useFetchTransactionTypes = () => {
    const dispatch = useAppDispatch();
    const isLoadingTransactionTypes = useAppSelector(selectIsLoadingTransactionTypes);
    const hasTransactionTypesError = useAppSelector(selectHasTransactionTypesError);
    const manualTransactionsInfo = useAppSelector(selectManualTransactionsInfo);

    useEffect(() => {
        dispatch(getTransactionTypes());
    }, [dispatch]);

    return {
        isLoadingTransactionTypes,
        hasTransactionTypesError,
        manualTransactionsInfo,
    };
};
