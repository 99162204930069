import React, { ChangeEvent, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

interface IProps {
    buttonTitle?: string;
    accept?: string;
    onFileUpload?: (file: File) => void;
}

export const FileUpload = ({ buttonTitle = 'Click to select', accept = '', onFileUpload }: IProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [file, setFile] = useState<File>();
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleUploadClick = () => {
        inputRef.current?.click();
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        const newFile = e.target.files[0];

        setFile(newFile);
        if (onFileUpload) onFileUpload(newFile);
        e.target.value = '';
    };

    return (
        <Box>
            <Button onClick={handleUploadClick}>{buttonTitle}</Button>

            <input type='file' accept={accept} ref={inputRef} onChange={handleFileChange} style={{ display: 'none' }} />
        </Box>
    );
};
