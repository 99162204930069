import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';
import { toUrlParams } from '../../utils/formatting';

const initialState = {
    ordersParams: {
        balanceState: '',
        'balance[gte]': '',
        'balance[lte]': '',
        accountable: '',
        search: '',
        marketplace: '',
        'createdAt[gte]': '',
        'createdAt[lte]': '',
        tag: '',
    } as OrdersParams,
    orders: [] as Order[],
    paging: {
        currentPage: 1,
        totalPages: 1,
        totalCount: 1,
        pageCount: 1,
    } as Pagination,
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        ordersLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        ordersReceived(state, action: PayloadAction<Orders>) {
            return {
                ...state,
                orders: action.payload.orders,
                paging: action.payload.paging,
                isLoading: false,
            };
        },
        ordersParamsUpdated(state, action: PayloadAction<Partial<OrdersParams>>) {
            return {
                ...state,
                ordersParams: {
                    ...state.ordersParams,
                    ...action.payload,
                },
            };
        },
        ordersError(state) {
            return {
                ...state,
                hasError: true,
                isLoading: false,
            };
        },
    },
});

export default ordersSlice.reducer;

const { ordersLoading, ordersReceived, ordersError } = ordersSlice.actions;
export const { ordersParamsUpdated: updateOrdersParams } = ordersSlice.actions;

type GetOrdersParams = {
    page: number;
    pageSize: number;
    params: OrdersParams;
};

export const getOrders =
    ({ page, pageSize, params }: GetOrdersParams) =>
    async (dispatch: Dispatch) => {
        dispatch(ordersLoading());
        try {
            const urlParams = toUrlParams(params);
            const res = await getGatewayApiInstance().get(`/orders?limit=${pageSize}&page=${page}${urlParams}`);
            dispatch(ordersReceived(res.data));
        } catch (err: unknown) {
            dispatch(ordersError());
            // Temporarily logging errors to the console
            handleAxiosErrors(err);
        }
    };

// Selectors
export const selectOrders = (state: State): Order[] => state.orders;
export const selectOrdersTotal = (state: State): number => state.paging.totalCount;
export const selectOrdersParams = (state: State): OrdersParams => state.ordersParams;
// Loading
export const selectIsLoadingOrders = (state: State): boolean => state.isLoading;
// Errors
export const selectHasOrdersError = (state: State): boolean => state.hasError;
