import { ITTP, TTPFileData, TTPStatus, TTPType } from '@app/components/TransactionsUpload/types';
import { TTPFileValidationSchema } from '@app/components/TransactionsUpload/validation';
import { toDateTime } from '@app/utils/dates';
import { makeId } from '@app/utils/random';
import { getErrorMessage } from '@app/utils/error';

const parseNumStringIntoNumber = (numString: string): number => {
    if (!numString) return 0;

    return parseFloat(numString.replace(',', '.'));
};

export const getUIReadableTTPType = (type: TTPType) => {
    switch (type) {
        case TTPType.ConsumerDiscountRefundTransaction:
            return 'Consumer Discount Refund';
        case TTPType.ConsumerDiscountTransaction:
            return 'Consumer Discount';
        case TTPType.ConsumerPaymentDiscrepancyTransaction:
            return 'Consumer Payment Discrepancy';
        case TTPType.ConsumerPaymentTransaction:
            return 'Consumer Payment';
        case TTPType.ConsumerRefundTransaction:
            return 'Consumer Refund';
        case TTPType.ConsumerSettledReturnShippingCostsTransaction:
            return 'Consumer Settled Return Shipping Costs';
        case TTPType.MarketplaceChargebackCostsTransaction:
            return 'Marketplace Chargeback Costs';
        case TTPType.MarketplaceCompensationForConsumerTransaction:
            return 'Marketplace Compensation For Consumer';
        case TTPType.MarketplaceExplanationForCreditPaymentDiscrepancyTransaction:
            return 'Marketplace Explanation For Credit Payment Discrepancy';
        case TTPType.MarketplaceRevenueCorrectionTransaction:
            return 'Marketplace Revenue Correction';
        case TTPType.MarketplaceVatShiftedForConsumerTransaction:
            return 'Marketplace Vat Shifted For Consumer';
        case TTPType.SupplierCancellationTransaction:
            return 'Supplier Cancellation';
        case TTPType.SupplierCompensationAsRevenueTransaction:
            return 'Supplier Compensation As Revenue';
        case TTPType.SupplierCompensationFixedTransaction:
            return 'Supplier Compensation Fixed';
        case TTPType.SupplierCompensationForConsumerTransaction:
            return 'Supplier Compensation For Consumer';
        case TTPType.SupplierCompensationForMarketplaceTransaction:
            return 'Supplier Compensation For Marketplace';
        case TTPType.SupplierVatShiftedCompensationForMarketplaceTransaction:
            return 'Supplier Vat Shifted Compensation For Marketplace';
        case TTPType.ThirdPartyDeliveryCostsTransaction:
            return 'ThirdParty Delivery Costs';
        case TTPType.SupplierFulfillmentTransaction:
            return 'Supplier Fulfillment';

        default:
            return 'Unknown type';
    }
};

// eslint-disable-next-line consistent-return
export const getTransactionTypeBasedOnTTPType = (ttpType: TTPType, transactionTypes: TransactionInfo[]) => {
    // eslint-disable-next-line default-case
    switch (ttpType) {
        case TTPType.ConsumerDiscountRefundTransaction:
            return transactionTypes.find((t) => t.transaction === 'discountRefund');
        case TTPType.ConsumerDiscountTransaction:
            return transactionTypes.find((t) => t.transaction === 'discount');
        case TTPType.ConsumerPaymentDiscrepancyTransaction:
            return transactionTypes.find((t) => t.transaction === 'paymentDiscrepancy');
        case TTPType.ConsumerPaymentTransaction:
            return transactionTypes.find((t) => t.transaction === 'payment');
        case TTPType.ConsumerRefundTransaction:
            return transactionTypes.find((t) => t.transaction === 'refund');
        case TTPType.ConsumerSettledReturnShippingCostsTransaction:
            return transactionTypes.find((t) => t.transaction === 'settledReturnShippingCosts');
        case TTPType.MarketplaceChargebackCostsTransaction:
            return transactionTypes.find((t) => t.transaction === 'marketplaceChargebackCosts');
        case TTPType.MarketplaceCompensationForConsumerTransaction:
            return transactionTypes.find((t) => t.transaction === 'marketplaceCompensationForConsumer');
        case TTPType.MarketplaceExplanationForCreditPaymentDiscrepancyTransaction:
            return transactionTypes.find((t) => t.transaction === 'creditPaymentExplanation');
        case TTPType.MarketplaceRevenueCorrectionTransaction:
            return transactionTypes.find((t) => t.transaction === 'revenueCorrection');
        case TTPType.MarketplaceVatShiftedForConsumerTransaction:
            return transactionTypes.find((t) => t.transaction === 'marketplaceVatShiftedForConsumer');
        case TTPType.SupplierCancellationTransaction:
            return transactionTypes.find((t) => t.transaction === 'cancellation');
        case TTPType.SupplierCompensationAsRevenueTransaction:
            return transactionTypes.find((t) => t.transaction === 'compensationAsRevenue');
        case TTPType.SupplierCompensationFixedTransaction:
            return transactionTypes.find((t) => t.transaction === 'compensationFixed');
        case TTPType.SupplierCompensationForConsumerTransaction:
            return transactionTypes.find((t) => t.transaction === 'compensationForConsumer');
        case TTPType.SupplierCompensationForMarketplaceTransaction:
            return transactionTypes.find((t) => t.transaction === 'compensationForMarketplace');
        case TTPType.SupplierVatShiftedCompensationForMarketplaceTransaction:
            return transactionTypes.find((t) => t.transaction === 'compensationVatShiftedForMarketplace');
        case TTPType.ThirdPartyDeliveryCostsTransaction:
            return transactionTypes.find((t) => t.transaction === 'thirdPartyDeliveryCosts');
        case TTPType.SupplierFulfillmentTransaction:
            return transactionTypes.find((t) => t.transaction === 'fulfillment');
    }
};

export const mapTTPintoCreateTransactionEntity = (ttp: ITTP, transactionsInfo: TransactionInfo[]) => {
    const createTransactionType = getTransactionTypeBasedOnTTPType(ttp.Type, transactionsInfo);

    return {
        externalTransactionId: ttp.OrderNumber || '',
        type: createTransactionType?.transaction || '',
        datetime: toDateTime(ttp.Date || new Date().toDateString()),
        marketplaceCode: ttp.Marketplace?.toLowerCase() || '',
        orderNumber: Number(ttp.OrderNumber) || 0,
        amount: parseNumStringIntoNumber(ttp.Amount || ''),
        description: ttp.Description || '',
        paymentSource: ttp.PaymentSource || '',
        supplierNumber: Number(ttp.VendorNumber) || 0,
        sku: ttp.SKU || '',
        productName: ttp.Name || '',
        brand: ttp.Brand || '',
        price: parseNumStringIntoNumber(ttp.Price || ''),
        quantity: Number(ttp.Quantity) || 0,
        cancelReasonId: null,
        trackingCompany: '',
        trackingNumbers: [],
        trackingUrls: [],
        shipmentStatus: '',
    } as CreateTransaction;
};

export const downloadErrorReport = (TTPs: ITTP[]) => {
    const errorTTPs = TTPs.filter((ttp) => ttp.status === TTPStatus.ERROR);

    if (!errorTTPs.length) return;

    const CSVHeaders = [
        'Marketplace',
        'Type',
        'OrderNumber',
        'Date',
        'Description',
        'VendorNumber',
        'PaymentSource',
        'Amount',
        'SKU',
        'Name',
        'Price',
        'Quantity',
        'Brand',
        'CancelReasonId',
        '!ErrorMessage',
    ];

    const rows: string[][] = errorTTPs.map((ttp) => [
        ttp.Marketplace || '',
        ttp.Type || '',
        ttp.OrderNumber || '',
        ttp.Date || '',
        ttp.Description || '',
        ttp.VendorNumber || '',
        ttp.PaymentSource || '',
        ttp.Amount || '',
        ttp.SKU || '',
        ttp.Name || '',
        ttp.Price || '',
        ttp.Quantity || '',
        ttp.Brand || '',
        ttp.CancelReasonId || '',
        ttp.errorMessage || 'Unknown Error',
    ]);

    const csvContent = `${CSVHeaders.join(',')}\n${rows.map((e) => e.join(',')).join('\n')}`;

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', href);
    link.setAttribute('download', `ttp-error-report-${makeId(5)}.csv`);
    document.body.appendChild(link);

    link.click();
};

// Returns validation error or null
export const validateTTPFileItem = async (ttpFileItem: TTPFileData) => {
    try {
        await TTPFileValidationSchema.validate(ttpFileItem);
    } catch (err) {
        return getErrorMessage(err);
    }

    return null;
};
