import React from 'react';
import { Error, Loader } from '../../../../../shared';
import { Fieldset } from '../../../../../shared/Form';
import { DateInput, NumberInput, SelectInput, TextInput } from '../../../../../shared/Form/Inputs';
import { AddButton, CancelButton } from '../../../../../shared/Buttons';
import { useNewTransactionForm } from './useNewTransactionForm';
import styles from './newTransactionForm.module.scss';

interface NewTransactionFormProps {
    manualTransactionsInfo: TransactionInfo[];
    handleHideTransactionForm: () => void;
}

export const NewTransactionForm = ({ manualTransactionsInfo, handleHideTransactionForm }: NewTransactionFormProps) => {
    const {
        handleAddTemporaryTransaction,
        transactionTypes,
        requiredFieldsBasedOnTransactionType,
        transactionTypeDescription,
        uniqueOrderSkus,
        formErrors,
        // Inputs
        transactionType,
        datetime,
        description,
        defaultDescription,
        handleDescriptionChange,
        sku,
        amount,
        supplierNumber,
        supplierNumberBasedOnSku,
        handleSupplierNumberChange,
        price,
        priceBasedOnSku,
        handlePriceChange,
        quantity,
        quantityBasedOnSku,
        handleQuantityChange,
        paymentSource,
        cancelReasonId,
        isLoadingCancellationReasons,
        hasCancellationReasonsError,
        cancellationReasons,
    } = useNewTransactionForm({ manualTransactionsInfo, handleHideTransactionForm });

    const cancellationReasonOptions = cancellationReasons.map((reason) => ({
        value: reason.id.toString(),
        text: reason.description,
    }));

    const formInputs = requiredFieldsBasedOnTransactionType
        .map((field) => {
            if (
                [
                    'orderNumber',
                    'marketplaceCode',
                    'productName',
                    'externalTransactionId',
                    'brand',
                    'trackingCompany',
                    'trackingNumbers',
                    'trackingUrls',
                    'shipmentStatus',
                ].includes(field)
            )
                return null;
            if (field === 'cancelReasonId') {
                if (isLoadingCancellationReasons) return <Loader key={field} />;
                if (hasCancellationReasonsError)
                    return (
                        <div key={field} className={styles.errorWrapper}>
                            <Error errorMsg='Could not load cancellation reasons' />
                        </div>
                    );
                return (
                    <SelectInput
                        key={field}
                        label='Cancellation reason'
                        hasExtraLabel={false}
                        value={cancelReasonId.value}
                        options={cancellationReasonOptions}
                        handleChange={cancelReasonId.handleChange}
                        error={formErrors.cancelReasonId !== ''}
                        helperText={formErrors.cancelReasonId}
                    />
                );
            }
            if (field === 'datetime')
                return (
                    <DateInput
                        key={field}
                        label='Date'
                        hasExtraLabel={false}
                        value={datetime.value}
                        handleChange={datetime.handleChange}
                        error={formErrors.datetime !== ''}
                        helperText={formErrors.datetime}
                    />
                );
            if (field === 'description')
                return (
                    <TextInput
                        key={field}
                        label='Description'
                        hasExtraLabel={false}
                        value={description.isTyping ? description.value : defaultDescription}
                        handleChange={handleDescriptionChange}
                        error={formErrors.description !== ''}
                        helperText={formErrors.description}
                    />
                );
            if (field === 'sku')
                return (
                    <SelectInput
                        key={field}
                        label='SKU'
                        hasExtraLabel={false}
                        value={sku.value}
                        options={uniqueOrderSkus}
                        handleChange={sku.handleChange}
                        error={formErrors.sku !== ''}
                        helperText={formErrors.sku}
                    />
                );
            if (field === 'paymentSource')
                return (
                    <TextInput
                        key={field}
                        label='Payment source'
                        hasExtraLabel={false}
                        value={paymentSource.value}
                        handleChange={paymentSource.handleChange}
                        error={formErrors.paymentSource !== ''}
                        helperText={formErrors.paymentSource}
                    />
                );
            if (field === 'amount')
                return (
                    <NumberInput
                        key={field}
                        label='Amount'
                        hasExtraLabel={false}
                        value={amount.value}
                        handleChange={amount.handleChange}
                        error={formErrors.amount !== ''}
                        helperText={formErrors.amount}
                    />
                );
            if (field === 'supplierNumber')
                return (
                    <NumberInput
                        key={field}
                        label='Supplier number'
                        hasExtraLabel={false}
                        value={supplierNumber.isTyping ? supplierNumber.value : supplierNumberBasedOnSku}
                        handleChange={handleSupplierNumberChange}
                        error={formErrors.supplierNumber !== ''}
                        helperText={formErrors.supplierNumber}
                    />
                );
            if (field === 'price')
                return (
                    <NumberInput
                        key={field}
                        label='Price'
                        hasExtraLabel={false}
                        value={price.isTyping ? price.value : priceBasedOnSku}
                        handleChange={handlePriceChange}
                        error={formErrors.price !== ''}
                        helperText={formErrors.price}
                    />
                );
            if (field === 'quantity')
                return (
                    <NumberInput
                        key={field}
                        label='Quantity'
                        hasExtraLabel={false}
                        value={quantity.isTyping ? quantity.value : quantityBasedOnSku}
                        handleChange={handleQuantityChange}
                        error={formErrors.quantity !== ''}
                        helperText={formErrors.quantity}
                    />
                );

            return (
                <div key={field} className={styles.errorWrapper}>
                    <Error errorMsg={`Input field for ${field} is missing.`} />
                </div>
            );
        })
        .filter((field) => field !== null);

    return (
        <form noValidate>
            <Fieldset legend='Create transaction'>
                <p className={styles.transactionDesc}>
                    <i>{transactionTypeDescription}</i>
                </p>
                <SelectInput
                    label='Transaction type'
                    hasExtraLabel={false}
                    options={transactionTypes}
                    value={transactionType.value}
                    handleChange={transactionType.handleChange}
                    error={formErrors.type !== ''}
                    helperText={formErrors.type}
                />
                {formInputs}
            </Fieldset>
            <div className={styles.buttonWrapper}>
                <AddButton label='Add transaction' handleClick={handleAddTemporaryTransaction} />
            </div>
            <CancelButton handleClick={handleHideTransactionForm} />
        </form>
    );
};
