import React from 'react';
import { SearchInput, SelectInput } from '../../../shared/Form/Inputs';
import { useEditInvoicesFromInvoiceRunParams } from './useEditInvoicesFromInvoiceRunParams';
import styles from './editInvoicesFromInvoiceRunParams.module.scss';

export const EditInvoicesFromInvoiceRunParams = () => {
    const { marketplace, query, handleMarketplaceChange, handleQueryChange } = useEditInvoicesFromInvoiceRunParams();
    return (
        <div className={styles.params}>
            <div className={styles.params__param}>
                <SelectInput
                    label='Marketplace'
                    hasExtraLabel={false}
                    options={['NL', 'DE']}
                    value={marketplace.value}
                    handleChange={handleMarketplaceChange}
                />
            </div>
            <SearchInput value={query} handleChange={handleQueryChange} />
        </div>
    );
};
