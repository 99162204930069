import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { getOrder } from '../../redux/reducers/orderDetails';
import { selectOrderDetails, selectIsLoadingOrderDetails, selectHasOrderDetailsError } from '../../redux/reducers';

export const useOrderDetails = () => {
    const { orderId } = useParams();
    const dispatch = useAppDispatch();
    const order = useAppSelector(selectOrderDetails);
    const isLoading = useAppSelector(selectIsLoadingOrderDetails);
    const hasError = useAppSelector(selectHasOrderDetailsError);

    useEffect(() => {
        if (typeof orderId === 'string') {
            dispatch(getOrder(orderId));
        }
    }, [dispatch, orderId]);

    return {
        order,
        isLoading,
        hasError,
    };
};
