import React from 'react';
import { useAppSelector } from '../../../../redux/typed-hooks';
import { selectOrderDetailsOrderData } from '../../../../redux/reducers';
import { VerticalTable } from '../../../shared';
import { formatBool } from '../../../../utils/formatting';
import { toDateTime } from '../../../../utils/dates';

export const OrderInfoBlock = () => {
    const orderData = useAppSelector(selectOrderDetailsOrderData);

    if (!orderData) return null;

    const { created, updated, shopifyTags, isDraft, refundComments, isAccountable } = orderData;

    const tableContent = [
        {
            heading: 'Created at',
            data: toDateTime(created),
        },
        {
            heading: 'Last updated at',
            data: toDateTime(updated),
        },
        {
            heading: 'Tags',
            data: shopifyTags.join(', '),
        },
        {
            heading: 'Draft order ?',
            data: formatBool(isDraft),
        },
        { heading: 'Refund comments', data: refundComments.join('. ') },
        {
            heading: 'Accountable ?',
            data: formatBool(isAccountable),
        },
    ];

    return <VerticalTable title='Order info' tableContent={tableContent} />;
};
