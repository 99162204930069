import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';
import { getTemporaryInstantDownloadLink } from '../../utils/download';

const initialState = {
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const invoiceRunDownloadSlice = createSlice({
    name: 'invoiceRunDownload',
    initialState,
    reducers: {
        invoiceRunDownloadLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        invoiceRunDownloaded(state) {
            return {
                ...state,
                isLoading: false,
            };
        },
        invoiceRunDownloadError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
        invoiceRunDownloadErrorCleared(state) {
            return {
                ...state,
                hasError: false,
            };
        },
    },
});

export default invoiceRunDownloadSlice.reducer;

const { invoiceRunDownloadLoading, invoiceRunDownloaded, invoiceRunDownloadError } = invoiceRunDownloadSlice.actions;
export const { invoiceRunDownloadErrorCleared: clearInvoiceRunDownloadError } = invoiceRunDownloadSlice.actions;

export const downloadInvoiceRunZip = (id: number) => async (dispatch: Dispatch) => {
    dispatch(invoiceRunDownloadLoading());
    try {
        const res = await getGatewayApiInstance().get(`/invoice-runs/${id}/zip`, { responseType: 'blob' });
        // Create a temporary link to the file and click to download
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = getTemporaryInstantDownloadLink({ url, fileName: `invoice-run-${id}`, fileExtension: 'zip' });
        link.click();
        dispatch(invoiceRunDownloaded());
    } catch (err: unknown) {
        dispatch(invoiceRunDownloadError());
        handleAxiosErrors(err);
    }
};

// Selectors
export const selectHasInvoiceRunDownloadError = (state: State): boolean => state.hasError;
