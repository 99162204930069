import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectIsLoadingOrderComments, selectOrderComments, selectOrderCommentError } from '../../../../redux/reducers';
import { clearOrderCommentError, editOrderComment } from '../../../../redux/reducers/orderDetails';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import { useTextInput } from '../../../shared/Form/Inputs';

export const useComment = () => {
    const dispatch = useAppDispatch();
    const { orderId } = useParams();
    const comments = useAppSelector(selectOrderComments);
    const IsLoading = useAppSelector(selectIsLoadingOrderComments);
    const hasError = useAppSelector(selectOrderCommentError);

    const newComment = useTextInput(comments);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (typeof orderId === 'string') {
            dispatch(editOrderComment(newComment.value, orderId));
        }
    };

    useEffect(() => {
        dispatch(clearOrderCommentError());
    }, [dispatch]);

    return {
        comments,
        newComment,
        handleSubmit,
        IsLoading,
        hasError,
    };
};
