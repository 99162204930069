import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    MuiEvent,
} from '@mui/x-data-grid';
import { DataGridLinearProgress, DataGridNoRowsOverlay, useDataGridServerSidePagination } from '../shared/DataGrid';
import { Error } from '../shared';
import { DownloadButton } from '../shared/Buttons';
import { EditExactExportRunsParams, ExactExportRunsActionMenu } from './components';
import { useFetchExactExportRuns } from './useFetchExactExportRuns';
import { useDownloadExactExportRun } from './useDownloadExactExportRun';
import { useCheckIfUserHasManagerRights } from '../../hooks';
import { getExactExportRunFileName } from '../../utils/exactExports';
import { toYearMonthDay, toYearMonthDayTime } from '../../utils/dates';
import styles from './exactExportRuns.module.scss';

export const ExactExportRuns = () => {
    const navigate = useNavigate();
    const { hasManagerRights } = useCheckIfUserHasManagerRights();

    const INITIAL_PAGE_SIZE = 10;

    const { rowCountState, page, pageSize, handlePageChange, handlePageSizeChange, setRowCount } =
        useDataGridServerSidePagination({
            initialPageSize: INITIAL_PAGE_SIZE,
        });

    const { isLoading, hasError, exactExportRuns } = useFetchExactExportRuns({ page, pageSize, setRowCount });

    const { handleDownloadClick, hasExactExportRunDownloadError } = useDownloadExactExportRun();

    const handleCellClick = (params: GridCellParams, e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        if (params.field !== 'download-button') {
            navigate(`/exact-export-runs/${params.row.id}`);
        } else {
            const { id, maxOrderNumber, cutoffDateTime } = params.row;
            const fileName = getExactExportRunFileName({ id, maxOrderNumber, cutoffDateTime, toYearMonthDay });
            handleDownloadClick({ id, fileName });
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.5,
            minWidth: 50,
        },
        {
            field: 'created',
            headerName: 'Created',
            valueFormatter: (params: GridValueFormatterParams<ExactExportRun['created']>) =>
                toYearMonthDayTime(params.value),
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'month',
            headerName: 'Month',
            flex: 0.5,
            minWidth: 60,
        },
        {
            field: 'year',
            headerName: 'Year',
            flex: 0.5,
            minWidth: 50,
        },
        {
            field: 'cutoffDateTime',
            headerName: 'Cut-off date',
            valueFormatter: (params: GridValueFormatterParams<ExactExportRun['cutoffDateTime']>) =>
                toYearMonthDayTime(params.value),
            flex: 0.5,
            minWidth: 140,
        },
        {
            field: 'maxOrderNumber',
            headerName: 'Max order number',
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'marketplaceCodes',
            headerName: 'Marketplaces',
            valueFormatter: (params: GridValueFormatterParams<ExactExportRun['marketplaceCodes']>) =>
                params.value.map((code) => code.toUpperCase()).join(', '),
            flex: 1,
            minWidth: 110,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'generated',
            headerName: 'Generated',
            valueFormatter: (params: GridValueFormatterParams<ExactExportRun['generated']>) =>
                params.value ? toYearMonthDayTime(params.value) : '',
            flex: 0.5,
            minWidth: 140,
        },
        {
            field: 'generationProgress',
            headerName: 'Progress',
            valueFormatter: (params: GridValueFormatterParams<ExactExportRun['generationProgress']>) =>
                `${params.value}%`,
            flex: 0.5,
            minWidth: 80,
        },
        {
            field: 'download-button',
            headerName: 'Download',
            flex: 0,
            width: 80,
            renderCell: (params: GridRenderCellParams<ExactExportRun['generated']>) => {
                const isGenerated = Boolean(params.row.generated);
                return (
                    <DownloadButton
                        handleClick={() => {
                            // This is intentional
                        }}
                        isDisabled={!isGenerated}
                    />
                );
            },
        },
    ];

    return (
        <div>
            {hasManagerRights && (
                <div className={styles.actionMenu}>
                    <ExactExportRunsActionMenu />
                </div>
            )}
            {hasExactExportRunDownloadError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Exact Export Run download failed' />
                </div>
            )}
            {hasError && <Error />}
            {!hasError && (
                <>
                    <div className={styles.params}>
                        <EditExactExportRunsParams />
                    </div>

                    <DataGrid
                        columns={columns}
                        rows={exactExportRuns}
                        getRowId={(row) => row.id}
                        rowCount={rowCountState}
                        rowsPerPageOptions={[INITIAL_PAGE_SIZE, 25, 50, 100]}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'desc' }],
                            },
                        }}
                        loading={isLoading}
                        components={{
                            LoadingOverlay: DataGridLinearProgress,
                            NoRowsOverlay: DataGridNoRowsOverlay,
                        }}
                        componentsProps={{
                            noRowsOverlay: { text: 'No Exact Export runs' },
                        }}
                        paginationMode='server'
                        page={page}
                        pageSize={pageSize}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onCellClick={handleCellClick}
                        autoHeight
                        sx={{ cursor: 'pointer' }}
                    />
                </>
            )}
        </div>
    );
};
