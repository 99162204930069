import { useAppSelector, useAppDispatch } from '../../../../../../redux/typed-hooks';
import {
    selectIsLoadingNewTransaction,
    selectIsSubmitTransactionsConfirmDialogOpen,
    selectOrderNumber,
    selectTemporaryTransactionsInclRejectedPerOrder,
} from '../../../../../../redux/reducers';
import { closeConfirmDialog, createNewTransaction } from '../../../../../../redux/reducers/transactions';

export const useConfirmTransactionSubmissionDialog = () => {
    const dispatch = useAppDispatch();
    const isConfirmDialogOpen = useAppSelector(selectIsSubmitTransactionsConfirmDialogOpen);
    const orderNumber = useAppSelector(selectOrderNumber);
    const temporaryTransactionsInclRejected = useAppSelector((state) =>
        selectTemporaryTransactionsInclRejectedPerOrder(state, orderNumber)
    );
    const isLoadingNewTransaction = useAppSelector(selectIsLoadingNewTransaction);

    const handleCloseConfirmDialog = () => {
        dispatch(closeConfirmDialog());
    };

    const handleSubmitNewTransactions = () => {
        if (temporaryTransactionsInclRejected.length > 0) {
            temporaryTransactionsInclRejected.forEach((tempTrans) => {
                const newTransaction: CreateTransaction = [tempTrans].map((tempTransaction) => ({
                    externalTransactionId: tempTransaction.externalTransactionId || '',
                    type: tempTransaction.type,
                    datetime: tempTransaction.datetime,
                    marketplaceCode: tempTransaction.marketplaceCode,
                    orderNumber: tempTransaction.orderNumber,
                    amount: tempTransaction.amount,
                    description: tempTransaction.description,
                    paymentSource: tempTransaction.paymentSource || '',
                    supplierNumber: tempTransaction.supplierNumber,
                    sku: tempTransaction.sku,
                    productName: tempTransaction.productName,
                    brand: tempTransaction.brand,
                    price: tempTransaction.price,
                    quantity: tempTransaction.quantity,
                    cancelReasonId: tempTransaction.cancelReasonId,
                    trackingCompany: tempTransaction.trackingCompany,
                    trackingNumbers: tempTransaction.trackingNumbers,
                    trackingUrls: tempTransaction.trackingUrls,
                    shipmentStatus: tempTransaction.shipmentStatus
                }))[0];
                dispatch(createNewTransaction({ newTransaction, tempId: tempTrans.id }));
            });
        }

        dispatch(closeConfirmDialog());
    };

    return { isConfirmDialogOpen, isLoadingNewTransaction, handleCloseConfirmDialog, handleSubmitNewTransactions };
};
