import React, { useId } from 'react';
import { TextField } from '@mui/material';
import styles from '../inputs.module.scss';

interface NumberInputProps {
    variant?: 'number' | 'percentage';
    label: string;
    value: number | ''; // Empty string allows the controlled number input to be empty (null values are not allowed)
    hasExtraLabel: boolean;
    error?: boolean;
    helperText?: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NumberInput = ({
    variant = 'number',
    label,
    value,
    hasExtraLabel = true,
    error = false,
    helperText = '',
    handleChange,
}: NumberInputProps) => {
    const id = useId();

    return (
        <div className={styles.numberInput}>
            {hasExtraLabel && (
                <label htmlFor={id} aria-hidden='true' className={styles.numberInput__label}>
                    {label}
                </label>
            )}
            <div className={styles.numberInput__inputWrapper}>
                <TextField
                    id={id}
                    name={`text-field-${id}`}
                    type='number'
                    label={label}
                    value={value}
                    error={error}
                    helperText={helperText}
                    onChange={handleChange}
                    variant='outlined'
                    fullWidth
                    inputProps={{
                        min: variant === 'percentage' ? 0 : undefined,
                        max: variant === 'percentage' ? 100 : undefined,
                        step: 0.01,
                    }}
                />
            </div>
        </div>
    );
};
