import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    canCreateNew: false as ExactExportRunStatus['canCreateNewExactExportRun'],
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const exactExportRunStatusSlice = createSlice({
    name: 'exactExportRunStatus',
    initialState,
    reducers: {
        exactExportRunStatusLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        exactExportRunStatusReceived(state, action: PayloadAction<ExactExportRunStatus>) {
            return {
                ...state,
                canCreateNew: action.payload.canCreateNewExactExportRun,
                isLoading: false,
            };
        },
        exactExportRunStatusError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
    },
});

export default exactExportRunStatusSlice.reducer;

const { exactExportRunStatusLoading, exactExportRunStatusReceived, exactExportRunStatusError } =
    exactExportRunStatusSlice.actions;

export const getExactExportRunStatus = () => async (dispatch: Dispatch) => {
    dispatch(exactExportRunStatusLoading());
    try {
        const res = await getGatewayApiInstance().get('/exact-export-runs/can-create-new');
        dispatch(exactExportRunStatusReceived(res.data));
    } catch (err: unknown) {
        dispatch(exactExportRunStatusError());
        handleAxiosErrors(err);
    }
};

// Selectors
export const selectCanCreateNewExactExportRun = (state: State): ExactExportRunStatus['canCreateNewExactExportRun'] =>
    state.canCreateNew;
export const selectIsLoadingExactExportRunStatus = (state: State): boolean => state.isLoading;
export const selectHasExactExportRunStatusError = (state: State): boolean => state.hasError;
