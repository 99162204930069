import React from 'react';
import { DateInput } from '../../../shared/Form/Inputs';
import { useEditInvoiceRunsParams } from './useEditInvoiceRunsParams';
import styles from './editInvoiceRunsParams.module.scss';

export const EditInvoiceRunsParams = () => {
    const { dateFrom, dateUntil, handleDateFromParamChange, handleDateUntilParamChange } = useEditInvoiceRunsParams();

    return (
        <div className={styles.editInvoiceRunsParams}>
            <div className={styles.editInvoiceRunsParams__param}>
                <DateInput
                    label='From date'
                    hasExtraLabel={false}
                    value={dateFrom.value}
                    handleChange={handleDateFromParamChange}
                />
            </div>
            <DateInput
                label='Until date'
                hasExtraLabel={false}
                value={dateUntil.value}
                handleChange={handleDateUntilParamChange}
            />
        </div>
    );
};
