import React from 'react';
import { HomeButton } from '../../components/shared/Buttons';
import { PageTemplate } from '../../components/templates/PageTemplate/PageTemplate';
import styles from './404.module.scss';

export const FourOhFourPage = () => {
    return (
        <PageTemplate headerText='404' hasBackButton>
            <div className={styles.contentWrapper}>
                <div>
                    <p className={styles.contentWrapper__text}>
                        <b>Knock knock</b>
                        <br /> Who&apos;s there?
                        <br /> <b>Error.</b>
                        <br />
                        Error Who?
                        <br /> <b>Error 404: Punchline not found.</b>
                    </p>
                    <HomeButton />
                </div>
            </div>
        </PageTemplate>
    );
};
