import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';
import { getTemporaryInstantDownloadLink } from '../../utils/download';

const initialState = {
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const exactExportDownloadSlice = createSlice({
    name: 'exactExportDownload',
    initialState,
    reducers: {
        exactExportDownloadLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        exactExportDownloaded(state) {
            return {
                ...state,
                isLoading: false,
            };
        },
        exactExportDownloadError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
        exactExportDownloadErrorCleared(state) {
            return {
                ...state,
                hasError: false,
            };
        },
    },
});

export default exactExportDownloadSlice.reducer;

const { exactExportDownloadLoading, exactExportDownloaded, exactExportDownloadError } =
    exactExportDownloadSlice.actions;
export const { exactExportDownloadErrorCleared: clearExactExportDownloadError } = exactExportDownloadSlice.actions;

type DownloadExactExportParams = {
    id: ExactExport['id'];
    fileName: string;
};

export const downloadExactExportCSV =
    ({ id, fileName }: DownloadExactExportParams) =>
    async (dispatch: Dispatch) => {
        dispatch(exactExportDownloadLoading());
        try {
            const res = await getGatewayApiInstance().get(`/accounting/exact-exports/${id}/csv`, {
                responseType: 'blob',
            });
            // Create a temporary link to the file and click to download
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = getTemporaryInstantDownloadLink({ url, fileName, fileExtension: 'csv' });
            link.click();
            dispatch(exactExportDownloaded());
        } catch (err: unknown) {
            dispatch(exactExportDownloadError());
            handleAxiosErrors(err);
        }
    };

// Selectors
export const selectHasExactExportDownloadError = (state: State): boolean => state.hasError;
