import React, { useState } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddTaskIcon from '@mui/icons-material/AddTask';
import DownloadIcon from '@mui/icons-material/Download';
import { ActionMenu, Dialog } from '../../../shared';
import { Button, CancelButton } from '../../../shared/Buttons';
import { useExactExportRunDetailsActionMenu } from './useExactExportRunDetailsActionMenu';
import { useCheckIfUserHasManagerRights } from '../../../../hooks';

export const ExactExportRunDetailsActionMenu = () => {
    const { hasManagerRights } = useCheckIfUserHasManagerRights();
    const {
        isGenerated,
        isCancellable,
        canBeFinalised,
        canBeMarkedAsTest,
        handleCancelRun,
        handleDownloadRun,
        handleFinaliseRun,
        handleMarkAsTestRun
    } = useExactExportRunDetailsActionMenu();

    const [isCancelRunDialogOpen, setIsCancelRunDialogOpen] = useState(false);
    const [isFinaliseRunDialogOpen, setIsFinaliseRunDialogOpen] = useState(false);
    const [isMarkAsTestRunDialogOpen, setIsMarkAsTestRunDialogOpen] = useState(false);

    const handleOpenCancelRunDialog = () => {
        setIsCancelRunDialogOpen(true);
    };

    const handleCloseCancelRunDialog = () => {
        setIsCancelRunDialogOpen(false);
    };

    const handleOpenFinaliseRunDialog = () => {
        setIsFinaliseRunDialogOpen(true);
    };

    const handleCloseFinaliseRunDialog = () => {
        setIsFinaliseRunDialogOpen(false);
    };

    const handleOpenMarkAsTestRunDialog = () => {
        setIsMarkAsTestRunDialogOpen(true);
    };

    const handleCloseMarkAsTestRunDialog = () => {
        setIsMarkAsTestRunDialogOpen(false);
    };

    const downloadRunMenuMenuItem = {
        text: 'Download Run',
        icon: <DownloadIcon />,
        handler: handleDownloadRun,
        isDisabled: !isGenerated,
    };

    const finaliseRunMenuMenuItem = {
        text: 'Finalise Run',
        icon: <AddTaskIcon />,
        handler: handleOpenFinaliseRunDialog,
        isDisabled: !canBeFinalised,
    };

    const cancelRunMenuItem = {
        text: 'Cancel Run',
        icon: <CancelOutlinedIcon />,
        handler: handleOpenCancelRunDialog,
        isDisabled: !isCancellable,
    };

    const markAsTestRunMenuMenuItem = {
        text: 'Mark Run As Test',
        icon: <AddTaskIcon />,
        handler: handleOpenMarkAsTestRunDialog,
        isDisabled: !canBeMarkedAsTest,
    };

    let menuItems = [downloadRunMenuMenuItem];
    if (hasManagerRights) {
        menuItems = [cancelRunMenuItem, downloadRunMenuMenuItem, markAsTestRunMenuMenuItem, finaliseRunMenuMenuItem];
    }

    return (
        <div>
            <ActionMenu menuItems={menuItems} />
            <Dialog
                dialogOpen={isCancelRunDialogOpen}
                titleText='Are you sure that you want to cancel this invoice run?'
            >
                <CancelButton handleClick={handleCloseCancelRunDialog} />
                <Button
                    label='Cancel Run'
                    icon={<CancelOutlinedIcon />}
                    handleClick={() => {
                        handleCloseCancelRunDialog();
                        handleCancelRun();
                    }}
                />
            </Dialog>
            <Dialog
                dialogOpen={isFinaliseRunDialogOpen}
                titleText='Are you sure that you want to finalise this Exact Export run?'
            >
                <CancelButton handleClick={handleCloseFinaliseRunDialog} />
                <Button
                    label='Finalise Run'
                    icon={<AddTaskIcon />}
                    handleClick={() => {
                        handleCloseFinaliseRunDialog();
                        handleFinaliseRun();
                    }}
                />
            </Dialog>
            <Dialog
                dialogOpen={isMarkAsTestRunDialogOpen}
                titleText='Are you sure that you want to mark this Exact Export run as test?'
            >
                <CancelButton handleClick={handleCloseMarkAsTestRunDialog} />
                <Button
                    label='Mark Run As Test'
                    icon={<AddTaskIcon />}
                    handleClick={() => {
                        handleCloseMarkAsTestRunDialog();
                        handleMarkAsTestRun();
                    }}
                />
            </Dialog>
        </div>
    );
};
