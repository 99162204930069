import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { getGatewayApiInstance, handleAxiosErrors } from '../../utils/http-requests';

const initialState = {
    suppliers: [] as InvoiceRunSupplier[],
    page: 1,
    searchValue: '',
    isLoading: false,
    hasError: false,
};

type State = typeof initialState;

const invoiceRunSuppliersSlice = createSlice({
    name: 'invoiceRunSuppliers',
    initialState,
    reducers: {
        invoiceRunSuppliersLoading(state) {
            return {
                ...state,
                isLoading: true,
                hasError: false,
            };
        },
        invoiceRunSuppliersReceived(state, action: PayloadAction<InvoiceRunSupplier[]>) {
            return {
                ...state,
                suppliers: action.payload,
                isLoading: false,
            };
        },
        invoiceRunSuppliersError(state) {
            return {
                ...state,
                isLoading: false,
                hasError: true,
            };
        },
    },
});

export default invoiceRunSuppliersSlice.reducer;

const { invoiceRunSuppliersLoading, invoiceRunSuppliersReceived, invoiceRunSuppliersError } =
    invoiceRunSuppliersSlice.actions;

const ITEMS_PER_PAGE_LIMIT = 10;

export const getInvoiceRunSuppliers = (page: number, searchValue: string) => async (dispatch: Dispatch) => {
    dispatch(invoiceRunSuppliersLoading());
    try {
        const result = await getGatewayApiInstance().get('/suppliers', {
            params: {
                page,
                limit: ITEMS_PER_PAGE_LIMIT,
                search: searchValue,
            },
        });

        const suppliers: InvoiceRunSupplier[] = result.data?.suppliers.map((supplier: InvoiceRunSupplier) => ({
            id: supplier.id,
            supplierName: supplier.supplierName,
            supplierNumber: supplier.supplierNumber,
        }));

        dispatch(invoiceRunSuppliersReceived(suppliers));
    } catch (err: unknown) {
        dispatch(invoiceRunSuppliersError());
        handleAxiosErrors(err);
    }
};

// Selectors
export const selectInvoiceRunSuppliers = (state: State): InvoiceRunSupplier[] => state.suppliers;
export const selectInvoiceRunSuppliersPage = (state: State): number => state.page;
export const selectIsLoadingInvoiceRunSuppliers = (state: State): boolean => state.isLoading;
export const selectHasInvoiceRunSuppliersError = (state: State): boolean => state.hasError;