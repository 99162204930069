import { useCallback, useEffect, useMemo, useState } from 'react';
import Papa from 'papaparse';
import { v4 } from 'uuid';
import { ITTP, TTPFileData, TTPStatus } from '@app/components/TransactionsUpload/types';
import { useAppDispatch, useAppSelector } from '@app/redux/typed-hooks';
import { getTransactionTypes } from '@app/redux/reducers/transactionTypes';
import { selectAllTransactionsInfo } from '@app/redux/reducers';
import {
    downloadErrorReport,
    mapTTPintoCreateTransactionEntity,
    validateTTPFileItem,
} from '@app/components/TransactionsUpload/utils';
import { getGatewayApiInstance } from '@app/utils/http-requests';
import { notificationUpdated } from '@app/redux/reducers/notifications';
import { asyncMap, indexOf } from '@app/utils/array';

export const useTTP = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getTransactionTypes());
    }, [dispatch]);

    const transactionsInfo = useAppSelector(selectAllTransactionsInfo);

    const [fileName, setFileName] = useState<string>('');
    const [TTPs, setTTPs] = useState<ITTP[]>([]);
    const [isProcessInProgress, setIsProcessInProgress] = useState<boolean>(false);

    const doesHaveTTPsToProcess = useMemo<boolean>(() => {
        return TTPs.some((ttp) => ttp.status === TTPStatus.TO_PROCESS);
    }, [TTPs]);
    const isErrorReportAvailable = useMemo<boolean>(() => {
        return TTPs.some((ttp) => ttp.status === TTPStatus.ERROR);
    }, [TTPs]);

    const downloadTTPErrorReport = () => downloadErrorReport(TTPs);

    const updateTTPStatus = (id: string, status: TTPStatus) => {
        const newTTPs = [...TTPs];
        const index = indexOf(newTTPs, (item) => item.id === id);

        if (index <= -1) return;

        newTTPs[index].status = status;
        setTTPs(newTTPs);
    };

    const updateTTPError = (id: string, errorMessage: string) => {
        const newTTPs = [...TTPs];
        const index = indexOf(newTTPs, (item) => item.id === id);

        if (index <= -1) return;

        newTTPs[index].status = TTPStatus.ERROR;
        newTTPs[index].errorMessage = errorMessage;
        setTTPs(newTTPs);
    };

    const onClear = useCallback(() => {
        setTTPs([]);
    }, []);

    const onFileUpload = (file: File) => {
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
            if (!target) return;

            const csvRawData = target.result as string;
            const parsedJsonData = Papa.parse(csvRawData, { header: true })?.data as TTPFileData[];
            const mappedJsonData = await asyncMap(parsedJsonData, async (item, index) => {
                const errorMessage = await validateTTPFileItem(item);

                return {
                    id: v4(),
                    status: errorMessage ? TTPStatus.ERROR : TTPStatus.TO_PROCESS,
                    errorMessage,
                    transactionNumber: index + 1,
                    Marketplace: item.Marketplace || '',
                    Type: item.Type || '',
                    OrderNumber: item.OrderNumber || '',
                    Date: item.Date || '',
                    Description: item.Description || '',
                    VendorNumber: item.VendorNumber || '',
                    PaymentSource: item.PaymentSource || '',
                    SKU: item.SKU || '',
                    Name: item.Name || '',
                    Brand: item.Brand || '',
                    Price: item.Price || '',
                    Amount: item.Amount || '',
                    Quantity: item.Quantity || '',
                    CancelReasonId: item.CancelReasonId || '',
                } as ITTP;
            });

            setFileName(file.name);
            setTTPs(mappedJsonData);
        };
        reader.readAsText(file);
    };

    const onProcess = async () => {
        const TTPsToProcess = TTPs.filter((ttp) => ttp.status === TTPStatus.TO_PROCESS);

        if (!TTPsToProcess.length) return;

        setIsProcessInProgress(true);

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < TTPsToProcess.length; i++) {
            const TTP = TTPsToProcess[i];

            try {
                const createTransactionEntity = mapTTPintoCreateTransactionEntity(TTP, transactionsInfo);
                // eslint-disable-next-line no-await-in-loop
                await getGatewayApiInstance().post(`/transactions`, createTransactionEntity);

                updateTTPStatus(TTP.id, TTPStatus.SUCCESS);
                dispatch(notificationUpdated('New transaction was successfully created'));
            } catch (err: any) {
                const errorMessage: string = err?.response?.data?.error?.error?.description || 'Unknown error';
                updateTTPError(TTP.id, errorMessage);
                console.error('Caught an error while sending transactions', err);
            }
        }

        setIsProcessInProgress(false);
    };

    return {
        fileName,
        TTPs,
        onFileUpload,
        onClear,
        onProcess,
        doesHaveTTPsToProcess,
        isProcessInProgress,
        isErrorReportAvailable,
        downloadTTPErrorReport,
    };
};
