import React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import styles from '../inputs.module.scss';

interface CheckboxGroupProps {
    label: string;
    error?: boolean;
    helperText?: string;
    children: React.ReactNode;
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ label, error = false, helperText = '', children }) => {
    return (
        <div className={styles.checkboxGroup}>
            <p>{label}</p>
            <div className={styles.checkboxGroup__inputWrapper}>
                {children}
                {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
            </div>
        </div>
    );
};
