import React from 'react';
import { PageTemplate } from '../../components/templates/PageTemplate/PageTemplate';
import { NewExactExportRun } from '../../components/NewExactExportRun/NewExactExportRun';

export const NewExactExportRunPage = () => {
    return (
        <PageTemplate headerText='New Exact Export Run' hasBackButton>
            <NewExactExportRun />
        </PageTemplate>
    );
};
