import React, { useId } from 'react';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styles from '../inputs.module.scss';

interface SearchInputProps {
    value: string;
    size?: 'small' | 'medium';
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput = ({ value, size, handleChange }: SearchInputProps) => {
    const id = useId();

    // Prevent page from refreshing when hitting Enter while searching
    const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        const { key } = e;
        if (key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <form role='search'>
            <div className={styles.textInput}>
                <div className={styles.textInput__inputWrapper}>
                    <TextField
                        id={id}
                        name={`search-input-${id}`}
                        label='Type to search'
                        type='search'
                        value={value}
                        onChange={handleChange}
                        onKeyDown={handleEnterKeyDown}
                        InputProps={{
                            endAdornment: <SearchIcon color='primary' />,
                        }}
                        sx={{ width: '100%' }}
                        size={size}
                    />
                </div>
            </div>
        </form>
    );
};
