import React from 'react';
import { EditInvoiceParams, InvoiceList } from './subComponents';
import styles from './invoices.module.scss';

export const Invoices = () => {
    return (
        <>
            <div className={styles.paramsWrapperContainer}>
                <EditInvoiceParams />
            </div>
            <InvoiceList />
        </>
    );
};
