import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { MuiEvent } from '@mui/x-data-grid';

interface CreateButtonProps {
    label: string;
    handleClick: (e: MuiEvent<React.MouseEvent<HTMLElement>>) => void;
}

export const AddButton = ({ label, handleClick }: CreateButtonProps) => {
    return (
        <Button onClick={handleClick} type='button' variant='contained' endIcon={<AddIcon />}>
            {label}
        </Button>
    );
};
