import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../redux/typed-hooks';
import {
    selectHasMarkAsTestExactExportRunError,
    selectHasCancelExactExportRunError,
    selectHasExactExportRunDownloadError,
    selectHasFinaliseExactExportRunError
} from '../../redux/reducers';
import { clearExactExportRunActionErrors } from '../../redux/reducers/exactExportRunDetails';
import { clearExactExportRunDownloadError } from '../../redux/reducers/exactExportRunDownload';

export const useHandleExactExportRunActionErrors = () => {
    const dispatch = useAppDispatch();
    const hasCancelRunError = useAppSelector(selectHasCancelExactExportRunError);
    const hasMarkAsTestExactExportRunError = useAppSelector(selectHasMarkAsTestExactExportRunError);
    const hasExactExportRunDownloadError = useAppSelector(selectHasExactExportRunDownloadError);
    const hasFinaliseExactExportRunError = useAppSelector(selectHasFinaliseExactExportRunError);

    useEffect(() => {
        dispatch(clearExactExportRunActionErrors());
        dispatch(clearExactExportRunDownloadError());
    }, [dispatch]);

    return {
        hasCancelRunError,
        hasExactExportRunDownloadError,
        hasMarkedAsTestExactExportRunError: hasMarkAsTestExactExportRunError,
        hasFinaliseExactExportRunError,
    };
};
