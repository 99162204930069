import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../redux/typed-hooks';
import {
    selectHasInvoiceRunDownloadError,
    selectHasCancelInvoiceRunError,
    selectHasMarkAsTestInvoiceRunError,
    selectHasFinaliseInvoiceRunError,
    selectHasEmailInvoiceRunError,
} from '../../redux/reducers';
import { clearInvoiceRunDownloadError } from '../../redux/reducers/invoiceRunDownload';
import { clearInvoiceRunActionErrors } from '../../redux/reducers/invoiceRunDetails';

export const useHandleInvoiceRunActionErrors = () => {
    const dispatch = useAppDispatch();
    const hasCancelRunError = useAppSelector(selectHasCancelInvoiceRunError);
    const hasMarkAsTestRunError = useAppSelector(selectHasMarkAsTestInvoiceRunError);
    const hasFinaliseRunError = useAppSelector(selectHasFinaliseInvoiceRunError);
    const hasEmailRunError = useAppSelector(selectHasEmailInvoiceRunError);
    const hasDownloadRunError = useAppSelector(selectHasInvoiceRunDownloadError);

    useEffect(() => {
        dispatch(clearInvoiceRunActionErrors());
        dispatch(clearInvoiceRunDownloadError());
    }, [dispatch]);

    return {
        hasCancelRunError,
        hasMarkAsTestRunError,
        hasFinaliseRunError,
        hasEmailRunError,
        hasDownloadRunError,
    };
};
