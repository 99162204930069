import { useState } from 'react';

export const useDateInput = (initialValue: string | null) => {
    const getInitialValue = (initialDateValue: string | null) => {
        if (!initialDateValue) return null;
        return new Date(initialDateValue);
    };

    const [value, setValue] = useState<Date | null>(getInitialValue(initialValue));

    const handleChange = (newDate: Date | null) => {
        setValue(newDate);
    };

    return { value, handleChange };
};
