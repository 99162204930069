import { useState, useEffect } from 'react';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../../../redux/typed-hooks';
import {
    selectHasNewTransactionError,
    selectTemporaryTransactionsPerOrder,
    selectSelectedTemporaryTransactionsIds,
    selectRejectedTransactionsPerOrder,
    selectOrderNumber,
} from '../../../../redux/reducers';
import {
    clearNewTransactionError,
    saveSelectedTemporaryTransactionsIds,
    deleteSelectedTemporaryTransactions,
    openConfirmDialog,
} from '../../../../redux/reducers/transactions';
import { getDebitTotal, getCreditTotal } from '../../../../utils/transactions';

export const useTransactionTable = (transactions: Transaction[]) => {
    const dispatch = useAppDispatch();
    const [selectedDebitTotal, setSelectedDebitTotal] = useState(0);
    const [selectedCreditTotal, setSelectedCreditTotal] = useState(0);
    const hasNewTransactionError = useAppSelector(selectHasNewTransactionError);
    const orderNumber = useAppSelector(selectOrderNumber);
    const temporaryTransactions = useAppSelector((state) => selectTemporaryTransactionsPerOrder(state, orderNumber));
    const rejectedTransactions = useAppSelector((state) => selectRejectedTransactionsPerOrder(state, orderNumber));
    const selectedTemporaryTransactionsIds = useAppSelector(selectSelectedTemporaryTransactionsIds);
    // UI
    const hasTemporaryTransactions = temporaryTransactions.length > 0 || rejectedTransactions.length > 0;
    const isDeleteButtonDisabled = selectedTemporaryTransactionsIds.length < 1;
    const [isFormVisible, setIsFormVisible] = useState(false);

    useEffect(() => {
        dispatch(clearNewTransactionError());
    }, [dispatch]);

    const rejectedTransactionsWithClassName = rejectedTransactions.map((rejectedTransaction) => ({
        ...rejectedTransaction,
        className: 'rejected',
    }));

    const temporaryTransactionsWithClassName = temporaryTransactions.map((tempTransaction) => ({
        ...tempTransaction,
        className: 'temporary',
    }));

    const allTransactions = [
        ...transactions,
        ...rejectedTransactionsWithClassName,
        ...temporaryTransactionsWithClassName,
    ];

    /**
     * MUI DataGrid does not provide an option to add a summary row (e.g. to sum up column values).
     * This workaround solution repurposes footer component as a summary row and feeds it the pre-calculated debit and credit totals
     */
    const debitTotal = getDebitTotal(allTransactions);
    const creditTotal = getCreditTotal(allTransactions);

    const handleRowSelection = (ids: GridSelectionModel): void => {
        const selectedTransactions = allTransactions.filter((transaction) => ids.includes(transaction.id));
        const selectedCreditsTotal = getCreditTotal(selectedTransactions);
        const selectedDebitsTotal = getDebitTotal(selectedTransactions);
        setSelectedCreditTotal(selectedCreditsTotal);
        setSelectedDebitTotal(selectedDebitsTotal);

        // Keep track of selected temporary transactions in Redux store (this will allow to delete selected temporary transactions)
        const selectedTempTransactionsIds = selectedTransactions
            .filter(
                (selectedTransaction) =>
                    typeof selectedTransaction.id === 'string' &&
                    selectedTransaction.id.includes('temporary-transaction')
            )
            .map((selectedTempTransaction) => selectedTempTransaction.id) as string[];

        dispatch(saveSelectedTemporaryTransactionsIds(selectedTempTransactionsIds));
    };

    const handleCreateTransactionClick = () => {
        setIsFormVisible(true);
    };

    const handleHideTransactionForm = () => {
        setIsFormVisible(false);
    };

    const handleDeleteSelectedTemporaryTransactions = () => {
        dispatch(deleteSelectedTemporaryTransactions());
    };

    const handleOpenConfirmationDialog = () => {
        dispatch(openConfirmDialog());
    };

    return {
        debitTotal,
        creditTotal,
        selectedDebitTotal,
        selectedCreditTotal,
        handleRowSelection,
        handleCreateTransactionClick,
        handleHideTransactionForm,
        handleDeleteSelectedTemporaryTransactions,
        handleOpenConfirmationDialog,
        allTransactions,
        hasTemporaryTransactions,
        hasNewTransactionError,
        isDeleteButtonDisabled,
        isFormVisible,
    };
};
