import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';

export const HomeButton = () => {
    const navigate = useNavigate();

    const handleHomeClick = () => {
        navigate('/orders');
    };

    return (
        <Button type='button' variant='outlined' onClick={handleHomeClick} endIcon={<HomeIcon />}>
            Let&apos;s go home
        </Button>
    );
};
