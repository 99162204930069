import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ITTP, TTPStatus } from '@app/components/TransactionsUpload/types';
import { TransactionItem } from '@app/components/TransactionsUpload/components/TransactionItem';

const DisplayGridBox = styled(Box)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;

    max-height: 30rem;
    overflow: scroll;
    padding-bottom: 3rem;
`;

interface IColumnProps {
    items: ITTP[];
}

const ToProcessColumn = ({ items }: IColumnProps) => (
    <Grid container direction='column' gap='1rem'>
        <Grid item>
            <Typography textAlign='center'>To process: {items.length}</Typography>
        </Grid>

        {items.map((item) => (
            <Grid item key={item.id}>
                <TransactionItem TTP={item} />
            </Grid>
        ))}
    </Grid>
);

const SuccessColumn = ({ items }: IColumnProps) => (
    <Grid container direction='column' gap='1rem'>
        <Grid item>
            <Typography textAlign='center' color='green'>
                Success: {items.length}
            </Typography>
        </Grid>

        {items.map((item) => (
            <Grid item key={item.id}>
                <TransactionItem TTP={item} />
            </Grid>
        ))}
    </Grid>
);

const ErrorColumn = ({ items }: IColumnProps) => (
    <Grid container direction='column' gap='1rem'>
        <Grid item>
            <Typography textAlign='center' color='error'>
                Error: {items.length}
            </Typography>
        </Grid>

        {items.map((item) => (
            <Grid item key={item.id}>
                <TransactionItem TTP={item} />
            </Grid>
        ))}
    </Grid>
);

interface IProps {
    TTPs: ITTP[];
}

export const TTPGrid = ({ TTPs }: IProps) => {
    if (!TTPs.length) return null;

    return (
        <>
            <DisplayGridBox>
                <ToProcessColumn items={TTPs.filter((i) => i.status === TTPStatus.TO_PROCESS)} />

                <SuccessColumn items={TTPs.filter((i) => i.status === TTPStatus.SUCCESS)} />

                <ErrorColumn items={TTPs.filter((i) => i.status === TTPStatus.ERROR)} />
            </DisplayGridBox>
            <Box>
                <Typography>Total amount of transactions: {TTPs.length}</Typography>
            </Box>
            <Divider />
        </>
    );
};
