import React from 'react';
import { toYearMonthDayTime } from '@app/utils/dates';
import { Error, Heading, Loader, VerticalTable } from '../shared';
import { EditInvoicesFromInvoiceRunParams, InvoiceRunDetailsActionMenu, InvoicesFromInvoiceRun } from './components';
import { useFetchInvoiceRunDetails } from './useFetchInvoiceRunDetails';
import { useHandleInvoiceRunActionErrors } from './useHandleInvoiceRunActionErrors';
import styles from './invoiceRunDetails.module.scss';

export const InvoiceRunDetails = () => {
    const { isLoading, hasError, invoiceRun } = useFetchInvoiceRunDetails();

    const { hasCancelRunError, hasMarkAsTestRunError, hasFinaliseRunError, hasEmailRunError, hasDownloadRunError } =
        useHandleInvoiceRunActionErrors();

    const tableContent1 = [
        { heading: 'ID', data: invoiceRun?.id || '' },

        { heading: 'Created', data: invoiceRun?.created ? toYearMonthDayTime(invoiceRun.created) : '' },
        { heading: 'Month', data: invoiceRun?.month || '' },

        { heading: 'Year', data: invoiceRun?.year || '' },
        {
            heading: 'Cut-off date',
            data: invoiceRun?.cutoffDateTime ? toYearMonthDayTime(invoiceRun.cutoffDateTime) : '',
        },
        {
            heading: 'Marketplaces',
            data: invoiceRun?.marketplaceCodes
                ? invoiceRun.marketplaceCodes.map((code) => code.toUpperCase()).join(', ')
                : '',
        },
        {
            heading: 'Suppliers',
            data: invoiceRun?.supplierNames?.length ? invoiceRun.supplierNames.join(', ') : 'All',
        },
    ];

    const tableContent2 = [
        { heading: 'Status', data: invoiceRun?.status || '' },
        { heading: 'Generated', data: invoiceRun?.generated ? toYearMonthDayTime(invoiceRun.generated) : '' },
        { heading: 'Cancelled', data: invoiceRun?.cancelled ? toYearMonthDayTime(invoiceRun.cancelled) : '' },
        {
            heading: 'Marked as test',
            data: invoiceRun?.markedAsTest ? toYearMonthDayTime(invoiceRun.markedAsTest) : '',
        },
        { heading: 'Finalised', data: invoiceRun?.finalized ? toYearMonthDayTime(invoiceRun.finalized) : '' },
        { heading: 'Emailed', data: invoiceRun?.emailed ? toYearMonthDayTime(invoiceRun.emailed) : '' },
        { heading: 'Progress', data: `${invoiceRun?.generationProgress || ''}%` },
    ];

    if (hasError) return <Error />;
    if (isLoading) return <Loader />;
    return (
        <>
            <div className={styles.menuWrapper}>
                <InvoiceRunDetailsActionMenu />
            </div>
            {hasCancelRunError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not cancel the run.' />
                </div>
            )}
            {hasMarkAsTestRunError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not mark as test.' />
                </div>
            )}
            {hasFinaliseRunError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not finalise the run.' />
                </div>
            )}
            {hasEmailRunError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not email the run' />
                </div>
            )}
            {hasDownloadRunError && (
                <div className={styles.errorWrapper}>
                    <Error errorMsg='Could not download the run.' />
                </div>
            )}

            <div className={styles.tableWrapper}>
                <div className={styles.tableWrapper__table}>
                    <VerticalTable tableContent={tableContent1} />
                </div>
                <div className={styles.tableWrapper__table}>
                    <VerticalTable tableContent={tableContent2} />
                </div>
            </div>
            <div className={styles.headingWrapper}>
                <Heading level={1} text='Invoices' />
            </div>
            <EditInvoicesFromInvoiceRunParams />
            <InvoicesFromInvoiceRun />
        </>
    );
};
