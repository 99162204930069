export const formatExactExportType = (type: ExactExport['type']): string => {
    if (type === 'marketplace') return 'commission';
    if (type === 'retail_revenue') return 'purchase revenue';
    if (type === 'retail_costs') return 'purchase costs';
    return type;
};

export const getExactExportRunFileName = ({
    id,
    maxOrderNumber,
    cutoffDateTime,
    toYearMonthDay,
}: {
    id: number;
    maxOrderNumber: number;
    cutoffDateTime: Date;
    toYearMonthDay: (date: Date) => string;
}): string => {
    const cutOffDate = toYearMonthDay(cutoffDateTime).replaceAll('-', '');
    return `exact-export-run-${id}_${maxOrderNumber}_${cutOffDate}`;
};
