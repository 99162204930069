import React from 'react';
import { SubmitButton } from '../../../shared/Buttons';
import { Form } from '../../../shared/Form';
import { TextInput } from '../../../shared/Form/Inputs';
import { Error, Heading } from '../../../shared';
import { useComment } from './useComment';
import { useCheckIfUserHasManagerRights } from '../../../../hooks';
import styles from './comment.module.scss';

export const Comment = () => {
    const { hasManagerRights } = useCheckIfUserHasManagerRights();
    const { comments, newComment, handleSubmit, IsLoading, hasError } = useComment();

    if (hasManagerRights)
        return (
            <Form id='notes-form' handleSubmit={handleSubmit}>
                {hasError && <Error />}
                <div className={styles.commentWrapper}>
                    <TextInput
                        variant='textarea'
                        label='Comment'
                        hasExtraLabel={false}
                        value={newComment.value}
                        handleChange={newComment.handleChange}
                    />
                </div>
                <div className={styles.postBtnWrapper}>
                    <SubmitButton variant='save' isLoading={IsLoading} />
                </div>
            </Form>
        );
    return (
        <div className={styles.commentWrapper}>
            <Heading level={2} text='Notes' />
            <p>
                <i>{comments || 'No notes.'}</i>
            </p>
        </div>
    );
};
