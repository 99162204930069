import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { getExactExports } from '../../redux/reducers/exactExports';
import {
    selectExactExports,
    selectExactExportsTotal,
    selectIsLoadingExactExports,
    selectHasExactExportsError,
} from '../../redux/reducers';

type FetchExactExportsParams = {
    page: number;
    pageSize: number;
    setRowCount: (rowCount: number | undefined) => void;
};

export const useFetchExactExports = ({ page, pageSize, setRowCount }: FetchExactExportsParams) => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoadingExactExports);
    const hasError = useAppSelector(selectHasExactExportsError);
    const exactExports = useAppSelector(selectExactExports);
    const exactExportsTotal = useAppSelector(selectExactExportsTotal);

    useEffect(() => {
        setRowCount(undefined);
        dispatch(getExactExports({ page: page + 1, pageSize }));
        setRowCount(exactExportsTotal);
    }, [dispatch, page, pageSize, exactExportsTotal, setRowCount]);

    return { exactExports, isLoading, hasError };
};
