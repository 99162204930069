import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/typed-hooks';
import { startNewExactExportRun, clearNewExactExportRunError } from '../../redux/reducers/exactExportRuns';
import { selectIsLoadingNewExactExportRun, selectHasNewExactExportRunError } from '../../redux/reducers';
import { useCheckboxInput, useDateInput } from '../shared/Form/Inputs';
import {
    objectHasNonEmptyValue,
    validateRequiredCheckboxGroup,
    validateRequiredDateField,
} from '../../utils/validation';
import { getMonth, getYear } from '../../utils/dates';
import { convertCheckboxGroupToList, toNumber } from '../../utils/formatting';

export const useStartNewExactExportRun = () => {
    const dispatch = useAppDispatch();
    const isLoadingNewExactExportRun = useAppSelector(selectIsLoadingNewExactExportRun);
    const hasNewExactExportRunError = useAppSelector(selectHasNewExactExportRunError);

    const period = useDateInput(null);
    const isMarketplaceNL = useCheckboxInput(true);
    const isMarketplaceDE = useCheckboxInput(false);
    const marketplaceCodes = convertCheckboxGroupToList([
        { value: 'nl', checked: isMarketplaceNL.value },
        { value: 'de', checked: isMarketplaceDE.value },
    ]);

    const [formErrors, setFormErrors] = useState({
        period: '',
        marketplaceCodes: '',
    });

    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

    const handleOpenNewExactExportRunConfirmationDialog = () => {
        // Open the confirmation dialog only if parameters pass validation, else show form errors
        const newFormErrors = {
            period: validateRequiredDateField({ value: period.value, fieldName: 'Period' }),
            marketplaceCodes: validateRequiredCheckboxGroup(marketplaceCodes, 'marketplace codes'),
        };
        setFormErrors(newFormErrors);
        if (objectHasNonEmptyValue(newFormErrors)) return;
        setIsConfirmationDialogOpen(true);
    };

    const handleCancelNewExactExportRunRequest = () => {
        setIsConfirmationDialogOpen(false);
    };

    const handleStartNewExactExportRunClick = () => {
        const newExactExportRun: NewExactExportRun = {
            month: period.value ? toNumber(getMonth(period.value)) : 0,
            year: period.value ? toNumber(getYear(period.value)) : 0,
            marketplaceCodes: marketplaceCodes as ExactExportRun['marketplaceCodes'],
        };

        dispatch(startNewExactExportRun(newExactExportRun));
        setIsConfirmationDialogOpen(false);
    };

    useEffect(() => {
        dispatch(clearNewExactExportRunError());
    }, [dispatch]);

    return {
        period,
        isMarketplaceNL,
        isMarketplaceDE,
        formErrors,
        isConfirmationDialogOpen,
        handleOpenNewExactExportRunConfirmationDialog,
        handleCancelNewExactExportRunRequest,
        isLoadingNewExactExportRun,
        hasNewExactExportRunError,
        handleStartNewExactExportRunClick,
    };
};
