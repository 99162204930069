import React from 'react';
import { PageTemplate } from '../../components/templates/PageTemplate/PageTemplate';
import { ExactExports } from '../../components/ExactExports/ExactExports';

export const ExactExportsPage = () => {
    return (
        <PageTemplate headerText='Exact Exports' hasBackButton>
            <ExactExports />
        </PageTemplate>
    );
};
