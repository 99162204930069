import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridRowParams, GridValueFormatterParams, MuiEvent } from '@mui/x-data-grid';
import { DataGridLinearProgress, DataGridNoRowsOverlay, useDataGridServerSidePagination } from '../shared/DataGrid';
import { Error } from '../shared';
import { EditInvoiceRunsParams, InvoiceRunsActionMenu } from './components';
import { useFetchInvoiceRuns } from './useFetchInvoiceRuns';
import { useCheckIfUserHasManagerRights } from '../../hooks';
import { toYearMonthDayTime } from '../../utils/dates';
import styles from './invoiceRuns.module.scss';

export const InvoiceRuns = () => {
    const navigate = useNavigate();
    const { hasManagerRights } = useCheckIfUserHasManagerRights();

    const INITIAL_PAGE_SIZE = 10;

    const { rowCountState, page, pageSize, handlePageChange, handlePageSizeChange, setRowCount } =
        useDataGridServerSidePagination({
            initialPageSize: INITIAL_PAGE_SIZE,
        });

    const { isLoading, hasError, invoiceRuns } = useFetchInvoiceRuns({ page, pageSize, setRowCount });

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.5,
            minWidth: 80,
        },
        {
            field: 'created',
            headerName: 'Created',
            valueFormatter: (params: GridValueFormatterParams<InvoiceRun['created']>) =>
                toYearMonthDayTime(params.value),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'month',
            headerName: 'Month',
            flex: 0.5,
            minWidth: 80,
        },
        {
            field: 'year',
            headerName: 'Year',
            flex: 0.5,
            minWidth: 80,
        },

        {
            field: 'cutoffDateTime',
            headerName: 'Cut-off date',
            valueFormatter: (params: GridValueFormatterParams<InvoiceRun['cutoffDateTime']>) =>
                toYearMonthDayTime(params.value),
            flex: 0.5,
            minWidth: 150,
        },
        {
            field: 'marketplaceCodes',
            headerName: 'Marketplaces',
            valueFormatter: (params: GridValueFormatterParams<InvoiceRun['marketplaceCodes']>) =>
                params.value.map((code) => code.toUpperCase()).join(', '),
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'generated',
            headerName: 'Generated',
            valueFormatter: (params: GridValueFormatterParams<InvoiceRun['generated']>) =>
                params.value ? toYearMonthDayTime(params.value) : '',
            flex: 0.5,
            minWidth: 150,
        },
        {
            field: 'generationProgress',
            headerName: 'Progress',
            valueFormatter: (params: GridValueFormatterParams<InvoiceRun['generationProgress']>) => `${params.value}%`,
            flex: 0.5,
            minWidth: 100,
        },
    ];

    const handleRowClick = (params: GridRowParams, e: MuiEvent<React.MouseEvent<HTMLElement>>) => {
        e.defaultMuiPrevented = true;
        navigate(`/invoice-runs/${params.row.id}`);
    };

    return (
        <>
            {hasManagerRights && (
                <div className={styles.actionMenu}>
                    <InvoiceRunsActionMenu />
                </div>
            )}
            {hasError && <Error />}
            {!hasError && (
                <>
                    <div className={styles.params}>
                        <EditInvoiceRunsParams />
                    </div>
                    <DataGrid
                        columns={columns}
                        rows={invoiceRuns}
                        getRowId={(row) => row.id}
                        rowCount={rowCountState}
                        rowsPerPageOptions={[INITIAL_PAGE_SIZE, 25, 50, 100]}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'desc' }],
                            },
                        }}
                        onRowClick={handleRowClick}
                        loading={isLoading}
                        components={{
                            LoadingOverlay: DataGridLinearProgress,
                            NoRowsOverlay: DataGridNoRowsOverlay,
                        }}
                        componentsProps={{
                            noRowsOverlay: { text: 'No invoice runs' },
                        }}
                        paginationMode='server'
                        page={page}
                        pageSize={pageSize}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        autoHeight
                        sx={{ cursor: 'pointer' }}
                    />
                </>
            )}
        </>
    );
};
